
import axios from 'axios';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import img from '../../../../constants/Img';
import { SharedData } from '../../../../context/CallApi';
import { CarFilterStates } from '../../../../context/FiltersCars';
import { LocalizationContext } from '../../../../context/LangChange';
import cheerio from 'cheerio';


const ResLimousine = ({ price_lim, price_after_tax_lim, total_Price_lim, lim, deliveryType, promoCodeValue, price_after_tax, totalPrice, CalcDays, selectedStartDate, selectedEndDate, promoType, promoValue, promoCheck, priceTax, DeliveryExpenses, carName, carImage, show, setModalShow, onHide, getReservationsData, price }) => {
    let { setInvoice, booking, userLocationMap } = useContext(CarFilterStates);
    let { fetchPersonal, fetchPersonalData } = useContext(SharedData);

    let { isLang } = useContext(LocalizationContext);
    const [modalDone, setModalDone] = useState(false);
    const [calcTotalPrice, setcalcTotalPrice] = useState(null);
    const [calcTax, setcalcTax] = useState(null);
    const [calcTotalPricePerDay, setcalcTotalPricePerDay] = useState(null);
    const [discountsPromoWithPrice, setDiscountsPromoWithPrice] = useState(false);
    const [checked, setChecked] = useState(false);
    const [loadingPay, setLoadingPay] = useState(false);


    const handleTotalPrice = () => {
        setcalcTotalPrice(false);
        if (lim === 0) {
            if (promoType === 0) {
                let pricePromo = totalPrice * (promoValue / 100);
                setDiscountsPromoWithPrice(pricePromo)
                if (deliveryType === 0) {
                    let total = totalPrice - pricePromo
                    let totalWithTax = total * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay(((totalPrice) - pricePromo) + totalWithTax)
                } else {
                    let total = totalPrice - pricePromo
                    let totalWithTax = total * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay(((totalPrice + DeliveryExpenses) - pricePromo) + totalWithTax)
                }


            } else if (promoType === 1) {
                setDiscountsPromoWithPrice(promoValue)
                if (deliveryType === 0) {
                    let total = totalPrice - promoValue
                    let totalWithTax = total * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay((totalPrice - promoValue) + totalWithTax)
                } else {
                    let total = totalPrice - promoValue
                    let totalWithTax = total * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay(((totalPrice + DeliveryExpenses) - promoValue) + totalWithTax)
                }


            } else {
                if (deliveryType === 0) {
                    let totalWithTax = totalPrice * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay(totalPrice + totalWithTax)
                } else {
                    let totalWithTax = totalPrice * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay((totalPrice + DeliveryExpenses) + totalWithTax)
                }


            }
        } else if (lim === 1) {
            if (promoType === 0) {
                let pricePromo = total_Price_lim * (promoValue / 100);
                setDiscountsPromoWithPrice(pricePromo)
                if (deliveryType === 0) {
                    let total = total_Price_lim - pricePromo
                    let totalWithTax = total * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay(((total_Price_lim) - pricePromo) + totalWithTax)
                } else {
                    let total = total_Price_lim - pricePromo
                    let totalWithTax = total * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay(((total_Price_lim) - pricePromo) + totalWithTax)
                }


            } else if (promoType === 1) {
                setDiscountsPromoWithPrice(promoValue)
                if (deliveryType === 0) {
                    let total = total_Price_lim - promoValue
                    let totalWithTax = total * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay((total_Price_lim - promoValue) + totalWithTax)
                } else {
                    let total = total_Price_lim - promoValue
                    let totalWithTax = total * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay(((total_Price_lim) - promoValue) + totalWithTax)
                }


            } else {
                if (deliveryType === 0) {
                    let totalWithTax = total_Price_lim * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay(total_Price_lim + totalWithTax)
                } else {
                    let totalWithTax = total_Price_lim * priceTax
                    setcalcTax(totalWithTax)
                    setcalcTotalPricePerDay((total_Price_lim) + totalWithTax)
                }


            }
        }
    };


    useEffect(() => {
        handleTotalPrice()
        return () => {
            handleTotalPrice()
        }
    }, [promoValue, priceTax, deliveryType, userLocationMap, selectedStartDate, selectedEndDate])

    let navigate = useNavigate()
    const handelReservations = async () => {
        const lat = userLocationMap?.lat ? `&lat=${parseFloat(userLocationMap?.lat)}` : '&lat=';
        const lang = userLocationMap?.lng ? `&lang=${parseFloat(userLocationMap?.lng)}` : '&lang=';
        const promoCodeParam = promoCodeValue ? `&promo=${promoCodeValue}` : '';
        const startTime = getReservationsData?.startTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }).replace(/([0-9]{2}:[0-9]{2}).*/, '$1');

        setLoadingPay(true)

        const url = `${process.env.REACT_APP_API_URL}/templimo?carId=${getReservationsData?.carId}&startTime=${startTime}${lang}${lat}&clientId=${getReservationsData?.clientId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&cityId=${getReservationsData?.cityId}&address=${getReservationsData?.address}&type=1&payType=1${promoCodeParam}&price=${lim === 0 ? (totalPrice <= 0 ? 0 : totalPrice) : (total_Price_lim <= 0 ? 0 : total_Price_lim)}&tax=${calcTax}&total=${calcTotalPricePerDay <= 0 ? 0 : calcTotalPricePerDay}&days=${CalcDays}`;
        let { data } = await axios.post(url)
        if (data?.status) {
            localStorage.removeItem('res_number')
            setInvoice(data);
            localStorage.setItem('res_number', data?.res_number)
            fetchPersonal()
            const timeOut = setTimeout(() => {
                methodPayment(
                    {
                        IDReservation: data?.res_number,
                        Name: fetchPersonalData?.first_name + ' ' + fetchPersonalData?.last_name,
                        Phone: fetchPersonalData?.mobile ? fetchPersonalData?.mobile : null,
                        Email: fetchPersonalData?.email ? fetchPersonalData?.email : null,
                        Amount: data?.total
                    }
                )
            }, 1500);
            return () => clearTimeout(timeOut);
        }
    }
    const methodPayment = async (invoice) => {
        try {
            const response = await axios.post('https://backend.betterway-egypt.com/api/admin/test', invoice, {});
            const $ = cheerio.load(response.data);
            const redirectLink = $('meta[http-equiv="refresh"]').attr('content').match(/url='(.*?)'/)[1];
            // Set the current page's URL to the new URL
            setTimeout(function () {
                window.location.href = redirectLink;
                setLoadingPay(false)
            }, 1000);

        } catch (error) {
            setLoadingPay(false)

        }
    };
    return (
        <div className='app__wallet app__payment_summry'>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Col xl={6} lg={6} md={12} sm={12}  >
                            <div className="app__payment_getway flex h-100 flex-column justify-content-center">
                                <div className="header w-100">
                                    <h3>{isLang === "en" ? 'Order summary' : 'ملخص الطلب'}</h3>
                                    <hr />
                                </div>
                                <div className="app__payment_summary1">
                                    <div className="summary-right">
                                        <img src={carImage} className='w-100' alt="images" loading='lazy' />
                                    </div>
                                    <div className="summary-left">
                                        <div className="content">
                                            <div className="content1">
                                                <h3>{carName}</h3>
                                                {
                                                    lim === 0 ?
                                                        <h2>{parseFloat(price).toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2> :
                                                        <h2>{parseFloat(price_lim).toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                                }
                                            </div>
                                            <div className="content2">
                                                <h3>{isLang === "en" ? 'Rental term' : 'مدة الإيجار'}</h3>
                                                <h2>  {CalcDays} {isLang === "en" ? 'Days' : 'يوم'}  </h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="app__payment_summary_date  flex flex-column">
                                    <div className="summary-left">
                                        <div className="content2 flex flex-row justify-content-between w-100">
                                            <h3>{isLang === "en" ? 'Start date  ' : 'تاريخ بداية الايجار'}</h3>
                                            <h2>  {selectedStartDate}   </h2>
                                        </div>
                                        <div className="content2 flex flex-row justify-content-between w-100">
                                            <h3>{isLang === "en" ? 'end date  ' : ' تاريخ نهاية الايجار'}</h3>
                                            <h2>  {selectedEndDate}   </h2>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="app__payment_summary2">
                                    <div className="  content1 ">
                                        <div className="content1_title">
                                            <span>{isLang === "en" ? 'Total Amount' : 'إجمالى المبلغ'}</span>
                                        </div>
                                        <div className="content1_price">
                                            {
                                                lim === 0 ?
                                                    <h2>{parseFloat(totalPrice)?.toFixed(2) <= 0 ? 0 : parseFloat(totalPrice)?.toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2> :
                                                    <h2>{parseFloat(total_Price_lim)?.toFixed(2) <= 0 ? 0 : parseFloat(total_Price_lim)?.toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                            }
                                        </div>
                                    </div>



                                    {
                                        promoCheck &&
                                        <div className="content1">
                                            <div className="content1_title">
                                                <span>{isLang === "en" ? 'Promo code discount ' : 'قيمة خصم البرومو كود'}</span>
                                            </div>
                                            <div className="content1_price">
                                                <h2>{parseFloat(discountsPromoWithPrice)?.toFixed(2) <= 0 ? 0 : parseFloat(discountsPromoWithPrice)?.toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <hr />
                                <div className="app__payment_summary3">
                                    <div className="  content2  flex flex-row ">
                                        <div className="content2_title w-100 ">
                                            <span>{isLang === "en" ? 'Tax amount' : 'قيمة الضريبة'}</span>
                                        </div>
                                        <div className="content2_discount flex justify-content-between flex-row w-100">
                                            <h3>{getReservationsData?.tax * 100}% </h3>
                                            <h2>{parseFloat(calcTax).toFixed(2) <= 0 ? 0 : parseFloat(calcTax).toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                        </div>
                                    </div>

                                    {
                                        lim === 0 &&
                                        <>
                                            {
                                                deliveryType !== 0 &&
                                                <div className="content2 flex flex-row w-100 justify-content-between">
                                                    <div className="content2_title ">
                                                        <span>{isLang === "en" ? 'Delivery charges ' : 'مصاريف التوصيل'}</span>
                                                    </div>
                                                    <div className="content2_discount  ">
                                                        <div className="content2_discount flex  w-100">
                                                            <h2>{parseFloat(DeliveryExpenses).toFixed(2) <= 0 ? 0 : parseFloat(DeliveryExpenses).toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </>
                                    }
                                    <div className="content3  mt-4 flex flex-row justify-content-between align-items-center">
                                        <div className="content3_title">
                                            <span>{isLang === "en" ? 'Total Amount' : 'إجمالى المبلغ'}</span>
                                        </div>
                                        <div className="content3_price">
                                            <h2>  {parseFloat(calcTotalPricePerDay).toFixed(2) <= 0 ? 0 : parseFloat(calcTotalPricePerDay).toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn_pay mt-6  w-100">
                                    {/*             <div className="flex  gap-2 align-items-center  mb-3">
                                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={e => setChecked(e.checked)} checked={checked} className='m-0 p-0' />
                                        <label htmlFor="ingredient1" className="  text_box p-0 m-0 ">  {isLang === "en" ? 'Deposit amount will be paid at the branch before receiving the car' : 'سيتم دفع مبلغ تأميني في الفرع قبل استلام السيارة'}  </label>
                                    </div> */}
                                    <Button onClick={(e) => { handelReservations(e) }} /* disabled={!checked} */ loading={loadingPay} className='w-12' dir='ltr' label={isLang === "en" ? 'Pay now' : '   إدفــع الان'} severity="warning" />
                                </div>
                                <span className='mt-5 info_after_payment text-center'>
                                    {
                                        isLang === "en" ? 'Your personal data will be used to process your order and support your experience throughout this website and for other purposes described in our Privacy Policy.' : "سيتم استخدام بياناتك الشخصية لمعالجة طلبك ودعم تجربتك في جميع أنحاء هذا الموقع ولأغراض أخرى موصوفة في سياسة الخصوصية الخاصة بنا."
                                    }
                                </span>

                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}  >
                            <div className="app__login-image h-100">
                                <img src={img.Bg_Overlay} className='w-100 h-100 ' alt="images" loading='lazy' />
                                <div onClick={() => setModalShow(false)}>
                                    <div className="btn_back cursor-pointer shadow-lg" >
                                        <i className={`pi ${isLang === "en" ? "pi-arrow-right " : "pi-arrow-left"} `}></i>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                show={modalDone}
                onHide={() => setModalDone(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body style={{ height: '100vh' }} className='flex justify-content-center align-item-center '>
                    <div style={{ width: '400px' }} className='h-100  flex justify-content-center align-item-center flex-column '>
                        <img src={img.Image_Reservations} alt="image logo" loading='lazy' />
                        <h1 className='text-center text_done_Reservations'>    {isLang === "en" ? 'The order has been confirmed' : 'تم تاكيد الطلب'}</h1>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ResLimousine
