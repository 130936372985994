import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Icons from '../constants/Icons';
import { SharedData } from '../context/CallApi';
import { LocalizationContext } from '../context/LangChange';
import Component from './../constants/Component';
import { motion } from 'framer-motion';
import { Container } from 'react-bootstrap';

const Alghazal = () => {
  const location = useLocation();
  const [navBarBg, setnavBarBg] = useState('navbar-nav')
  let { isLang } = useContext(LocalizationContext);
  let { contactsData ,LoadingHome} = useContext(SharedData);

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (location.pathname !== '/') {
      setnavBarBg('navbar-navOnthorPAge');
    } else {
      setnavBarBg('navbar-navOnthorPAge')
    }

  }, [location.pathname])
  // URLs for the app on different platforms
  const appStoreUrl = 'https://apps.apple.com/us/app/الغزال-لتأجير-السيارات/id6479377415';
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.vigtas.algazal';

  // Function to detect the device
  const detectDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    } else if (/android/i.test(userAgent)) {
      return 'Android';
    }
    return 'unknown';
  };

  const deviceType = detectDevice();

  // Function to get the appropriate app link based on the device
  const getAppLink = () => {
    return deviceType === 'iOS' ? appStoreUrl : playStoreUrl;
  };

  return (
    <div className="loaders">
      {
        !LoadingHome ?
          <div className='relative '>

            <Component.NavBar navbarColor={navBarBg} />
            <Outlet ></Outlet>
            <Component.Footer />
            {deviceType !== 'unknown' && (
              <div className="app_download_app">
                <Container>
                  <div className="content flex justify-content-between  ">
                    <div className="open_app   ">
                      <a href={getAppLink()} className='btn_app shadow-sm' target="_blank" rel="noopener noreferrer">
                        {isLang === "en" ? 'Open App' : 'إلي التطبيق'}
                      </a>
                    </div>
                    <div className="content_app  ">
                      <span>{isLang === "en" ? 'Book through the app' : 'احجز من خلال التطبيق'}</span>
                    </div>
                  </div>


                </Container>
              </div>
            )}
            <div className={`${isLang === "en" ? 'chatEn' : 'chatAr'} chat`}>
              {/* <ChatPopup togglePopup={togglePopup} isOpen={isOpen} />
         */}
              <a href={`https://wa.me/${contactsData?.whatsapp}`} target='_blank' rel="noreferrer">
                <motion.div
                  className="phoneIcons round d-flex justify-content-center align-items-center "
                  whileHover={{ rotate: 360, y: -5, scale: 1.2 }}
                  transition={{ duration: 0.4 }}
                >
                  <Button
                    onClick={togglePopup}
                    id="chatButton"
                    icon={<Icons.Chat />}
                    rounded
                    text
                    raised
                    severity="success"
                    aria-label="Search"
                  />
                </motion.div>
              </a>

            </div>
          </div>
          :
          <Component.Loader />
      }
    </div>
  )
}

export default Alghazal
