
import axios from 'axios'
import { differenceInDays, format, parse } from 'date-fns'
import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Carousel } from "primereact/carousel"
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { Messages } from 'primereact/messages'
import { Toast } from 'primereact/toast'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import LocationGoogleMap from '../../../../GoogleMap/LocationGoogleMap'
import Component from '../../../../constants/Component'
import Icons from '../../../../constants/Icons'
import { SharedData } from '../../../../context/CallApi'
import { FiltersApis } from '../../../../context/CarFiltersApis'
import { CarFilterStates } from '../../../../context/FiltersCars'
import { LocalizationContext } from '../../../../context/LangChange'


const LimousineDetails = () => {
    let { isLang } = useContext(LocalizationContext);

    const validate = (values) => {
        const errors = {};

        if (values.endDate < values.startDate) {
            errors.endDate = isLang === "en" ? 'End date must be equal to or after the start date' : "يجب أن يكون تاريخ الانتهاء مساويًا لتاريخ البدء أو بعده";
        }

        return errors;
    };
    const validationSchemaPay = Yup.object().shape({
        //    startTime: Yup.string().required(isLang === "en" ? 'Delivery time required' : 'وقت التسليم مطلوب'),
    });
    ;
    let { fetchPersonal, clientStatus } = useContext(SharedData);

    const fetchPersonalRef = useRef(fetchPersonal);
    let navigate = useNavigate()
    useEffect(() => {

        fetchPersonalRef.current();
    }, []);

    useEffect(() => {
        if (clientStatus == 1) {
            localStorage.removeItem("client_id");
            localStorage.removeItem("CLIENT_FOUND");
            navigate('/auth/sign-in')
        }
    }, [clientStatus]);

    const toast = useRef(null);
    let { id } = useParams()
    const msgs = useRef(null);
    let { branchesData } = useContext(FiltersApis);


    let { setStoreBrancheTo, storeStartDate, clacDateLim, storeBrancheTo, setStoreBrancheForm,
        storeTime,
        setStoreTime, storeBrancheFrom, storeEndDate } = useContext(CarFilterStates);

    const [fetchCities, setFetchCities] = useState([]);
    const fetchCitiesData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/cities?type=1`;
        let { data } = await axios.get(url)
        setFetchCities(data);
    }

    const [getReservationsData, setGetReservationsData] = useState(null);
    const brancheFrom = [...branchesData.map((city) => ({
        name: isLang === "en" ? city.name : city.arabic_name,
        id: city.id,
    }))];
    const brancheTo = [...branchesData.map((city) => ({
        name: isLang === "en" ? city.name : city.arabic_name,
        id: city.id,
    }))];

    const [deliveryType, setDelivery] = useState(1);
    const [modalShow, setModalShow] = useState(false);
    const [CalcDays, setCalcDays] = useState(false);
    const [detailsData, setDetailsData] = useState([]);

    const fetchCarsDetails = async () => {
        const url = `${process.env.REACT_APP_API_URL}/cardetails?carId=${id}`;
        let { data } = await axios.get(url)
        setDetailsData(data);
    }

    const fetchCarsDetailsRef = useRef(fetchCarsDetails);
    const fetchCitiesDataRef = useRef(fetchCitiesData);
    useEffect(() => {
        window.scrollBy(0, -window.pageYOffset);
    }, [])
    useEffect(() => {
        fetchCarsDetailsRef.current();
    }, [id]);
    useEffect(() => {
        fetchCitiesDataRef.current();
    }, [])

    const getValidationSchema = (deliveryType) => {
        if (deliveryType === 0) {
            // When deliveryType is 0, no validation on cityId and address
            return Yup.object().shape({
                ...validationSchemaPay.fields,
                branchId: Yup.string().required(isLang === "en" ? 'Receiving branch is required' : 'فرع الاستلام مطلوب '),
                deliveringId: Yup.string().required(isLang === "en" ? 'Return  branch is required' : 'فرع التسليم مطلوب '),
            });
        } else {
            // When deliveryType is 1, apply validation on branchId
            return Yup.object().shape({
                ...validationSchemaPay.fields,
                cityId: Yup.string().required(isLang === "en" ? 'City is required' : 'مدينة مطلوبة'),
                address: Yup.string().required(isLang === "en" ? 'Address is required' : 'العنوان مطلوب'),
            });
        }
    };

    const formikValidationSchema = getValidationSchema(deliveryType);
    const formikReservationsApi = useFormik({
        initialValues: {
            carId: Number(id),
            clientId: Number(localStorage.getItem('client_id')),
            startDate: '',
            endDate: '',
            cityId: '',
            address: '',
            branchId: storeBrancheFrom !== null ? storeBrancheFrom : '',
            deliveringId: storeBrancheTo !== null ? storeBrancheTo : '',
            type: '',
            payType: 1,
            promo: '',
            tax: 0.15,
            total: '',
            days: '',
            startTime: storeTime,
        },
        validationSchema: formikValidationSchema, // Pass the dynamically created schema
        validate,
        onSubmit: async (values) => {
            if (formikReservationsApi.isValid) {
                let token = localStorage.getItem('client_id')
                if (!token) {
                    localStorage.setItem('openLogin', 'open')
                    navigate('/auth/sign-in')
                } else {
                    setModalShow(true)
                }
            } else {
                setModalShow(false)
            }
            setGetReservationsData(values);
            setStoreTime(null)
        }
    });



    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const clacDate = (start, end) => {
        const startDate = parse(start, 'dd/MM/yyyy', new Date());
        const endDate = parse(end, 'dd/MM/yyyy', new Date()); 
        // حساب الفارق بين التواريخ بالأيام
        const daysDifference = differenceInDays(endDate, startDate); 
        setCalcDays(daysDifference === 0 ? daysDifference + 1 : daysDifference+1);
    }
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const findCarTypeParam = urlSearchParams.get('car-lim');

        if (findCarTypeParam == 1) {
            setCalcDays(clacDateLim)
            setSelectedStartDate(format(new Date(storeStartDate), 'dd/MM/yyyy'))
            setSelectedEndDate(format(new Date(storeEndDate), 'dd/MM/yyyy'))
        } else {
            if (storeStartDate !== null && storeEndDate !== null) {
                clacDate(format(storeStartDate, 'dd/MM/yyyy'), format(storeEndDate, 'dd/MM/yyyy'));
                setSelectedStartDate(format(storeStartDate, 'dd/MM/yyyy'))
                setSelectedEndDate(format(storeEndDate, 'dd/MM/yyyy'))

            }

        }

    }, [])

    const handleStartDateChange = (start) => {
        const startDateStr = format(start, 'dd/MM/yyyy');
        setSelectedStartDate(startDateStr);
    };

    const handleEndDateChange = (end) => {
        const endDateStr = format(end, 'dd/MM/yyyy');
        setSelectedEndDate(endDateStr)
        // fetchReservationCheckApi(selectedStartDate, endDateStr);
        clacDate(selectedStartDate, endDateStr);
    };

    // const [reservationCheck, setReservationCheck] = useState(true);
    // const fetchReservationCheckApi = async (start, end) => {
    //     msgs.current.clear();
    //     try {
    //         const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/avalcheck?carId=${id}&start=${start}&end=${end}`);
    //         if (data.status) {
    //             // العربيه متاحه للايجار
    //             setReservationCheck(false)
    //             showSuccess()
    //         } else {
    //             // العربيه محجوزه
    //             showError()
    //             setReservationCheck(true)
    //         }
    //     } catch (error) {
    //         console.error('Network error:', error);
    //     }
    // };


    // const showError = () => {
    //     msgs.current.show({ sticky: true, severity: 'error', summary: `${isLang === "en" ? 'error' : 'خطا : '} `, detail: `${isLang === "en" ? 'The car is not available' : 'السيارة غير متاحه '} ` });
    // };
    // const showSuccess = () => {
    //     msgs.current.show({ sticky: true, severity: 'success', summary: `${isLang === "en" ? 'success' : 'متاح : '} `, detail: `${isLang === "en" ? 'The car is available' : 'السيارة متاحه '} ` });
    // };
    // Promo
    const [promoValue, setPromoValue] = useState(null);
    const [promoType, setPromoType] = useState(null);
    const [promoCodeValue, setPromoCodeValue] = useState(null);
    const [promoCheck, setPromoCheck] = useState(false);
    const [promoCheck1, setPromoCheck1] = useState(false);
    const [handelpromoCheck, sethandelPromoCheck] = useState(false);
    const promoCodeValid = () => {
        toast.current.show({ severity: 'success', summary: 'ًصحيح', detail: 'برومو كود  فعال' });
    };
    const promoCodeCheckInValid = () => {
        toast.current.show({ severity: 'error', summary: 'خطــا', detail: 'برومو كود غير  فعال ' });
    };
    const promoCodeCheckApi = async (promo) => {
        toast.current.clear();
        setPromoValue(null)
        setPromoType(null)
        setPromoCodeValue(null)
        setPromoCheck(false)
        setPromoCheck1(false)
        sethandelPromoCheck(false)
        if (promo) {
            toast.current.clear();
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/promocheck?promo=${promo}&start=${selectedStartDate}&end=${selectedEndDate}`);
                sethandelPromoCheck(true)
                if (data.status) {
                    setPromoCheck(false)
                    setPromoCheck1(true)
                    setPromoCodeValue(promo)
                    promoCodeValid()
                    if (data.type === 0) {
                        setPromoType(0)
                        setPromoValue(data?.value)
                    } else if (data.type === 1) {
                        setPromoValue(data?.value)
                        setPromoType(1)
                    }
                } else {
                    setPromoCheck(true)
                    setPromoCheck1(false)
                    setPromoValue('')
                    promoCodeCheckInValid()
                    setPromoCodeValue(null)

                }
            } catch (error) {
                console.error('Network error:', error);
            }

        }
    };

    useEffect(() => {
        formikReservationsApi.setFieldValue('startDate', storeStartDate);
        formikReservationsApi.setFieldValue('endDate', storeEndDate);
    }, [])

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 3,
            numScroll: 3,
        },
        {
            breakpoint: "600px",
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: "480px",
            numVisible: 1,
            numScroll: 1,
        },
    ];
    const changeEndDateAndTime = async () => {
        if (new Date(storeEndDate instanceof Date ? storeEndDate : formikReservationsApi.values.endDate)) {
            formikReservationsApi.setFieldValue("endTime", new Date())
        }
    }

    const changeStartDateAndTime = async () => {
        if (new Date(storeTime)) {
            formikReservationsApi.setFieldValue("startTime", new Date())
        }
    }
    return (
        <>
            <Toast ref={toast} position="bottom-center" />
            <Container  >

                <Row className='mt-5 pt-6' dir={isLang === "en" ? 'ltr' : 'rtl'}>

                    <Col xl={7} lg={7} md={12} sm={12} className='mt-7' >
                        <Component.Title title={`${isLang === "en" ? detailsData.brand : detailsData.brand_arabic}` + '  ' + `${isLang === "en" ? detailsData.sub_brand : detailsData.sub_brand_arabic}`} />
                        <div className="slider_card mt-40 " dir={isLang === "en" ? 'ltr' : 'rtl'}>
                            <ul className='mt-'>
                                <li >
                                    <Icons.SliderCarSedan />
                                    <strong className='bold'>{detailsData.year} {' '} {isLang === "en" ? detailsData.type : detailsData.type_arabic}  </strong>
                                </li>
                                <li className='mt-2'>
                                    <Icons.CarRent />
                                    {
                                        detailsData.lim === 1 ?
                                            <span>{isLang === "en" ? 'Limousine' : 'ليموزين'}</span> :
                                            <span>{isLang === "en" ? 'Individuals' : 'افراد '}</span>
                                    }
                                </li>
                                {
                                    detailsData?.lim === 1 ?
                                        <li className='mt-2'>
                                            <Icons.SliderCarMoney />
                                            <span>{detailsData.lim_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                            <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                        </li>
                                        :
                                        <li className='mt-2'>
                                            <Icons.SliderCarMoney />
                                            <span>{detailsData.price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                            <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                        </li>
                                }
                                <li className='mt-2'>
                                    <Icons.SliderCarPeople />
                                    <span>{detailsData.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                                </li>
                                {detailsData.aircondition !== 0 && <li className='mt-2'>
                                    <Icons.SliderCarSoil />
                                    <span>{isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                                </li>}
                                {
                                    detailsData?.lim === 0 &&
                                    <>
                                        {
                                            detailsData.att3 &&
                                            <li className='mt-2'>
                                                <Icons.CarSpeed />
                                                <span>{detailsData.att3} {isLang === "en" ? 'km per day' : ' كيلو في اليوم'}   </span>
                                            </li>
                                        }
                                    </>
                                }

                                {
                                    detailsData?.lim_km_price &&
                                    <>
                                        {
                                            detailsData?.lim === 0 &&
                                            <div className='mt-3 lim_km_price shadow-1'>
                                                <span> {isLang === "en" ? `Exceeding incurs a fine of ${detailsData?.lim_km_price} SAR per 1 km.` : ` علي كل كيلومتر زياده ${detailsData?.lim_km_price} ريال `} </span>
                                            </div>
                                        }
                                    </>
                                }

                            </ul>
                        </div>
                    </Col>
                    <Col xl={5} lg={5} md={12} sm={12} className='mt-8 slider_car_details' dir='ltr'>
                        <Carousel
                            value={detailsData?.banners}
                            itemTemplate={(banner) => (
                                <img
                                    src={banner.image}
                                    className="w-100 object-fit-contain"
                                    alt=""
                                />
                            )}
                            responsiveOptions={responsiveOptions}
                            circular
                        />
                    </Col>
                </Row>
            </Container>


            <div className={`mt-5  payment_bg relative ${detailsData?.banners?.length > 0 ? '' : 'banners_top_100'} `}>
                <Container>
                    <div className="app__payment_details   mb-40" dir={isLang === "en" ? 'ltr' : 'rtl'}>

                        <Row>
                            {/*             <Col xl={4} lg={4} md={12} sm={12} className=' ' >
                                <Component.Title title={`${isLang === "en" ? detailsData.brand : detailsData.brand_arabic}` + '  ' + `${isLang === "en" ? detailsData.sub_brand : detailsData.sub_brand_arabic}`} />
                                <div className="slider_card mt-40 " dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                    <ul>
                                        <li>
                                            <Icons.SliderCarSedan />
                                            <strong className='bold'>{detailsData.year} {' '} {isLang === "en" ? detailsData.type : detailsData.type_arabic}  </strong>
                                        </li>

                                        {
                                            detailsData?.lim === 1 ?
                                                <li>
                                                    <Icons.SliderCarMoney />
                                                    {detailsData.offer !== 0 && <span className='app__discount'>{detailsData.lim_price} {isLang === "en" ? 'SR' : 'ريال'} </span>}
                                                    {
                                                        detailsData.offer === 0 ?
                                                            <span>{detailsData.lim_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                            :
                                                            <span>{detailsData.offer_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                    }
                                                    <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                                </li>
                                                :
                                                <li>
                                                    <Icons.SliderCarMoney />
                                                    {detailsData.offer !== 0 && <span className='app__discount'>{detailsData.price} {isLang === "en" ? 'SR' : 'ريال'} </span>}
                                                    {
                                                        detailsData.offer === 0 ?
                                                            <span>{detailsData.price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                            :
                                                            <span>{detailsData.offer_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                    }
                                                    <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                                </li>
                                        }
                                        <li>
                                            <Icons.SliderCarPeople />
                                            <span>{detailsData.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                                        </li>

                                        {
                                            detailsData.att3 &&
                                            <li>
                                                <Icons.CarSpeed />
                                                <span>{detailsData.att3} {isLang === "en" ? 'km per day' : ' كيلو في اليوم'}   </span>
                                            </li>
                                        }
                                        {detailsData.aircondition !== 0 && <li>
                                            <Icons.SliderCarSoil />
                                            <span>{isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                                        </li>}
                                    </ul>
                                </div>
                            </Col> */}


                            <Col xl={8} lg={8} md={12} sm={12}>
                                <form onSubmit={formikReservationsApi.handleSubmit}>
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                                            <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                                                <Messages ref={msgs} className='w-100' />
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} className={isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'} >
                                            <Calendar
                                                id="startDate"
                                                name="startDate"
                                                value={storeStartDate instanceof Date ? storeStartDate : formikReservationsApi.values.startDate}
                                                placeholder={isLang === "en" ? ' Pickup Date' : '  تاريخ بدأ الإيجار '}
                                                onChange={async (e) => {
                                                    await formikReservationsApi.setFieldValue('startDate', e.value);
                                                    await formikReservationsApi.setFieldValue("endDate", null);
                                                    await handleStartDateChange(e.value);
                                                    await changeStartDateAndTime()
                                                }}
                                                showIcon
                                                className="w-full"
                                                dir={isLang === "en" ? 'ltr' : 'rtl'}
                                                icon="pi pi-calendar-plus"
                                                minDate={new Date()}
                                            />

                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} className={isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'} >
                                            <Calendar
                                                id="endDate"
                                                name="endDate"
                                                value={storeEndDate instanceof Date ? storeEndDate : formikReservationsApi.values.endDate}
                                                placeholder={isLang === "en" ? 'Return Date' : '  تاريخ نهايه الإيجار '}
                                                onChange={async (e) => {
                                                    await formikReservationsApi.setFieldValue('endDate', e.value);
                                                    await handleEndDateChange(e.value);
                                                    await changeEndDateAndTime()
                                                }}
                                                showIcon
                                                className="w-full"
                                                icon="pi pi-calendar-plus"
                                                minDate={
                                                    formikReservationsApi.values.startDate
                                                        ? new Date(formikReservationsApi.values.startDate.getTime())
                                                        : new Date()
                                                }
                                                required
                                            />
                                            {formikReservationsApi.errors.endDate && (
                                                <small className="text-red-300">{formikReservationsApi.errors.endDate}</small>
                                            )}
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} className={isLang === "en" ? 'Calendar_paymentEn mt-3' : 'Calendar_payment mt-3'} dir='ltr'>
                                            <div className="flex flex-column gap-2">

                                                <div className={isLang === "en" ? ' ' : 'calendar_styleAr'}>
                                                    <Calendar
                                                        name="startTime"
                                                        value={storeTime}
                                                        onChange={(e) => {
                                                            formikReservationsApi.setFieldValue("startTime", e.value);
                                                            setStoreTime(e.value)
                                                        }}
                                                        placeholder={isLang === "en" ? 'Pickup Time' : 'وقت بدأ الإيجار'}
                                                        showIcon
                                                        showTime={true}
                                                        hourFormat="24"
                                                        className='w-full'
                                                        dir="ltr"
                                                        timeOnly
                                                        showPeriod={true} // تمكين تغيير AM و PM
                                                        iconPos={isLang === "en" ? 'right' : 'left'}
                                                        icon="pi pi-clock"
                                                        minDate={new Date(storeStartDate instanceof Date ? storeStartDate : formikReservationsApi.values.startDate)} // تحديد الوقت الحالي كحد أدنى


                                                    />
                                                    {formikReservationsApi.errors.startTime && formikReservationsApi.touched.startTime ?
                                                        <div div dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                                            <Message severity="error" text={formikReservationsApi.errors.startTime} />
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                        </Col>

                                        {/* <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                            <Dropdown
                                                id="type"
                                                name="type"
                                                value={formikReservationsApi.values.type}
                                                optionLabel="name"
                                                onChange={(e) => {
                                                    setDelivery(e.value);
                                                    formikReservationsApi.setFieldValue("type", e.value)
                                                }}
                                                onBlur={formikReservationsApi.handleBlur}
                                                options={delivery}
                                                placeholder={isLang === "en" ? ' How to receive the car  ' : ' مكان استلام السيارة  '}
                                                className="w-full  "

                                            />
                                            {formikReservationsApi.touched.type && formikReservationsApi.errors.type && (
                                                <small className="p-error">{formikReservationsApi.errors.type}</small>
                                            )}
                                        </Col> */}
                                        {
                                            deliveryType === 0 ?
                                                <>
                                                    <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                                        <Dropdown
                                                            id="branchId"
                                                            name="branchId"
                                                            filter
                                                            value={storeBrancheFrom} // تحديد القيمة المختارة
                                                            options={brancheFrom}
                                                            optionLabel="name"

                                                            optionValue="id"
                                                            onChange={(e) => {
                                                                formikReservationsApi.setFieldValue('branchId', e.value)
                                                                setStoreBrancheForm(e.value)
                                                            }}
                                                            className="w-100"
                                                            placeholder={isLang === "en" ? 'Pickup Branch ' : 'فرع الاستلام'}

                                                        />
                                                        {formikReservationsApi.touched.branchId && formikReservationsApi.errors.branchId && (
                                                            <small className="p-error">{formikReservationsApi.errors.branchId}</small>
                                                        )}
                                                    </Col>

                                                    <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                                        <Dropdown
                                                            filter
                                                            id="deliveringId"
                                                            name="deliveringId"
                                                            value={storeBrancheTo} // تحديد القيمة المختارة
                                                            options={brancheTo}
                                                            optionLabel="name"



                                                            onChange={(e) => {
                                                                formikReservationsApi.setFieldValue('deliveringId', e.value)
                                                                setStoreBrancheTo(e.value)
                                                            }}
                                                            optionValue="id"
                                                            onBlur={formikReservationsApi.handleBlur}
                                                            className="w-full  "
                                                            dir={isLang === "en" ? 'ltr' : 'rtl'}
                                                            placeholder={isLang === "en" ? 'Return Branch ' : '     فرع التسليم  '}
                                                        />
                                                        {formikReservationsApi.touched.deliveringId && formikReservationsApi.errors.deliveringId && (
                                                            <small className="p-error">{formikReservationsApi.errors.deliveringId}</small>
                                                        )}
                                                    </Col>
                                                </>
                                                :
                                                <>
                                                    <Col xl={6} lg={6} md={6} sm={12} className='mt-3 dropdown_itemsAr'>
                                                        <Dropdown
                                                            filter
                                                            id="cityId"
                                                            name="cityId"
                                                            value={formikReservationsApi.values.cityId}
                                                            options={fetchCities.map(city => ({
                                                                name: isLang === 'en' ? city.name : city.arabic_name,
                                                                value: city.id,
                                                            }))}
                                                            optionLabel="name"
                                                            onChange={(e) => formikReservationsApi.setFieldValue("cityId", e.value)}
                                                            onBlur={formikReservationsApi.handleBlur}
                                                            className="w-full    "
                                                            dir={isLang === "en" ? 'ltr' : 'rtl'}
                                                            placeholder={isLang === "en" ? ' City' : 'المدينة'}

                                                        />
                                                        {formikReservationsApi.touched.cityId && formikReservationsApi.errors.cityId && (
                                                            <small className="p-error">{formikReservationsApi.errors.cityId}</small>
                                                        )}
                                                    </Col>
                                                    <Col xl={12} lg={12} md={6} sm={12} className='mt-3'>
                                                        <InputTextarea
                                                            id="address"
                                                            name="address"
                                                            type="text"
                                                            onBlur={formikReservationsApi.handleBlur}
                                                            onChange={formikReservationsApi.handleChange}
                                                            rows={4} cols={30}
                                                            className="w-full  "
                                                            aria-describedby="username-help"
                                                            placeholder={isLang === "en" ? 'Enter your address...' : 'أدخل عنوانك...'}

                                                        />
                                                        {formikReservationsApi.touched.address && formikReservationsApi.errors.address && (
                                                            <small className="p-error">{formikReservationsApi.errors.address}</small>
                                                        )}
                                                        {
                                                            deliveryType === 1 &&
                                                            <div className='map_sm_screen'>
                                                                <LocationGoogleMap
                                                                    height="300px"
                                                                />
                                                            </div>
                                                        }
                                                    </Col>
                                                </>
                                        }
                                        {/* <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                            <Dropdown
                                                id="payType"
                                                name="payType"
                                                value={formikReservationsApi.values.payType}
                                                optionLabel="name"
                                                onChange={(e) => formikReservationsApi.setFieldValue("payType", e.value)}
                                                onBlur={formikReservationsApi.handleBlur}
                                                options={payType}
                                                placeholder={isLang === "en" ? 'Payment Method' : 'طريقة الدفع '}
                                                className="w-full  "

                                            />
                                            {formikReservationsApi.touched.payType && formikReservationsApi.errors.payType && (
                                                <small className="p-error">{formikReservationsApi.errors.payType}</small>
                                            )}
                                        </Col> */}
                                        <Col xl={6} lg={6} md={6} sm={12} className='mt-3'>
                                            <InputText
                                                className={`w-full   ${promoCheck ? 'p-invalid' : 'p-valid'}`}
                                                aria-describedby="username-help"
                                                placeholder={isLang === "en" ? 'Enter Promo Code' : '   ادخل كود الخصم '}
                                                id="promo"
                                                name="promo"
                                                type="text"
                                                onBlur={(e) => {
                                                    formikReservationsApi.handleBlur(e);
                                                    promoCodeCheckApi(e.target.value); // Trigger promo code check
                                                }}
                                                onChange={formikReservationsApi.handleChange}
                                            />

                                            {
                                                handelpromoCheck &&
                                                <>
                                                    {promoCheck ?
                                                        <Message severity="error" text={`${promoValue} برومو كود غير فعال`} /> :
                                                        <Message severity="success" text={` برومو كود فعال   معاك خصم ${promoType === 0 ? `${promoValue + '%'}` : `${promoValue + '  ريال '}`} `} />}
                                                </>

                                            }

                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12} className='mt-3'></Col>




                                        <Row>
                                            <Col xl={4} lg={3} md={3} sm={12} className=' payment_footer_btn'>
                                                {/*   {
                                                            !reservationCheck && */}
                                                <div className="cal_price">
                                                    <Component.Title title={isLang === "en" ? 'Total Amount ' : '  إجمالى المبلغ '} />
                                                    <div className="price_summary">
                                                        {
                                                            detailsData.lim === 1 ?
                                                                <h4> {detailsData.lim_price * CalcDays} {isLang === "en" ? 'SAR' : 'ريال'}  </h4> :
                                                                <h4> {detailsData.price * CalcDays} {isLang === "en" ? 'SAR' : 'ريال'}  </h4>
                                                        }
                                                    </div>
                                                </div>
                                                {/* } */}
                                            </Col>
                                            <Col xl={8} lg={9} md={9} sm={12} className='payment_footer_btn payment_footer_btn2  gap-5 flex flex-row align-items-end'>
                                                <Row className='w-100 payment_footer_btn_gap'>
                                                    <Col xl={5} lg={5} md={6} sm={12}  >
                                                        <Link to={`${localStorage.getItem('path')}`}>
                                                            <div className="car_back   w-100" >
                                                                <Button className='w-12' icon={`pi ${isLang === "en" ? 'pi-caret-left' : 'pi-caret-right'}`} dir={isLang === "en" ? 'ltr' : 'rtl'}
                                                                    label={isLang === "en" ? 'Back to cars ' : '    الرجوع للسيارات'} severity="secondary" />
                                                            </div>
                                                        </Link>
                                                    </Col>
                                                    <Col xl={7} lg={7} md={6} sm={12} >
                                                        <div className="btn_pay w-100">
                                                            <Button className='w-12' /* disabled={!formikReservationsApi.isValid} */ icon={`pi ${isLang === "en" ? 'pi-caret-right' : 'pi-caret-left'}`} dir='ltr' label={isLang === "en" ? 'Complete payment ' : 'إكمال الدفع'} severity="secondary" />
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row>
                                </form>
                            </Col>

                            {
                                deliveryType === 1 &&
                                <Col xl={4} lg={4} md={12} sm={12} className=' mt-3' >
                                    <div className='map_lg_screen'>
                                        <LocationGoogleMap
                                            height="500px"

                                        />
                                    </div>
                                </Col>
                            }
                        </Row>


                    </div>
                </Container >

                <Component.ResLimousine
                    promoCheck={promoCheck1}
                    promoType={promoType}
                    promoValue={promoValue}
                    getReservationsData={getReservationsData}

                    price={detailsData.price_before_tax}
                    price_after_tax={detailsData.price}
                    totalPrice={detailsData.price_before_tax * CalcDays}

                    price_lim={detailsData.lim_price_before_tax}
                    price_after_tax_lim={detailsData.lim_price}
                    total_Price_lim={detailsData.lim_price_before_tax * CalcDays}

                    lim={detailsData.lim}
                    priceTax={getReservationsData?.tax}
                    carImage={detailsData.image}
                    carName={detailsData.name}
                    // DeliveryExpenses={150}
                    DeliveryExpenses={detailsData.delivery_price}
                    show={modalShow}
                    setModalShow={setModalShow}
                    onHide={() => setModalShow(false)}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    CalcDays={CalcDays}
                    promoCodeValue={promoCodeValue}
                    deliveryType={deliveryType}
                />

                <div className='absolute Bg_payment'>
                    <Icons.Bg_payment />
                </div>


            </div >

        </>
    )
}

export default LimousineDetails