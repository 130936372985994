import Icons from "../../constants/Icons";
import img from "../../constants/Img";

export const Data = {
    getPrivacyPolicy() {
        return [
            {
                title: 'What information will we collect? ',
                titleAr: 'ما المعلومات التي سنقوم بجمعها؟  ',
                descAr: 'عند استخدام الخدمات المقدمة على هذا الموقع و/أو تطبيق الهاتف المحمول، سيُطلب منك تقديم معلومات معينة:',
                desc: ' When you use services provided on this website and/or mobile application, you will be asked to provide certain information:',
                list: [
                    {
                        title: ' ',
                        titleAr: ' ',
                        body: 'Personal information, which includes your email address, name, phone number, device ID and IP address. ',
                        bodyAr: 'معلومات شخصية تتضمن بريدك الإلكتروني واسمك ورقم هاتفك ورقم تعريف جهازك وعنوان بروتوكول الإنترنت. ',
                    },
                    {
                        title: ' ',
                        titleAr: ' ',
                        body: 'Transactional information, such as rental car details. ',
                        bodyAr: 'معلومات المعاملة، ومنها تفاصيل برنامج الرحلة. ',
                    },
                    {
                        title: ' ',
                        titleAr: ' ',
                        body: 'Booking information, such as pick-up and drop-off details, type of car, and rental period. ',
                        bodyAr: 'معلومات الحجز، ومنها الوجهات والتواريخ. ',
                    },
                    {
                        title: ' ',
                        titleAr: ' ',
                        body: 'Credit/debit card information for payments. ',
                        bodyAr: 'معلومات البطاقة الائتمانية/بطاقة الصرف الآلي للدفع. ',
                    },
                  /*{
                        title: ' ',
                        titleAr: ' ',
                        body: 'Information about your device (such as your IP address, session ID, mobile/desktop device properties, and metadata) and browser; ',
                        bodyAr: 'معلومات عن جهازك (مثل عنوان الـ IP ومعرف الجلسة وخصائص الهاتف الجوال/ الحاسوب والبيانات الوصفية) والمتصفح؛ ',
                    }, */
                    {
                        title: ' ',
                        titleAr: ' ',
                        body: 'Other information, such as your browsing activity on the website and mobile app. ',
                        bodyAr: 'معلومات أخرى، ومنها معلومات عن تصفحك للموقع أو التطبيق. ',
                    },


                ],
                body: "سنقوم بتخزين معلوماتك الشخصية بتنسيق آمن ومشفر. نحن لا نقوم بتخزين بيانات بطاقة الائتمان/الخصم الخاصة بك. جميع معلومات الدفع وبطاقات الائتمان/الخصم آمنة ومشفرة ويتم التعامل معها من قبل موفري خدمات الدفع المعتمدين بالكامل. إذا كنت تقوم بحجز أو حجز سيارة نيابة عن شخص آخر، فيجب عليك الحصول على موافقته لاستخدام معلوماته الشخصية. نحن نمضي قدمًا على أساس حصولك على الموافقة المذكورة أعلاه. ",
                bodyEn: 'We will store your personal information in a secure and encrypted format. We do not store your credit/debit card data. All credit/debit card and payment information is secure, encrypted and handled by fully-certified payment providers. If you are reserving or booking a car on behalf of someone else, you must obtain their consent to use their personal information. We proceed on the basis that you have obtained the aforementioned consent. ',
            }, 
            {
                title: 'We will use this information in the following ways ',
                titleAr: 'سنقوم باستخدام هذه المعلومات بالطرق التالية ',
                desc: ' ',
                descAr: '   ',
                list: [
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'To fulfill our agreement with you, including processing your rental car bookings, sending you your booking confirmation, conducting payment transactions for the services available on the site or contacting you if there is a problem with your reservation.  ',
                        bodyAr: 'الوفاء باتفاقنا معك بما في ذلك تجهيز الرحلات وحجوزات الفنادق والباقة، وإرسال مسار الرحلة الخاصة بك، أو التواصل معك في حال وجود أي مشكلة تتعلق بالحجز الخاص بك. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'To register you with our website/mobile application and administer our website services where you have registered to receive these. You can unsubscribe from these services by contacting us. ',
                        bodyAr: 'لتسجيل اسمك على موقعنا أو تطبيقات الهاتف الجوال، وبالتالي يمكنك إدارة حسابك على موقعنا لتلقي جميع خدماتنا. كما يمكنك إلغاء الاشتراك عبر التواصل معنا في حال لم تعد ترغب بالتمتع بهذه الخدمات. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'To answer any queries which you may send to us by e-mail. ',
                        bodyAr: 'للإجابة على أي استفسار قمت بإرساله إلينا عبر البريد الإلكتروني. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'For direct marketing purposes. To enable us to review, develop and improve the services which we offer and provide you and other customers (via mail, email, telephone or otherwise) with information about new products and services and special offers we think you will find valuable. ',
                        bodyAr: 'لأغراض التسويق المباشر، على النحو المبين بالتفصيل أدناه. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'To personalise your experience and give you a better service (your information helps us to better respond to your individual needs). ',
                        bodyAr: 'لإجراء الحجوزات، شراء التذاكر، ومعاملات الدفع في الخدمات المتوفرة على الموقع. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'To improve the user-friendliness of the site (we continually strive to improve the site offerings based on the information we receive from you).  ',
                        bodyAr: 'لمنحك تجربة خاصة وتقديم أفضل الخدمات (والعمل على تلبية احتياجاتك الفردية على أكمل وجه عبر تزويدنا بمعلوماتك الشخصية). ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs) and for website traffic analysis. ',
                        bodyAr: 'لتطوير أداء استخدام الموقع: (نسعى دوماً لتحسين خدمات الموقع استناداً إلى المعلومات التي تزودنا بها). ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: ' To administer a contest, promotion, survey or other site features. ',
                        bodyAr: 'لتحسين خدمة العملاء (هذه المعلومات تساعدنا على تلبية طلباتك الموجهة لفريق خدمة العملاء ودعم احتياجاتهم) وتحليل حركة البيانات على الموقع. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: ' To send emails, SMS, website and mobile app notifications as well as other communications for providing services, responding to inquiries, and/or other requests or questions. ',
                        bodyAr: 'لإدارة أي مسابقة أو عرض ترويجي، أو استطلاع إحصائي أو إحدى ميزات الموقع الأخرى. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'To respond to queries of the authorities with regard to your accessing of the site and services availed by you.  ',
                        bodyAr: 'لإرسال رسائل إلى البريد الإلكتروني أو رسائل قصيرة، إشعارات للمستخدمين عبر الموقع أو التطبيق أو غيرها من الوسائل المتاحة لتقديم كافة الخدمات، والإجابة على جميع الاستفسارات والطلبات، وغيرها من الأسئلة. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: ' Automatic logging of session data ',
                        bodyAr: 'للإجابة على استفسارات السلطات فيما يتعلق باستخدامك للموقع والخدمات المتاحة إليك. ',
                    },
                    {
                        title: ' ',
                        titleAr: '  ',
                        body: 'Automatic data entry recording. Of course, you are responsible for the confidentiality of your password and personal account information. Therefore, we ask you to be careful to maintain the confidentiality of this information, especially when you are connected to the Internet. ',
                        bodyAr: 'التسجيل التلقائي لدخول البيانات. بالتأكيد، أنت المسؤول الوحيد عن سرية كلمة المرور الخاصة بك، ومعلومات حسابك الشخصي. لذا نرجو منك الحرص على الحفاظ على سرية هذه المعلومات وبالأخص عندما تكون متصلاً بالإنترنت. ',
                    },
                ],
                body: 'من الضروري لدينا معرفة جميع أسماء الركاب أو الأسماء المرتبطة بالحجوزات. إذا كنت قد قمت بالحجز بالنيابة عن شخص آخر، فمن المفترض أن تكون قد حصلت على موافقته لاستخدام معلوماته الشخصية. وسوف نكمل الإجراءات بناءً على الموافقة المذكورة أعلاه. ',
                bodyEn: 'Of course, you are solely responsible for maintaining the secrecy of your passwords, and your site membership account information. Please be very careful, responsible, and alert with this information, especially whenever you’re online. '
            },
            {},
            {
                title: 'Who do we share your information with?  ',
                titleAr: 'ما الجهات التي نقوم بمشاركة معلوماتك معها؟ ',
                desc: 'Our customer support offices in the network. ',
                descAr: ' ',
                list: [
                    {
                        title: '  ',
                        titleAr: ' ',
                        body: 'Trustworthy third parties that we use for the provision of certain services, such as enabling our customers to book cars quickly and easily.  ',
                        bodyAr:'مع جميع فروع مكاتب فريق خدمة العملاء. ',
                    },
                    {
                        title: '  ',
                        titleAr: ' ',
                        body: 'Third party organisations involved in credit card authorisation. ',
                        bodyAr:'مع أطراف أخرى جديرة بالثقة والتي نقوم بالتعامل معها لتوفير خدمات معينة: مثل الإتاحة لعملائنا بحجز رحلات طيران أو إتمام حجز رحلات أو استئجار سيارات أو حجز فندق أو إتمام حجز رحلة سياحية أو غيرها من الخدمات بسرعة وسهولة. ',
                    },
                    {
                        title: '  ',
                        titleAr: ' ',
                        body: 'Third party organisations assisting in fraud detection & prevention. ',
                        bodyAr:'مع أطراف أخرى مشاركة في سياسة ترخيص بطاقة الائتمان. ',
                    },
                    {
                        title: '  ',
                        titleAr: ' ',
                        body: 'With authorities, if we have a legal obligation to do so. ',
                        bodyAr:'مع أطراف أخرى تساعد في في الكشف عن الاحتيال والوقاية منه. ',
                    },
                    {
                        title: '  ',
                        titleAr: ' ',
                        body: 'Our employees and agents to do any of the above on our behalf, now or in the future. ',
                        bodyAr:'إذا كان لدينا مهمة للقيام بذلك أو إذا كان القانون يسمح لنا بذلك. ',
                    },
                    {
                        title: '  ',
                        titleAr: ' ',
                        body: 'With our employees and agents to carry out one of the tasks we have indicated above, now or in the future.',
                        bodyAr:'مع موظفينا ووكلائنا للقيام بإحدى المهام التي قمنا بالإشارة إليها أعلاه، في الحاضر أو في المستقبل. ',
                    },
                    {
                        title: '  ',
                        titleAr: ' ',
                        body: 'The companies within our corporate family and with our parent company and corporate affiliates. This sharing enables us to provide you with information about products and services, both travel-related and other, which might interest you. To the extent that our parent company and corporate affiliates have access to your information, they will follow practices that are at least as restrictive as the practices described in this privacy policy. They also will comply with applicable laws governing the transmission of promotional communications and, at a minimum, give you an opportunity in any commercial email they send to choose not to receive such email messages in the future. If you choose not to provide certain personal information we request, you will still be able to visit our site but you may be unable to access certain options or services.  ',
                        bodyAr:'مع المؤسسات التي بيننا وبينها شراكة والتي تقع ضمن مجموعتنا وشركتنا الأم والشركات الأخرى التابعة لها، حيث تمكننا هذه المشاركة من تقديم معلومات حول خدمات ومنتجات، ذات صلة بالرحلات وغيرها والتي قد تثير اهتمامك. وستكون لدى هذه الشركات صلاحية محدّدة للاطلاع على بياناتك الخاصة طبقاّ لما ورد ذكره في سياسة الخصوصية. كما ستقوم هذه الشركات بالامتثال للقوانين المعمول بها، والتي تنظمها وسائل الاتصالات وتمنحك على أقل تقدير الفرصة لاختيار عدم استلام رسائل مشابهة عبر بريدها الإلكتروني التجاري في المستقبل. إذا قمت باختيار عدم مشاركتنا لبعض البيانات الشخصية المطلوبة، بإمكانك زيارة صفحتنا لكن قد لا تتمكن من الوصول لبعض الخيارات أو الخدمات.  ',
                    },
             

                ]
            },
            {
                title: 'What you need to know when using the Alghazal mobile apps the (mobile apps)?   ',
                titleAr: 'ما الذي تحتاج معرفته عند استخدامك تطبيق الغزال عبر جوالك أو جهازك اللوحي ',
                desc: ' When you use a mobile app, we collect and use information about you in the same way and for the same purposes as we do when you use our website.   In addition to the same information we collect and use when using the Alghazal website, we also use some other information that we collect automatically when you use our mobile apps. Specifically:  ',
                descAr: 'عند استخدامك لتطبيقات الهاتف الجوال، نقوم بجمع واستخدام المعلومات عنك بنفس الطريقة ولنفس الأغراض عند استخدامك لموقعنا. نقوم أيضاً بإضافة المعلومات التي نقوم بجمعها عند استخدام موقع الغزال واستخدام بعض المعلومات الأخرى التي نقوم بجمعها تلقائياً عند استخدامك لتطبيقاتنا عبر هاتفك الجوال، على وجه التحديد:  ',

                list: [
                    {
                        title: ' ',
                        titleAr: '',
                        body: 'We collect information about mobile app functionality that you access and use. This allows us to identify those areas of the mobile app that are of interest to our customers so that we can refine and continuously improve the mobile app. The information we collect for this purpose does not enable us to directly identify you. ',
                        bodyAr: 'نقوم وبشكل تلقائي بجمع المعلومات حول تطبيق الهاتف الجوال، والتي يمكنك الوصول إليها واستخدامها. مما يسمح لنا بتحديد مجالات اهتمام عملائنا على تطبيقات الهاتف الجوال، ولكي لنتمكن من تطويرها وتحسينها بشكل دائم. ولا تمكننا هذه المعلومات التي قمنا بجمعها لهذا الغرض من التعرف عليك بشكل مباشر. ',
                    }, 
                    {
                        title: ' ',
                        titleAr: '',
                        body: `Each mobile app also sends us the device's unique device identifier (or "UDI"), a sequence of numbers or characters that are unique to your mobile device, as well as the unique Identifier for Advertisers (IDFA) and Identifier For Vendor (IDFV). We use this only on the first opening of the mobile app so that we can confirm to our advertising networks the number of downloads resulting from clicks on their respective banner ads and other marketing tools. `,
                        bodyAr: 'يرسل كل تطبيق للهاتف الجوال إلينا معرف جهاز فريد من نوعه (أو "UDI")، وهو عبارة عن سلسلة من الأرقام أو الأحرف الفريدة لهاتفك الجوال، بالإضافة إلى معرف جهاز فريد من نوعه للمعلنين (أو "IDFA")، ومعرف جهاز فريد من نوعه للمزودين (أو "IDFV"). ونقوم باستخدامها فقط عند أول استخدام لتطبيقات الهاتف الجوال لتأكيد نتائج أعداد تنزيل البيانات التي تتم من عملية النقر على لافتاتنا الإعلانية وأدواتنا التسويقية، لشبكة إعلاناتنا. ',
                    }, 
                    {
                        title: ' ',
                        titleAr: '',
                        body: `When you use a mobile app, you can choose to allow access to your current location – provided by your mobile device using GPS or similar technologies – in order to identify nearby Alghazal branches. If you choose to allow access, this location data may be collected anonymously as part of search requests logged by our servers. It may also be used for geo-targeting purposes to deliver relevant marketing messages to you and enhance your experience. You can switch off the mobile app’s access to your location at any time through your phone settings menu. This might however restrict usage of any location-dependent services or options available on our mobile app. `,
                        bodyAr: 'عند استخدامك لتطبيق الموبايل، يمكنك اختيار السماح بالوصول لموقعك الحالي - ميزة يتم توفيرها من قبل هاتفك الجوال عبر استخدام الـ GPS أو تقنيات مشابهة - بهدف تحديد الفنادق أو المطارات القريبة منك. إذا اخترت السماح بتفعيل هذه الميزة، فمن الممكن أن تقوم ذاكرة هذا الموقع وبشكل مجهول بجمع المعلومات عن موقعك كجزء من طلبات البحث التي تم تسجيلها من قبل خادمنا، وذلك لإيصال رسائل تسويقية ذات صلة وتحسين تجربتك. كما يمكنك إبطال تفعيل ميزة تحديد موقعك في تطبيق الهاتف الجوال وفي أي وقت من خلال قائمة إعدادات جوالك. ولكن قد يحد هذا من الاستفادة من الخدمات التي تعتمد على المكان أو الخيارات المتاحة على تطبيق الجوال لدينا. ',
                    }, 
                    {
                        title: ' ',
                        titleAr: '',
                        body: `You always have the ability to control what information a mobile app sends to us. You can exercise this control either by changing the settings of the mobile app under its setting menu or changing the settings of your mobile device. Alternatively, you can remove the mobile app from your mobile device entirely and access our services through our website. `,
                        bodyAr: 'تستطيع بشكل مستمر التحكم بنوعية المعلومات التي يتم إرسالها إلينا عبر تطبيق الهاتف الجوال. يمكنك التحكم في هذه الخدمة إما عن طريق إجراء بعض التعديلات في إعدادات تطبيق الهاتف الجوال تحت عنوان قائمة الإعدادات أو تغيير إعدادات هاتفك الجوال. بدلاً من ذلك، يمكنك إزالة تطبيق الموبايل من جهازك المحمول كلياً والوصول لخدماتنا عبر موقعنا. ',
                    }, 
                    {
                        title: ' ',
                        titleAr: '',
                        body: `At times, we record your booking process to understand booking behaviour and improve our product. However, we do not record sensitive information such as card details. `,
                        bodyAr: 'سنقوم في بعض الأحيان بتسجيل عملية الحجز التي تقوم بها لفهم تجربة حجزك وتحسين منتجنا. إلا أننا لا نعمل على تسجيل المعلومات الخاصة بك مثل بيانات بطاقتك. ',
                    }, 
                    
                ]
            },

            {
                title: 'Cookies policy ',
                titleAr: 'سياسة "الكوكيز" ',
                desc: 'There is a technology called "cookies" which may be used by us to provide you with customised information from Alghazal website or mobile applications. A cookie is an element of data that a website or mobile application can send to your browser, which may then store it on your system. Cookies allow us to understand who has seen which pages and advertisements, to determine how frequently particular pages are visited and to determine the most popular areas of our website. Cookies also allow us to make Ghazal’s website or mobile applications more user-friendly by, for example, allowing us to take you to the language site of last use so that we can give you a better experience when you return to our website. Most web browsers automatically accept cookies. You do not have to accept cookies, and you should read the information that came with your browser software to see how you can set it up to notify you when you receive a cookie, so you can decide whether to accept it. ',
                descAr: 'هناك تقنية تدعى "الكوكيز"، تستخدم لتوفير المعلومات التي تم تعديلها حسب الطلب من موقع الغزال أو تطبيقات الهاتف الجوال. الكوكيز عبارة عن عنصر من البيانات على موقع أو تطبيق الهاتف الجوال والتي من الممكن إرسالها إلى المتصفح الخاص بك وتخزينها على نفس النظام فيما بعد. تسمح لنا الكوكيز بالتعرف على الأشخاص الذين اطلعوا على صفحاتنا وإعلاناتنا، لتحديد عدد المرات التي تمت بها زيارة صفحات معينة وتحديد المحطات الأكثر زيارة على موقعنا. وتسمح لنا أيضاً بجعل تجربة تصفح موقع وتطبيق الغزال أكثر سهولة وراحة، على سبيل المثال السماح لنا بتوجيهك إلى اللغة التي تم استخدامها على الموقع آخر مرة، لمنحك تجربة أفضل عند إعادة استخدام الموقع. معظم متصفحات الويب تقبل الكوكيز تلقائياً. ولست مضطراً لقبول الكوكيز، إلا أنّه يتوجب عليك قراءة المعلومات الخاصة بمتصفح جهازك لترى كيفية إعداده لإرسال تنبيه إليك لحظة استلامك للكوكيز، وعندها بإمكانك اتخاذ القرار بقبولها أو لا. ',
                list: []
            },

        ];
    },



    getTermsPopup() {
        return [
            {
                title: 'First: Principles of leasing: - ',
                titleAr: 'أولا: أسس التأجير : -',
                desc: '  ',
                descAr: '  ',
                list: [
                    {
                        title: 'The minimum rental period is one day (twenty-four hours).',
                        titleAr: ' الحد الأدنى للإيجار هو يوم واحد (أربع وعشرون ساعة).',
                        desc: '',
                        descAr: '',
                        ListUl: []
                    },
                    {
                        title: 'The contract period can be extended with the approval of the landlord and tenant, provided that the necessary procedures for this are specified in the contract',
                        titleAr: 'مدة العقد قابلة للتمديد بموافقة المؤجر والمستأجر على أن يحدد في العقد الإجراءات اللازمة لذلك.',
                        desc: '',
                        descAr: '',
                        ListUl: []
                    },
                    {
                        title: 'The rental value is calculated for the hours of delay in the specified time for returning the car by the renter, with a maximum of 3 hours = half a day and 6 hours = a Full Day.',
                        titleAr: 'تحتسب قيمة الإيجار عن ساعات التأخير عن الموعد المحدد لإعادة السيارة من قبل المستأجر وبحد أقصى ٣ ساعات = نصف يوم و ٦ ساعات = يوم كامل .                        ',
                        desc: '',
                        descAr: '',
                        ListUl: []
                    },
                    {
                        title: 'In the event that the contract extension is not requested by the lessor or the lessor does not agree to the extension, the lessee will bear additional costs for the excess period until the car is returned to the lessor or retrieved by him, equivalent to 50% of the daily rental value if the delay exceeds forty-eight hours, in addition to costs. The rental agreed upon in the contract, including covering the costs of accidents according to the principles stated in the contract. ',
                        titleAr: 'في حالة عدم طلب تمديد العقد من قبل المستأجر أو عدم موافقة المؤجر على التمديد فإن المستأجر يتحمل تكاليف إضافية عن المدة الزائدة وحتى إعادة السيارة إلى المؤجر أو استرجاعها من قبله وبما يعادل ٥٠% من قيمة الايجار اليومي إذا تجاوز التأخير ثمان وأربعين ساعة إضافة لتكاليف التأجير المتفق عليها بالعقد بما في ذلك تغطية تكاليف الحوادث وفق الأسس الواردة في العقد.',
                        desc: '',
                        descAr: '',
                        ListUl: []
                    },
                    {
                        title: 'It is not permissible to make any amendment to the contract by the tenant or landlord after signing it except with the approval and approval of both parties.',
                        titleAr: 'لا يجوز إجراء أي تعديل من قبل المستأجر أو المؤجر على العقد بعد توقيعه إلا بموافقة واعتماد كلا الطرفين.                        ',
                        desc: '',
                        descAr: '',
                        ListUl: []
                    },
                ]
            },

            {
                title: 'Second: The lessor’s obligations:',
                titleAr: 'ثانياً: التزامات  المؤجر :  ',
                desc: 'The lessor acknowledges and undertakes to abide by the following:',
                descAr: 'يقر ويتعهد المؤجر بالالتزام بالآتي:',
                list: [
                    {
                        title: 'The suitability and safety of the car for use.',
                        titleAr: 'صلاحية وسلامة السيارة للاستعمال',
                        desc: '  ',
                        descAr: '  ',
                        ListUl: []
                    },
                    {
                        title: 'If any technical defect appears in the car that is not due to negligence or negligence on the part of the renter, it will be replaced with a car of the same category, and if it is not available, it will be replaced with a car of a lower category according to the announced tariff.',
                        titleAr: 'في حالة ظهور أي خلل فني في السيارة ليس بسبب تقصير أو إهمال من قبل المستأجر فيتم استبدالها بسيارة من ذات الفئة وفي حالة عدم توفرها يتم استبدالها بسيارة من فئة اقل وفقا للتعرفة المعلنة.',
                        desc: '  ',
                        descAr: '  ',
                        ListUl: []
                    },
                    {
                        title: 'Receiving the car involved in an accident immediately after reporting it, considering the date and time of reporting the accident as the end of the rental contract.',
                        titleAr: 'استلام السيارة التي يقع عليها حادث فور الإبلاغ به مع اعتبار تاريخ ووقت الإبلاغ بالحادث هو نهاية عقد التأجير.',
                        desc: '  ',
                        descAr: '  ',
                        ListUl: []
                    },
                    {
                        title: ' A copy of a valid driver’s license for the citizen is sufficient, as it includes all the data and a copy of the passport or residence permit and the driver’s license for foreigners.',
                        titleAr: 'الاكتفاء بصورة رخصة القيادة سارية المفعول للمواطن لاشتمالها على كافة البيانات وصورة الجواز أو الإقامة ورخصة القيادة بالنسبة للأجانب.',
                        desc: '  ',
                        descAr: '  ',
                        ListUl: []
                    },
                    {
                        title: 'Bear full responsibility for not verifying the following: ',
                        titleAr: 'تحمل كامل المسئولية المترتبة على عدم التحقق من الآتي :',
                        desc: '  ',
                        descAr: '  ',
                        ListUl: [
                            {
                                title: ' ',
                                titleAr: '  ',
                                desc: '  ',
                                descAr: '  ',
                                listOl: [
                                    {
                                        title: 'The identity of the renter and the person he authorizes to drive the car and obtain a copy from them.',
                                        titleAr: 'هوية المستأجر ومن يفوضهم بقيادة السيارة والحصول على نسخة منهم.'
                                    },
                                    {
                                        title: ' A valid driving license for the driver, whether he is the renter or someone authorized by him under the contract to drive the rented car and its passengers',
                                        titleAr: 'رخصة قيادة سارية للسائق سواء كان هو المستأجر أو من يفوضهم بموجب العقد بقيادة السيارة المستأجرة وركابها.'
                                    },
                                    {
                                        title: 'Not refraining, for any reason, from receiving the car from the renter upon returning it.',
                                        titleAr: 'عدم الامتناع ولأي سبب من الأسباب عن استلام السيارة من المستأجر عند إعادتها.',
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },

            {
                title: 'Third: The tenant’s obligations: ',
                titleAr: 'ثالثاً: التزامات  المستأجر :  ',
                desc: 'The tenant acknowledges and pledges to abide by the following:',
                descAr: ' يقر وبتعهد المستأجر بالالتزام بالآتي:',
                list: [
                    {
                        ListUl: [
                            {
                                title: 'The car: ',
                                titleAr: 'السيارة :',
                                desc: '  ',
                                descAr: '  ',
                                listOl: [
                                    {
                                        title: 'The complete car and its components, including tires, engine, tools, equipment and documents, belong to the lessor.',
                                        titleAr: 'أن السيارة كاملة ومشتملاتها من إطارات ومحرك وأدوات وتجهيزات ووثائق تعود للمؤجر.'
                                    },
                                    {
                                        title: 'The car was rented to him to be used for his personal transportation.',
                                        titleAr: 'ان السيارة قد تم تأجيرها له لاستخدامها لتنقلاته الشخصية.'
                                    },
                                    {
                                        title: 'He has ensured that the vehicle is suitable for his use.',
                                        titleAr: 'أنه قد تأكد من ملائمة السيارة لاستخدامه.'
                                    },
                                    {
                                        title: 'He does not have the right to make any modifications to the car and its equipment, including tampering with the odometer or removing a sticker identifying the lessor',
                                        titleAr: 'أن ليس له الحق في إجراء أي تعديلات على السيارة وتجهيزاتها بما في ذلك العبث بعداد احتساب المسافات او نزع ملصق تعريف بالمؤجر.'
                                    },
                                    {
                                        title: ' To use the fuel and oil specified by the lessor in the contract while operating the vehicle.',
                                        titleAr: 'أن يستخدم الوقود والزيت التي يحددها المؤجر في العقد أثناء تشغيل السيارة.'
                                    },
                                ]
                            },
                            {
                                title: ' Returning the car: ',
                                titleAr: 'إعادة السيارة :',
                                desc: 'Returning the car to the location, date and time specified in the contract and any extension thereof, and for it to be in the same operational condition in which he received it, taking into account normal wear and tear throughout the period of its stay with him.',
                                descAr: ' إعادة السيارة في الموقع والتاريخ والوقت المحدد في العقد وأي تمديد له وأن تكون في نفس الحالة التشغيلية التي استلمها بها مع الاخذ في الاعتبار الاستهلاك الطبيعي طيلة فترة بقائها لديه.',
                                listOl: []
                            },
                            {
                                title: 'Reclaiming the car: ',
                                titleAr: 'استعادة السيارة:  ',
                                desc: 'The lessor has the right to recover the car through the competent authority and at the expense of the lessee and without any prior notice from the lessor in the following cases: ',
                                descAr: 'إن المؤجر الحق في استعادة السيارة وعن طريق السلطة المختصة وعلى حساب المستأجر وبدون أي اشعار مسبق من المؤجر وذلك في الحالتين الآتية:',
                                listOl: [
                                    {
                                        title: 'Failure to return the car on the specified date and time.',
                                        titleAr: 'عدم إعادة السيارة في التاريخ والوقت المحددين.'
                                    },
                                    {
                                        title: 'Using the car in violation of the terms of the contract.',
                                        titleAr: 'موظفو الجهات المختصة في حالات الطوارئ.'
                                    },
                                    {
                                        title: 'If it becomes clear that the tenant provided incorrect information at the time of rental.',
                                        titleAr: 'الشخص أو الأشخاص المفوضين من قبل المستأجر ويحملون رخص قيادة تؤهلهم لقيادة السيارة ودونت بياناتهم في العقد.'
                                    },
                                ]
                            },
                            {
                                title: 'Drivers: ',
                                titleAr: 'السائقون:  ',
                                desc: 'Those authorized to drive the car are:',
                                descAr: 'إن المفوضين بقيادة السيارة هم :',
                                listOl: [
                                    {
                                        title: 'f the tenant holds a driving license that qualifies him to drive a car. ',
                                        titleAr: ' المستأجر إذا كان يحمل رخصة قيادة تؤهله لقيادة السيارة .                        '
                                    },
                                    {
                                        title: 'Employees of the competent authorities in emergency situations.',
                                        titleAr: 'موظفو الجهات المختصة في حالات الطوارئ. '
                                    },
                                    {
                                        title: 'The person or persons authorized by the lessee and holding driving licenses that qualify them to drive the car and whose data is recorded in the contract',
                                        titleAr: 'الشخص أو الأشخاص المفوضين من قبل المستأجر ويحملون رخص قيادة تؤهلهم لقيادة السيارة ودونت بياناتهم في العقد.'
                                    },
                                ]
                            },
                            {
                                title: 'Conditions of use: ',
                                titleAr: '  شروط الاستخدام:  ',
                                desc: ' The car will not be used in the following cases: ',
                                descAr: 'ان السيارة لن تستخدم في الحالات الاتية :',
                                listOl: [
                                    {
                                        title: 'By persons not authorized under the contract to drive the car.',
                                        titleAr: 'من قبل اشخاص غير مفوضين بموجب العقد بقيادة السيارة.'
                                    },
                                    {
                                        title: 'If the car needs repair.',
                                        titleAr: ' إذا كانت السيارة تحتاج إلى اصلاح .'
                                    },
                                    {
                                        title: ' If operation leads to damage to the car’s engine or one of its components. ',
                                        titleAr: 'إذا كان التشغيل يؤدي إلى أضرار بمحرك السيارة أو أحد عناصرها.'
                                    },
                                    {
                                        title: 'If the driver authorized under the contract is unable to drive the car, such as if he is under the influence of intoxicating or narcotic substances or suffers from a health condition that prevents him from driving properly.',
                                        titleAr: 'إذا كان السائق المفوض بموجب العقد غير قادر على قيادة السيارة كأن يكون تحت تأثير مواد مسكرة أو مخدرة أو يعاني من سبب صحي يعيفه عن القيادة السليمة.'
                                    },
                                    {
                                        title: 'Participation in car racing. ',
                                        titleAr: 'الاشتراك في سباقات السيارات.'
                                    },
                                    {
                                        title: 'Pushing or towing other cars. ',
                                        titleAr: ' دفع أو سحب سيارات أخرى.'
                                    },
                                    {
                                        title: ' In a manner caused by negligence.',
                                        titleAr: 'بشكل يتم عن الإهمال.'
                                    },
                                    {
                                        title: 'Towing a trailer without the lessor’s consent.',
                                        titleAr: 'سحب مقطورة دون موافقة المؤجر .'
                                    },
                                    {
                                        title: 'Leadership training. ',
                                        titleAr: 'التدريب على القيادة .                         '
                                    },
                                    {
                                        title: 'Re-leasing. ',
                                        titleAr: 'إعادة التأجير .                '
                                    },
                                    {
                                        title: 'For purposes inconsistent with the laws of the Kingdom of Saudi Arabia.',
                                        titleAr: 'في أغراض تتنافي مع أنظمة المملكة العربية السعودية.        '
                                    },
                                    {
                                        title: 'Outside the Kingdom of Saudi Arabia unless signed in the contract.',
                                        titleAr: 'خارج المملكة العربية السعودية مالم يكن موقع عليه في العقد'
                                    },

                                ]
                            },
                            {
                                title: 'Leaving the car: ',
                                titleAr: '  ترك  السيارة :  ',
                                desc: 'Do not leave the car with the ignition key on.',
                                descAr: '  عدم ترك السيارة ومفتاح التشغيل عليها.',
                                listOl: []
                            },

                            {
                                title: 'Insurance:',
                                titleAr: 'التأمين :  ',
                                desc: '  ',
                                descAr: '',
                                listOl: [
                                    {
                                        title: 'Bearing full responsibility for accidents towards third parties, in addition to the rented car, its driver and passengers.',
                                        titleAr: 'تحمل كامل المسؤولية المترتبة على الحوادث تجاه الغير بالإضافة إلى السيارة المستأجرة وسائقها وركابها.'
                                    },
                                    {
                                        title: ' It is known to him that insurance does not cover accidents except in the following cases, and therefore he bears the full responsibility resulting from them. ',
                                        titleAr: 'انه من المعروف لديه ان التأمين لا يغطي الحوادث الا في الحالات الآتية وعليه فانه يتحمل كامل المسؤولية المترتبة عليها.',
                                        listOl3th: [
                                            {
                                                title: 'If the driver is under the influence of intoxicating or narcotic substances. ',
                                                titleAr: 'إذا كان السائق تحت تأثير مواد مسكرة او مخدرة.',
                                            },
                                            {
                                                title: 'If the driver is not authorized under the contract to drive the car ',
                                                titleAr: 'إذا كان السائق غير مخول بموجب العقد بقيادة السيارة'
                                            },
                                            {
                                                title: 'The accident occurred after the end of the contract period and any extension thereof. ',
                                                titleAr: 'وقوع الحادث بعد انتهاء مدة العقد واي تمديد له. '

                                            },
                                            {
                                                title: 'Using the car for a purpose other than the one for which it was rented. ',
                                                titleAr: 'استخدام السيارة لغير الغرض المؤجر له. '

                                            },
                                            {
                                                title: 'If the accident occurred outside the Kingdom of Saudi Arabia and it was not agreed in the contract to use the car outside the Kingdom. ',
                                                titleAr: 'اذا وقع الحادث خارج المملكة العربية السعودية ولم يكن متفق في العقد على استخدام السيارة خارج المملكة.'

                                            },
                                        ]
                                    },

                                ]
                            },

                            {
                                title: 'Reporting accidents and impounding the car: ',
                                titleAr: ' الابلاغ عن الحوادث وحجز السيارة :  ',
                                desc: '  ',
                                descAr: '',
                                listOl: [
                                    {
                                        title: 'Bearing full responsibility for accidents towards third parties, in addition to the rented car, its driver and passengers.',
                                        titleAr: 'تحمل كامل المسؤولية المترتبة على الحوادث تجاه الغير بالإضافة إلى السيارة المستأجرة وسائقها وركابها.'
                                    },

                                    {
                                        title: 'It is known to him that insurance does not cover accidents except in the following cases, and therefore he bears full responsibility for them.',
                                        titleAr: 'انه من المعروف لديه ان التأمين لا يغطي الحوادث الا في الحالات الآتية وعليه فانه يتحمل كامل المسؤولية المترتبة عليها.',
                                        listOl3th: [
                                            {
                                                title: 'Notify the lessor and the security authorities as soon as the car is involved in an accident or it is discovered that it has been stolen, while providing them with all the information they request. ',
                                                titleAr: 'اشعار المؤجر والجهات الامنية فور تعرض السيارة لحادث او اكتشاف سرقتها مع تزويدهم بكافة المعلومات التي يطلبونها.',
                                            },
                                            {
                                                title: 'Notify the lessor immediately after the vehicle is seized by the competent authorities for any reason.',
                                                titleAr: 'إشعار المؤجر فور حجز السيارة من قبل الجهات المختصة لأي سبب من الأسباب.',
                                            },
                                        ]
                                    },



                                ]
                            },

                            {
                                title: 'The rights of the lessor: ',
                                titleAr: '  حقوق المؤجر:  ',
                                desc: '  ',
                                descAr: '',
                                listOl: [
                                    {
                                        title: 'Not to waive the rights of the lessor towards the other party. ',
                                        titleAr: ' عدم التنازل عن حقوق المؤجر تجاه الطرف الآخر. '
                                    },
                                    {
                                        title: 'Failure to represent the lessor before the competent authorities.',
                                        titleAr: 'عدم تمثيل المؤجر لدى الجهات المختصة.'
                                    },
                                ]
                            },
                            {
                                title: 'Sudden car malfunctions: ',
                                titleAr: 'الاعطال المفاجئة بالسيارة : ',
                                desc: 'Return the car to the lessor if it needs repair as a result of a sudden breakdown, or notify him if it is unfit to drive so that the lessor can restore it. In both cases, he agrees not to make any repairs to it except with the lessor’s approval.',
                                descAr: 'إعادة السيارة للمؤجر اذا كانت تحتاج الى اصلاح نتيجة عطل مفاجئ او اشعاره اذا كانت غير صالحة للسير ليتولى المؤجر استعادتها وفي كلتا الحالتين يوافق على عدم اجراء أي اصلاحات عليها الا بموافقة المؤجر.',
                                listOl: []
                            },
                            {
                                title: 'Violation of regulations and instructions: ',
                                titleAr: 'مخالفة الانظمة والتعليمات تحمل المسؤولية الكاملة المترتبة على الآتي: ',
                                desc: '  ',
                                descAr: '   ',
                                listOl: [
                                    {
                                        title: ' Traffic violations caused by the driver. ',
                                        titleAr: 'المخالفات المرورية المتسبب بها السائق '
                                    },
                                    {
                                        title: 'Any other violations resulting from misuse of the vehicle or its use for illegal purposes.',
                                        titleAr: 'أي مخالفات اخرى ناجمة عن اساءة استخدام السيارة أو استخدامها لأغراض غير مشروعة.'
                                    },
                                ]
                            },
                            {
                                title: 'The renter’s personal belongings: ',
                                titleAr: 'اغراض المستأجر الشخصية :',
                                desc: 'He bears full responsibility for leaving any belongings of his own inside the car.',
                                descAr: ' تحمل كامل المسؤولية المترتبة على ترك أي اغراض تخصه داخل السيارة.',
                                listOl: []
                            },
                            {
                                title: 'Rental costs: ',
                                titleAr: 'تكاليف الاستئجار : ',
                                desc: 'bear the following costs: ',
                                descAr: 'تحمل التكاليف الآتية:',
                                listOl: [
                                    {
                                        title: 'The costs of renting the car for the entire rental period and any extension thereof according to what is stated in the contract.',
                                        titleAr: 'تكاليف استئجار السيارة طيلة فترة الاستنجار واي تمديد لها حسب ما ورد في نصوص العقد.'
                                    },
                                    {
                                        title: ' Insurance costs agreed upon in the contract. ',
                                        titleAr: 'تكاليف التأمين المتفق عليها في العقد.'
                                    },
                                    {
                                        title: 'The value of fuel, oil, water, and tire air filling during the rental period',
                                        titleAr: ' لقيمة الوقود والزيت والماء وتعبئة هواء الاطارات خلال فترة الاستئجار. '
                                    },
                                    {
                                        title: 'Costs resulting from misuse of the car. ',
                                        titleAr: ' التكاليف المترتبة على سوء استخدام السيارة. '
                                    },
                                    {
                                        title: 'Costs of traffic violations caused by the driver. ',
                                        titleAr: 'تكاليف المخالفات المرورية المتسبب بها السائق . '
                                    },
                                    {
                                        title: 'Costs resulting from violating the regulations. ',
                                        titleAr: 'التكاليف المترتبة على مخالفة الأنظمة . '
                                    },
                                    {
                                        title: 'The fee for used public parking.',
                                        titleAr: 'اجرة المواقف العامة المستخدمة . '
                                    },
                                    {
                                        title: 'The fuel costs in the car when it is rented if it is used.',
                                        titleAr: ' تكاليف الوقود الموجودة في السيارة عند استئجارها في حالة استخدامه .  '
                                    },
                                    {
                                        title: 'Costs of damages not covered by the insurance agreed upon under the contract.',
                                        titleAr: 'تكاليف الاضرار التي لا يغطيها التأمين المتفق عليه بموجب العقد. '
                                    },
                                    {
                                        title: 'The costs resulting from assigning the lessor’s rights to others.',
                                        titleAr: 'التكاليف المترتبة على التنازل عن حقوق المؤجر لدى الغير.'
                                    },
                                    {
                                        title: ' The costs of repairing any modifications to the car that the lessee may have made.',
                                        titleAr: 'تكاليف إصلاح أي تعديلات على السيارة يكون المستأجر قد اجراها.'
                                    },
                                    {
                                        title: 'The costs resulting from the loss, replacement, or tampering with any of the car’s parts or equipment.',
                                        titleAr: 'التكاليف المترتبة على فقد او استبدال أو العبث باي من قطع السيارة او تجهيزاتها.'
                                    },
                                    {
                                        title: 'The costs resulting from delaying the delivery of the vehicle on the specified date and time and under the price category specified in the contract, including covering the costs of accidents according to the principles stated in the contract.',
                                        titleAr: 'التكاليف المترتبة على تأخير تسليم السيارة في التاريخ والوقت المحددين وبموجب فئة السعر المحدد في العقد بما في ذلك تغطية تكاليف الحوادث وفق الاسس الواردة في العقد.'
                                    },
                                ]
                            },

                        ]
                    },
                ]
            },


        ];
    },
    fetchPrivacyPolicy() {
        return Promise.resolve(this.getPrivacyPolicy());
    },

    fetchTermsAll() {
        return Promise.resolve(this.getTermsPopup());
    },
};  