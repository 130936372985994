import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import App from './App';

// Import css files bootstrap 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Import css files slick
import "react-multi-carousel/lib/styles.css";

 
//primereact
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
 
import './style/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

