import React, { useContext } from 'react'
// Validate From Fields
import * as Yup from 'yup';
import { LocalizationContext } from '../../../context/LangChange';
const useValidation = () => {
    let { isLang } = useContext(LocalizationContext);
    const errorMessages = {
        en: {
            firstName: 'First name is required',
            lastName: 'Last name is required',
            fulName: 'Full name is required',
            mobile: "Mobile number / Email is required",
            mobile2: 'It must be 10 num starting with 05',
            nationality: 'Nationality is required',
            nationalId: '  National ID is required',
            nationalId_nationality: 'National Id  invalid and must be 10 digits num',
            gender: 'Gender is required',
            cityId: 'City   is required',
            address: 'Address is required',
            password: 'Password is required and must be at least 8 characters long',
            confirmPassword: 'Password and confirmation must match',
            acceptTerms: 'You must accept the terms and privacy policy',
            message: 'Message is a required    ',
            dateLimozin: 'Date is a required    ',
            timeLimozin: 'Time is a required    ',
            daysLimozin: 'Days is a required    ',
            fromLimozin: 'Pickup location required',
            toLimozin: 'Delivery location required   ',

        },
        ar: {
            firstName: 'الأسم الأول مطلوب',
            lastName: 'الأسم الأخير مطلوب',
            fulName: 'الأسم مطلوب',
            message: '   نص الرساله مطلوب',
            mobile: "رقم الجوال / بريد الإلكتروني  مطلوب",
            mobile2: ' يجب أن يكون من 10 أرقام  يبدا ب05  ',
            email: 'عنوان البريد الإلكتروني غير صحيح',
            nationality: 'الجنسية مطلوبة',
            nationalId: ' رقم الهوية مطلوب ',
            nationalId_nationality: '  رقم الهوية غير صالح و يجب ان يتكون من 10 ارقام',
            gender: 'الجنس مطلوب',
            cityId: '  المدينة مطلوبة',
            address: 'العنوان مطلوب',
            password: 'كلمة المرور مطلوبة ويجب أن تحتوي على الأقل 8 أحرف',
            confirmPassword: 'يجب أن تتطابق كلمة المرور وتأكيد كلمة المرور',
            acceptTerms: 'يجب عليك قبول الشروط وسياسة الخصوصية',
            dateLimozin: 'التاريخ مطلوب    ',
            timeLimozin: 'الوقت مطلوب  ',
            daysLimozin: '   عدد  الايام مطلوب  ',
            fromLimozin: 'مكان الاستلام مطلوب',
            toLimozin: 'مكان التوصيل مطلوب  ',
        },
    };
    const validateLoginForm = () => {
        let validation = Yup.object({
            mobile: Yup.string().required(errorMessages[isLang]?.mobile),
            password: Yup.string()
                .required(isLang === "en" ? 'Password is required' : "كلمة المرور مطلوبة")
        })
        return validation
    }

    const validateRestpasswordForm = () => {
        let validation = Yup.object({
            mobile: Yup.string().required(errorMessages[isLang]?.mobile),
        })
        return validation
    }

    const validateRestpasswordNewForm = () => {
        let validation = Yup.object({
            password: Yup.string()
                .required(isLang === "en" ? 'Password is required' : "كلمة المرور مطلوبة")
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9]).{8,25}$/,
                    isLang === "en"
                        ? 'The password must contain an uppercase letter, a lowercase letter, a symbol, a number, and be between 8 and 25'
                        : "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورمز ورقم ويتكون بين 8 و 25  "
                ),
            confirmPassword: Yup.string().required(errorMessages[isLang]?.confirmPassword).oneOf([Yup.ref('password'), null], errorMessages[isLang]?.confirmPassword),
        })
        return validation
    }

    const validateRegisterForm = () => {
        let validation = Yup.object({
            firstName: Yup.string().required(errorMessages[isLang]?.firstName).max(10).min(1),
            lastName: Yup.string().required(errorMessages[isLang]?.lastName).max(10).min(1),
            // mobile: Yup.string().required(errorMessages[isLang]?.mobile).matches(/^(55|56|58|59)\d{7}$/, errorMessages[isLang]?.mobile2),
            mobile: Yup.string().test(
                'mobile',
                errorMessages[isLang]?.mobile,
                function(value) {
                    const email = this.parent.email;
                    // التحقق من أن الهاتف مطلوب إذا كان البريد الإلكتروني فارغًا
                    return email || value ? true : false;
                }
            ),
            email: Yup.string().email(errorMessages[isLang]?.emailInvalid).test(
                'email',
                errorMessages[isLang]?.email,
                function(value) {
                    const mobile = this.parent.mobile;
                    // التحقق من أن البريد الإلكتروني مطلوب إذا كان الهاتف فارغًا
                    return mobile || value ? true : false;
                }
            ),
            // nationality: Yup.string().required(errorMessages[isLang]?.nationality),
            // nationalId: Yup.string().required(errorMessages[isLang]?.nationalId)
            //     .test('is-saudi-national-id', errorMessages[isLang]?.nationalId_nationality, (value, context) => {
            //         if (context.parent.nationality === (isLang === 'en' ? 'Saudi' : 'سعودي')) {
            //             return /^[1][0-9]{9}$/.test(value);
            //         }
            //         else {
            //             return true
            //         }
            //     })
            //     .required(errorMessages[isLang]?.nationalId),

   /*          nationalId: Yup.string()
            .test('is-saudi-national-id', errorMessages[isLang]?.nationalId_nationality, (value, context) => {
                if (context.parent.nationality === (isLang === 'en' ? 'Saudi' : 'سعودي')) {
                    return /^[1][0-9]{9}$/.test(value);
                }
                if (context.parent.nationality !== (isLang === 'en' ? 'Saudi' : 'سعودي')) {
                    return /^[2][0-9]{9}$/.test(value);
                }
            })
            .required(errorMessages[isLang]?.nationalId), */
            // gender: Yup.string().required(errorMessages[isLang]?.gender),
            cityId: Yup.string().required(errorMessages[isLang]?.cityId),
            // address: Yup.string().required(errorMessages[isLang]?.address),
            password: Yup.string()
                .required(isLang === "en" ? 'Password is required' : "كلمة المرور مطلوبة")
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9]).{8,25}$/,
                    isLang === "en"
                        ? 'The password must contain an uppercase letter, a lowercase letter, a symbol, a number, and be between 8 and 25'
                        : "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورمز ورقم ويتكون بين 8 و 25  "
                ),
            confirmPassword: Yup.string().required(errorMessages[isLang]?.confirmPassword).oneOf([Yup.ref('password'), null], errorMessages[isLang]?.confirmPassword),
            acceptTerms: Yup.boolean().oneOf([true], errorMessages[isLang]?.acceptTerms),


        })
        return validation
    }
    const validateUpdateProfile = () => {
        let validation = Yup.object({
            firstName: Yup.string().required(errorMessages[isLang]?.firstName).max(10).min(1),
            lastName: Yup.string().required(errorMessages[isLang]?.lastName).max(10).min(1),
            // mobile: Yup.string().required(errorMessages[isLang]?.mobile).matches(/^(55|56|58|59)\d{7}$/, errorMessages[isLang]?.mobile2),
            mobile: Yup.string().required(errorMessages[isLang]?.mobile),
            email: Yup.string().required(errorMessages[isLang]?.email).email(errorMessages[isLang]?.email),
            nationality: Yup.string().required(errorMessages[isLang]?.nationality),
            gender: Yup.string().required(errorMessages[isLang]?.gender),
            cityId: Yup.string().required(errorMessages[isLang]?.cityId),
            address: Yup.string().required(errorMessages[isLang]?.address),
        })
        return validation
    }

    const validateChangePassword = () => {
        let validation = Yup.object({
            old: Yup.string().required(isLang === "en" ? 'Old password required' : 'كلمة المرور القديمة مطلوبة'),
            password: Yup.string()
                .required(isLang === "en" ? 'Password is required' : "كلمة المرور مطلوبة")
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[0-9]).{8,25}$/,
                    isLang === "en"
                        ? 'The password must contain an uppercase letter, a lowercase letter, a symbol, a number, and be between 8 and 25'
                        : "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورمز ورقم ويتكون بين 8 و 25  "
                ), confirmPassword: Yup.string().required(isLang === "en" ? 'Password confirmation required' : 'تأكيد كلمة المرور مطلوب').oneOf([Yup.ref('password'), null], isLang === "en" ? 'Password and Confirm Password must match' : 'كلمة المرور وتأكيد كلمة المرور يجب أن تتطابق'),
        })
        return validation
    }

    const validateSendMessage = () => {
        let validation = Yup.object({
            name: Yup.string().required(errorMessages[isLang]?.fulName),
            mobile: Yup.string().required(errorMessages[isLang]?.mobile),
            email: Yup.string()
                .required(isLang === "en" ? 'Email is required' : 'البريد الإلكتروني مطلوب')
                .email(isLang === "en" ? 'Invalid e-mail format' : 'صيغة البريد الإلكتروني غير صحيحة'),
            message: Yup.string().required(errorMessages[isLang]?.message).max(200),
        })
        return validation
    }

    const validateLongTermRent = () => {
        const validation = Yup.object().shape({
            number_cars: Yup.number().typeError(isLang === "en" ? 'Car numbers must be a number' : 'عدد السيارات يجب أن يكون رقمًا').required(isLang === "en" ? 'car numbers is required' : 'عدد السيارات مطلوب'),
            months: Yup.number()
                .typeError(isLang === "en" ? 'Rental duration must be a number' : 'مدة الايجار يجب أن تكون رقمًا')
                .required(isLang === "en" ? 'Rental duration is required' : 'مدة الايجار مطلوبة'),
            // Add validation for other fields here
            car_brand: Yup.string()
                .required(isLang === "en" ? 'Car brand is required' : 'ماركه السيارات مطلوبه'),
            company_name: Yup.string()
                .required(isLang === "en" ? 'Company name is required' : 'اسم الشركة مطلوب'),
            city: Yup.string()
                .required(isLang === "en" ? 'City is required' : 'المدينة مطلوبة'),
            // address: Yup.string()
            //     .required(isLang === "en" ? 'Address is required' : 'العنوان مطلوب'),
            activity: Yup.string()
                .required(isLang === "en" ? 'Activity is required' : 'النشاط مطلوب'),
            /*           reg_number: Yup.string()
                          .required(isLang === "en" ? 'Commercial Record is required' : 'سجل تجارى مطلوب'), */
            person: Yup.string()
                .required(isLang === "en" ? 'Name of responsible person is required' : 'اسم الشخص المسؤول مطلوب'),
            mobile: Yup.string().required(errorMessages[isLang]?.mobile),
            // mobile: Yup.string().required(errorMessages[isLang]?.mobile).matches(/^05\d{8}$/, errorMessages[isLang]?.mobile2),

            email: Yup.string()
                .required(isLang === "en" ? 'E-mail is required' : 'البريد الإلكتروني مطلوب')
                .email(isLang === "en" ? 'Invalid e-mail format' : 'صيغة البريد الإلكتروني غير صحيحة'),
        });
        return validation
    }

    const validateDate = () => {
        let validation = Yup.object({
            start: Yup.date()
                .nullable()
                .required(isLang === "en" ? 'Booking start date required' : 'تاريخ بداية الحجز مطلوب'),
            end: Yup.date()
                .nullable()
                .required(isLang === "en" ? 'Booking end date required' : 'تاريخ نهاية الحجز مطلوب')
                .when('start', (startDate, schema) => {
                    return startDate && schema.min(startDate, isLang === "en" ? 'End date must be after start date' : 'تاريخ النهاية يجب أن يكون بعد تاريخ البداية');
                }),
        });
        return validation;
    };

    const validateLimozinRequest = () => {
        let validation = Yup.object({
            person: Yup.string().required(errorMessages[isLang]?.firstName),
            mobile: Yup.string().required(errorMessages[isLang]?.mobile),
            from: Yup.string().required(errorMessages[isLang]?.fromLimozin),
            to: Yup.string().required(errorMessages[isLang]?.toLimozin),
            date: Yup.string().required(errorMessages[isLang]?.dateLimozin),
            time: Yup.string().required(errorMessages[isLang]?.timeLimozin),
        })
        return validation
    }

    const validateLimozinRequest8H = () => {
        let validation = Yup.object({ 
            date: Yup.string().required(errorMessages[isLang]?.dateLimozin),
            time: Yup.string().required(errorMessages[isLang]?.timeLimozin),
            days: Yup.string().required(errorMessages[isLang]?.daysLimozin),
        })
        return validation
    }

    const validationDate = () => {
        let validation = Yup.object({
            start: Yup.date().nullable(),
            end: Yup.date().nullable().min(Yup.ref('start'),isLang === "en" ? 'End date must be after start date' : 'تاريخ النهاية يجب أن يكون بعد تاريخ البداية'),
        })
        return validation
    }
    


    return {
        validationDate,
        validateUpdateProfile,
        validateLimozinRequest8H,
        validateLimozinRequest,
        validateDate,
        validateSendMessage,
        validateLoginForm,
        validateRegisterForm,
        validateChangePassword,
        validateLongTermRent,
        validateRestpasswordForm,
        validateRestpasswordNewForm
    }
}

export default useValidation
