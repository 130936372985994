import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from "primereact/radiobutton";
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import img from '../../../constants/Img';
import { LocalizationContext } from '../../../context/LangChange';
import './Points.scss';

const Wallet = (props) => {
    const [ingredient, setIngredient] = useState('');
    const [checked, setChecked] = useState(false);
    let {    isLang } = useContext(LocalizationContext);

    return (
        <div className="app__wallet">
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered

            >

                <Modal.Body>
                    <Row  dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Col xl={6} lg={6} md={6} sm={12}  >
                            <div className="app__payment_getway flex h-100 flex-column justify-content-center">
                                <div className="header w-100">
                                    <h3>إكمال الدفع</h3>
                                    <hr />
                                </div>
                                <div className="app__payment_getway-form">
                                    <span>ادفع من خلال</span>
                                    <div className="flex flex-wrap gap-3 mt-3">
                                        <div className="flex align-items-center gap-2">
                                            <RadioButton inputId="ingredient1" name="pizza" value="Cheese" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'Cheese'} />
                                            <label htmlFor="ingredient1" className="ml-2">مدي</label>
                                        </div>
                                        <div className="flex align-items-center gap-2">
                                            <RadioButton inputId="ingredient2" name="pizza" value="Mushroom" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'Mushroom'} />
                                            <label htmlFor="ingredient2" className="ml-2">بطاقة إئتمان</label>
                                        </div>
                                    </div>

                                    <Row className='mt-5'>
                                        <Col xl={6} lg={4} md={6} sm={12} >
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username">       رقم البطاقة   </label>
                                                <InputText id="username" placeholder='1234  5678  9101  1121' aria-describedby="username-help" className='bg-light' />
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={4} md={6} sm={12} >
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username"> المبلغ</label>
                                                <InputText placeholder='2000 SR' id="username" aria-describedby="username-help" className='bg-light' />
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={4} md={6} sm={12} >
                                            <div className="flex flex-column gap-2 mt-3">
                                                <label htmlFor="username"> CVV     </label>
                                                <InputText placeholder='123' id="username" aria-describedby="username-help" className='bg-light' />
                                            </div>
                                        </Col>
                                        <Col xl={6} lg={4} md={6} sm={12} >
                                            <div className="flex flex-column gap-2 mt-3">
                                                <label htmlFor="username">تاريخ انتهاء الصلاحية   </label>
                                                <InputText id="username" aria-describedby="username-help" className='bg-light' />
                                            </div>
                                        </Col>
                                        <Col xl={12} lg={4} md={6} sm={12} className='mt-4'>

                                            <div className="flex  gap-2 align-items-center mb-2">
                                                <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={e => setChecked(e.checked)} checked={checked} />
                                                <label htmlFor="ingredient1" className="ml-2 info_after_payment text_box">    حفظ تفاصيل البطاقة </label>
                                            </div>
                                        </Col>
                                        <Col xl={12} lg={4} md={6} sm={12} >
                                            <div className="flex flex-column gap-2 mt-4 submit">
                                                <Button severity='warning' label='  إدفع الان ' className=' w-full' />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <span className='mt-5 info_after_payment'>سيتم استخدام بياناتك الشخصية لمعالجة طلبك ودعم تجربتك في جميع أنحاء هذا الموقع ولأغراض أخرى موصوفة في سياسة الخصوصية الخاصة بنا.</span>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12}  >
                            <img className='app__wallet w-100' src={img.Bg_Overlay} alt="images"  loading='lazy'/>
                        </Col>
                    </Row>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default Wallet