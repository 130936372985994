import React, { useContext, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import src from '../../../assets/payment loader.json'
import success from '../../../assets/success payment.json'
import faild from '../../../assets/faild payment.json'
import { useEffect } from 'react';
import axios from 'axios';
import { LocalizationContext } from '../../../context/LangChange';
import { useNavigate } from 'react-router-dom';

const BankView = () => {
    let { isLang } = useContext(LocalizationContext)
    const [data, setData] = useState(null)
    const [laoding, setLoading] = useState(true)
    const navigator = useNavigate();

    async function fetchDataFromBank() {
        setLoading(true)
        let { data } = await axios.get(`https://backend.betterway-egypt.com/api/admin/test3/${localStorage.getItem('res_number')}`)
        setData(data);
        setLoading(false)

        // if (data == 'NOT_FOUND' || data == 'Card security code (CVV) mismatch' || data == 'Invalid card number') {
        //     setTimeout(function () {
        //         navigator(localStorage.getItem('res_path'))
        //     }, 3000);
        // }

        if (data == 'Authorised') {
            setTimeout(function () {
                checkReservation()
            }, 3000);
        } else {
            setTimeout(function () {
                navigator(localStorage.getItem('res_path'))
            }, 3000);
        }

    }
    const checkReservation = async () => {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/finishreservations?resId=${localStorage.getItem('res_number')}`)
        if (data?.status === true) {
            localStorage.removeItem('res_number')
            localStorage.setItem('res_number', data?.res_id)
            navigator(`/${isLang}/thanks-page/order/${localStorage.getItem('res_number')}?lim=${data?.lim == true ? 1 : 0}`)
        }
    }
    useEffect(() => {
        setTimeout(function () {
            fetchDataFromBank()
        }, 3000);
    }, [])

    return (
        <>
            <div className="app__error payment_app flex flex-column vh-100">
                {
                    laoding ?
                        <>
                            <Player
                                className='w-25'
                                src={src}
                                autoplay
                                loop
                            />
                            <span>{isLang === "en" ? `Waiting for the bank's response.....` : '.....بإنتظار رد البنك'}</span>



                        </>
                        :
                        <>
                            {
                                data == 'Authorised' ?
                                    <>
                                        <Player
                                            className='w-25'
                                            // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                                            src={success}
                                            autoplay
                                            loop
                                        />
                                        <span>{isLang === "en" ? 'Payment successful' : 'تم الدفع بنجاح'}</span>
                                        <span className='font-normal'>{data}</span>
                                    </>
                                    :
                                    <>
                                        <Player
                                            className='w-25'
                                            // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                                            src={faild}
                                            autoplay
                                            loop
                                        />
                                        <span>{isLang === "en" ? 'Payment failed' : 'فشل في عملية الدفع'}</span>
                                        <span className='font-normal'>{data}</span>

                                    </>
                            }
                        </>
                }
            </div>
        </>
    )
}

export default BankView
