import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'; 
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';

const TermsCancellation = () => {
    let { isLang } = useContext(LocalizationContext);


    const [fetchPolicy, setFetchPolicy] = useState([]);
    const fetchPolicyData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/privcypolicy`;
        let { data } = await axios.get(url)
        setFetchPolicy(data);
    }

    useEffect(() => {
        window.scroll(0,0)
        fetchPolicyData()
    }, [])

    return (
        <div className='mt-70 py-7'>
            <Container>
                <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
                    <Col xl={8} lg={8} md={12} sm={12} >
                        <div className="mb-4">
                            <Component.Title title={isLang === "en" ? 'Terms Cancellation' : 'شروط الالغاء'} />
                        </div>
                        {isLang === "en" ? fetchPolicy?.english_privacy : fetchPolicy?.arabic_privacy}


                    </Col>
                    {/* <Col xl={5} lg={5} md={5} sm={12} className='flex justify-content-center align-items-center '>
                        <div className="Player">
                            <Player
                                className='w-75'
                                // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                                src={address}
                                autoplay
                                loop
                            />
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </div>
    )
}

export default TermsCancellation
