import axios from "axios";

//   Validate From handel Login
export const updateProfileData = async (values) => {

 
        let urlRegister = `${process.env.REACT_APP_API_URL}/profile?clientId=${localStorage.getItem('client_id')}&mobile=${values.mobile}&email=${values.email}&password=${values.password}&firstName=${values?.firstName}&lastName=${values.lastName}&gender=${values.gender}&nationality=${values.nationality}&address=${values.address}&cityId=${values.cityId}&type=${values.type}&image=${values?.image?.replace('https://alghazal.sa/uploads/imagename', "")}`
        let { data } = await axios.post(urlRegister).catch((err) => {
            /*        setLoadmobile(false);  */
        })
        // setLoadmobile(true);
        if (data.status === true) {
            setTimeout(() => {
                // setLoadmobile(false);
            }, 3000);
            return { severity: 'success', summary: 'Success', detail: 'The profile has been successfully modified', updateDate: true };
        } else if (data.status === false) {
            return { severity: 'error', summary: 'Error', detail: 'client not found', updateDate: false };
        }
 
};
export const handelChangePassword = async (values) => {
    const urlChangePassword = `${process.env.REACT_APP_API_URL}/changepassword?clientId=${localStorage.getItem('client_id')}&old=${values.old}&password=${values.password}`;
    let { data } = await axios.post(urlChangePassword)
    if (data.status === true) {
        setTimeout(() => {
            // setLoadmobile(false);
        }, 3000);
        return { severity: 'success', summary: 'Success', detail: 'Password has been chanched successfully', status: true };
    } else if (data.status === false) {
        return { severity: 'error', summary: 'Error', detail: data.msg, status: false };
    }
};

export default {
    updateProfileData,
    handelChangePassword
};
