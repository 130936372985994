import { useFormik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import React, { useState } from 'react'
import { Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Component from '../../../constants/Component'
import useValidation from '../../auth/Services/useValidation'
import { handelChangePassword } from '../../services/ProfileAuth'

const ChangePasword = ({ isLang, toast }) => {
    let { validateChangePassword } = useValidation()
    const [handelOpenPassword, setHandelOpenPassword] = useState('password');
    const [handelOpenPassword1, setHandelOpenPassword1] = useState('password');
    const [handelOpenPassword2, setHandelOpenPassword2] = useState('password');
    let navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            old: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: validateChangePassword,

        onSubmit: (values) => handelChangePassword(values).then((data) => {

            const { severity, summary, detail, status } = data;
            toast.current.show({ severity, summary, detail, life: 3000 });
            try {
                if (status === true) {
                    const rememberMe = localStorage.getItem('rememberMe');
                    if (rememberMe === 'true') {
                        localStorage.removeItem('rememberMePassword');
                        localStorage.setItem('rememberMePassword', values?.password);
                    }
                }
                setTimeout(() => {
                    // setLoadmobile(false);
                    if (status === true) {

                        navigate('/account/personal_data')
                    }
                }, 1500);
            } catch (error) {

            }
        })
    });

    return (
        <form onSubmit={formik.handleSubmit} className='mt-4 w-100'>
            <Row  >
                <div className="change_password mt-60">
                    <Component.Title title={isLang === "en"?'Change Password':'تغير كلمة المرور'} />
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">

                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="old" className="font-bold block mb-2">   {isLang === "en" ? '   Old Password' : '    كلمة المرور القديمة   '} </label>
                        <div className="password_open">
                            <InputText
                                id="old"
                                name="old"
                                type={handelOpenPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                dir='ltr'
                                className='w-full bg-light p-inputtext-sm' />
                            {
                                handelOpenPassword === "password" ?
                                    <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                                    <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>

                            }
                        </div>
                        {formik.errors.old && formik.touched.old ?
                            <Message severity="error" text={formik.errors.old} /> : null
                        }
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">

                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="password" className="font-bold block mb-2">   {isLang === "en" ? '   New Password' : ' كلمة المرور الجديدة '} </label>
                        <div className="password_open">
                            <InputText
                                id="password"
                                name="password"
                                type={handelOpenPassword1} onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                dir='ltr'
                                className='bg-light p-inputtext-sm w-full' />
                            {
                                handelOpenPassword1 === "password" ?
                                    <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword1('text')}  ></i> :
                                    <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword1('password')}  ></i>

                            }
                        </div>

                        {formik.errors.password && formik.touched.password ?
                            <Message severity="error" text={formik.errors.password} /> : null
                        }
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">

                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="confirmPassword" className="font-bold block mb-2">   {isLang === "en" ? 'Confirm Password' : 'تأكيد كلمة المرور   '} </label>
                        <div className="password_open">
                            <InputText
                                id="confirmPassword"
                                name="confirmPassword"
                                type={handelOpenPassword2}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                dir='ltr'
                                className='bg-light p-inputtext-sm w-full' />
                            {
                                handelOpenPassword2 === "password" ?
                                    <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword2('text')}  ></i> :
                                    <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword2('password')}  ></i>

                            }
                        </div>

                        {formik.errors.confirmPassword && formik.touched.confirmPassword ?
                            <Message severity="error" text={formik.errors.confirmPassword} /> : null
                        }
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                    <div className="flex flex-column gap-2 mt-60 submit">
                        <Button type='submit' severity='warning' label={isLang === "ar" ? 'تغير كلمه السر' : 'Change Password  '} className=' w-full' size='small' />
                    </div>
                </div>
            </Row>
        </form>


    )
}

export default ChangePasword