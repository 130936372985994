import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import address from '../../../assets/address not found.json';
import HandePage from '../../../components/NotFound/HandePage';
import Component from '../../../constants/Component';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import './style.scss';
const DeliveryAddress = () => {
  let { isLang } = useContext(LocalizationContext);
  let { fetchPersonalData } = useContext(SharedData)

  return (
    <div className="app__delivery_address mb-8" dir={isLang === "en" ? 'ltr' : 'rtl'}>

      <Container>
        {
          fetchPersonalData?.clientAddress?.length > 0 ?
            <>
              <Component.Title title={isLang === "en" ? 'Previous addresses' : 'العناوين السابقة'} />
              <div className="content mt-30" >
                {
                  fetchPersonalData?.clientAddress?.map((item, index) => (
                    <div className="address " key={index}>
                      <h3>{item?.address}</h3>
                    </div>
                  ))
                }
              </div>
            </> :
            <>
              <HandePage src={address} msg=" You have no previous addresses " msgAr={'  لا يوجد لديك عناوين سابقة '} />
            </>

        }

      </Container>
    </div>
  )
}

export default DeliveryAddress
