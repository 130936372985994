import Icons from "../../constants/Icons";

const routes = [
    {
        path: "/",
        nameEn: "Home",
        nameAr: "  الرئيسية",
        icon: <Icons.Home className="logoSvg" style={{ width: 20 }} />,
    }, 
    {
        path: "/cars",
        nameEn: "Fleet  ",
        nameAr: "أسطـول الغزال   ",
        icon: <Icons.DeliveryAddress className="logoSvg" style={{ width: 20 }} />,
    },
 

    {
        path: "/offers",
        nameEn: "  Offers",
        nameAr: "   العروض    ",
        icon: <Icons.Points className="logoSvg" style={{ width: 20 }} />,
    },

    {
        path: "/branches",
        nameEn: "  Branches",
        nameAr: "الفروع",
        icon: <Icons.Location className="logoSvg" style={{ width: 20 }} />,
    },

    {
        path: "/about",
        nameEn: "About Us",
        nameAr: "من نــحن",
        icon: <Icons.Aboutus className="logoSvg" style={{ width: 20 }} />,
    },
    
    {
        path: "/blogs",
        nameEn: "About Us",
        nameAr: "المدونات",
        icon: <Icons.Blogger className="logoSvg" style={{ width: 20 }} />,
    },
    
    // {
    //     path: "/career",
    //     nameEn: "about Us",
    //     nameAr: "الوظائف المتاحة",
    //     icon: <Icons.Jops className="logoSvg" style={{ width: 20 }} />,
    // },
    {
        path: "/contactus",
        nameEn: "  Contact Us",
        nameAr: "تواصل معنا ",
        icon: <Icons.Call className="logoSvg" style={{ width: 20 }} />,
    },
 

];

export default routes