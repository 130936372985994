import React from 'react'
import img from '../../constants/Img'
import './loading.scss'
const Loader = () => {

    return (
        <>
            <div className="app__loading  ">
                {/* <div className="app__loading flex flex-column gap-4"> */}
                <img src={img.Logo4} alt="image logo" loading='lazy' />
                {/* <h1>Under Maintenance</h1> */}

            </div>
        </>
    )
}

export default Loader