import axios from 'axios';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import * as Yup from 'yup';




const Apply = ({ show, handleClose, idJob, isLang }) => {
    const toast = useRef(null);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(isLang === "en" ? "Full Name is required" : "الاسم الكامل مطلوب"),
        mobile: Yup.string().required(isLang === "en" ? "Phone Number is required" : "رقم الهاتف مطلوب"),
        email: Yup.string().email(isLang === "en" ? "Invalid email address" : "عنوان البريد الإلكتروني غير صالح").required(isLang === "en" ? "Email is required" : "البريد الإلكتروني مطلوب"),
    });
    const [cv, setCv] = useState(null)
    const handleUpload = async (event) => {
        setCv(null)
        const formData = new FormData();
        formData.append('file', event.files[0]);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL_IMAGE}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                // هنا يمكنك تحديث قيمة الـ state بناءً على الاستجابة
                setCv(response.data)
            }
        } catch (error) {
            console.error('فشل في التحميل:', error);
        }
    };
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            cv: '',
            jobId: '',
        },
        validationSchema, // Include the validation schema here

        onSubmit: async (values) => {
            const urlCv = `${process.env.REACT_APP_API_URL}/jobs?name=${values.name}&email=${values.email}&mobile=${values.mobile}&cv=${cv}&jobId=${idJob}`

            let { data } = await axios.post(urlCv)
            if (data.status === true) {
                handleClose()
                setCv(null)
                toast.current.show({ severity: 'success', summary: "Send Your Cv ", detail: 'Message Content' });

            } else {
                toast.current.show({ severity: 'error', summary: "Data inValid ", detail: 'Message Content' });

            }
        }
    });
    return (
        <div>
            <Toast ref={toast} position={'bottom-center'} />
            <Modal show={show} onHide={handleClose} animation={true} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Apply Now </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="app__apply_jop">
                        <form onSubmit={formik.handleSubmit} className='w-100'>
                            <div   >
                                <Row>
                                    <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                                        <InputText
                                            id="name"
                                            name="name"
                                            type="text"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            aria-describedby="username-help"

                                            placeholder={isLang === "en" ? 'Full Name*' : ' الاسم الكامل * '}
                                            className='bg-light w-100 p-inputtext-sm' />

                                        {formik.errors.name && formik.touched.name ?
                                            <Message severity="error" text={formik.errors.name} /> : null
                                        }

                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} className='mt-3  w-100'>

                                        <InputText
                                            id="mobile"
                                            name="mobile"
                                            type="text"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            aria-describedby="username-help"
                                            placeholder={isLang === "en" ? 'Phone Number*' : 'رقم الجوال*'}
                                            className='bg-light w-100 p-inputtext-sm' />

                                        {formik.errors.mobile && formik.touched.mobile ?
                                            <Message severity="error" text={formik.errors.mobile} /> : null
                                        }

                                    </Col>

                                    <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                                        <InputText
                                            id="email"
                                            name="email"
                                            type="email"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            aria-describedby="username-help"
                                            placeholder={isLang === "en" ? 'Email*' : '  البريد الإلكتروني   * '}
                                            className='bg-light w-100 p-inputtext-sm' />

                                        {formik.errors.email && formik.touched.email ?
                                            <Message severity="error" text={formik.errors.email} /> : null
                                        }

                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                                        <FileUpload
                                            name="file"
                                            url={`${process.env.REACT_APP_URL_IMAGE}`}
                                            multiple
                                            accept=".pdf, .doc"
                                            emptyTemplate={cv &&
                                                <>
                                                    {
                                                        cv?.split('.')?.pop() === "pdf" ?
                                                            <iframe src={`${process.env.REACT_APP_URL_IMAGE}/${cv}`} title="PDF Document" width="100%" height="200px" /> :
                                                            <iframe src={`${process.env.REACT_APP_URL_IMAGE}/${cv}`} title="عرض ملف Word" width="100%" height="200px" />

                                                    }
                                                </>
                                            }
                                            onUpload={handleUpload}
                                            chooseLabel={isLang === "en" ? "Choose a resume  " : "اختر السيرة الذاتية"}
                                            uploadLabel={isLang === "en" ? "File upload " : "تحميل الملف"}
                                            cancelLabel={isLang === "en" ? "Cancel" : "إلغاء"}
                                        />


                                        {cv === null ?
                                            <Message severity="error" text={'Cv is required'} /> : null
                                        }
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} className='mt-3 app__apply_jop_submit'>
                                        <Button disabled={!cv} label={isLang === "en" ? ' Send' : ' إرســــــل  '} severity='secondary' className='w-4 mt-3 w-100' />
                                    </Col>

                                </Row>

                            </div>
                        </form>


                    </div>

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default Apply
