

import React, { useContext, useEffect } from 'react'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { LocalizationContext } from './context/LangChange';

const LanguageWrapper = () => {
    const { lang } = useParams();
    const { setIsLang,   } = useContext(LocalizationContext);
  
    useEffect(() => {
      // يقوم بتعيين اللغة في السياق
      setIsLang(lang);
    }, [lang, setIsLang]);
  
    return <Outlet />;
}

export default LanguageWrapper
