import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from 'primereact/message';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import img from '../../../../constants/Img';
import { LocalizationContext } from '../../../../context/LangChange';
import useConnectApi from '../../../services/ContactApi';

const HomeContactUS = () => {

    const bgStyle = {
        backgroundImage: `url(${img.ContactBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%'
    };
    let { isLang } = useContext(LocalizationContext);
    const { formik } = useConnectApi(); // Use the formik configuration


    return (
        <>
            <div className="app__contact pt-45 pb-45 mt-50 mb-50 " style={bgStyle} dir='rtl'>
                <div className="app__contact_container">
                    <div >
                        <Row className='app__contact'>
                            <Col xl={6} lg={6} md={6} sm={12}>
                                <div className='lg:w-9  md:w-full'>
                                    <form onSubmit={formik.handleSubmit} className='w-100'>
                                        <Row>
                                            <Col xl={6} lg={6} md={12} sm={12} className='mt-3' dir={isLang === "en" ? "ltr" : 'rtl'}>
                                                <InputText
                                                    id="mobile"
                                                    name="mobile"
                                                    value={formik.values.mobile}
                                                    type="text"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    aria-describedby="username-help"
                                                    placeholder={isLang === "en" ? 'Mobile Number*' : 'رقم الجوال*'}
                                                    className='bg-light w-100 p-inputtext-sm' />
                                                {formik.errors.mobile && formik.touched.mobile ?
                                                    <Message severity="error" text={formik.errors.mobile} /> : null
                                                }
                                            </Col>
                                            <Col xl={6} lg={6} md={12} sm={12} className='mt-3' dir={isLang === "en" ? "ltr" : 'rtl'}>
                                                <InputText
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    value={formik.values.name}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    aria-describedby="username-help" placeholder={isLang === "en" ? ' Full Name*' : '    الاسم الكامل * '} className='bg-light w-100 p-inputtext-sm' />
                                                {formik.errors.name && formik.touched.name ?
                                                    <Message severity="error" text={formik.errors.name} /> : null
                                                }
                                            </Col>
                                            <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                                                <InputText
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    value={formik.values.email}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    aria-describedby="username-help"
                                                    placeholder={isLang === "en" ? 'Email*' : '    بريد الإلكتروني * '}
                                                    className='bg-light w-100 p-inputtext-sm' />

                                                {formik.errors.email && formik.touched.email ?
                                                    <Message severity="error" text={formik.errors.email} /> : null
                                                }

                                            </Col>
                                            <Col xl={12} lg={12} md={12} sm={12} className='mt-3' dir={isLang === "en" ? "ltr" : 'rtl'}>
                                                <InputTextarea
                                                    id="message"
                                                    name="message"
                                                    type="text"
                                                    value={formik.values.message}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    placeholder={isLang === "en" ? 'Message Details **' : 'تفاصيل رساله *'} rows={8} cols={30} className='bg-light w-100 p-inputtext-sm' />
                                                {formik.errors.message && formik.touched.message ?
                                                    <Message severity="error" text={formik.errors.message} /> : null
                                                }
                                            </Col>
                                        </Row>
                                        <div className="btn_submit">
                                            <Button size='small' type='submit' label={isLang === "en" ? 'Send' : 'إرســـال'} severity='warning' rounded className='w-4 mt-3' />
                                        </div>
                                    </form>

                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={6} sm={12} >
                                <div className="app__content-contactUS">
                                    <h1>   {isLang === "en" ? 'Contact Us now' : 'تواصل معنا الآن'} </h1>
                                    <p>{isLang === "en" ? 'Al-ghazal mobile application, start your journey in a unique style with our distinctive car rental service.' : 'تطبيق الغزال للجوال, ابتدأ رحلتك بأسلوب فريد من نوعه مع خدمة تأجير سياراتنا المميزة.'}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeContactUS
