import { Button } from 'primereact/button';
import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Component from '../../../../constants/Component';
import { LocalizationContext } from '../../../../context/LangChange';
import './style.scss';

const HomeOffers = ({ dataHome }) => {
    let { isLang } = useContext(LocalizationContext);

    return (
        <>
            <div className="app__home__offers pt-20 pb-20 " dir={isLang === "en" ? 'ltr' : 'rtl'}>
                <Container>
                    <Component.Title title={isLang === "en" ? 'Best offers' : 'افضل العروض'} />

                    <Row className='mt-3 offer_column_reverse'>


                        <Col xl={6} lg={6} md={12} sm={12} className='mt-2' >
                            <Row className='flex justify-content-between h-100' >
                                {
                                    dataHome[0] &&
                                    <Col xl={6} lg={6} md={6} sm={6} xs={6}  >
                                        <div className="img__offer">
                                            <Link to={`/offers/details/${dataHome[0].id}`}  >
                                                <img src={dataHome[0].image} className='w-100 img__offer_sm' alt="images" loading='lazy'/>
                                                <div className="content">
                                                    <h1  >   {isLang === "en" ? dataHome[0].title : dataHome[0].title_arabic}</h1>
                                                    <p className={`${isLang === "en" ? "" : "px-2 text-right"}`}>
                                                        {isLang === "en" ? dataHome[0].desc : dataHome[0].desc_arabic}
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </Col>
                                }
                                {
                                    dataHome[1] &&
                                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className=' '>
                                        <div className="img__offer">
                                            <Link to={`/offers/details/${dataHome[1].id}`}  >
                                                <img src={dataHome[1].image} className='w-100 img__offer_sm' alt="images" loading='lazy'/>

                                                <div className="content">
                                                    <h1  >   {isLang === "en" ? dataHome[1].title : dataHome[1].title_arabic}</h1>
                                                    <p className={`${isLang === "en" ? "" : "px-2 text-right"}`}>
                                                        {isLang === "en" ? dataHome[1].desc : dataHome[1].desc_arabic}
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </Col>
                                }
                                {
                                    dataHome[3] &&
                                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className=''>
                                        <div className="img__offer">
                                            <Link to={`/offers/details/${dataHome[3].id}`} >
                                                <img src={dataHome[3].image} className='w-100 img__offer_sm' alt="images" loading='lazy'/>
                                                <div className="content">
                                                    <h1  >   {isLang === "en" ? dataHome[3].title : dataHome[3].title_arabic}</h1>
                                                    <p className={`${isLang === "en" ? "" : "px-2 text-right"}`}>
                                                        {isLang === "en" ? dataHome[3].desc : dataHome[3].desc_arabic}
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </Col>
                                }
                                {
                                    dataHome[4] &&
                                    <Col xl={6} lg={6} md={6} sm={6} xs={6} className=''>
                                        <div className="img__offer">
                                            <Link to={`/offers/details/${dataHome[4].id}`}>
                                                <img src={dataHome[4].image} className='w-100 img__offer_sm' alt="images" loading='lazy'/>
                                                <div className="content">
                                                    <h1  >   {isLang === "en" ? dataHome[4].title : dataHome[4].title_arabic}</h1>
                                                    <p className={`${isLang === "en" ? "" : "px-2 text-right"}`}>
                                                        {isLang === "en" ? dataHome[4].desc : dataHome[4].desc_arabic}
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </Col>
                                }


                            </Row>
                        </Col>
                        {
                            dataHome[2] &&
                            <Col xl={6} lg={6} md={12} sm={12} className='mt-3'>
                                <div className="img__offer">
                                    <Link to={`/offers/details/${dataHome[2].id}`}  >
                                        <img src={dataHome[2].image} className='w-100 img__offer_lg' alt="images" loading='lazy'/>
                                        <div className="content">
                                            <h1  >   {isLang === "en" ? dataHome[2].title : dataHome[2].title_arabic}</h1>
                                            <p className={`${isLang === "en" ? "" : "px-2 text-right"}`}>
                                                {isLang === "en" ? dataHome[2].desc : dataHome[2].desc_arabic}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                        }

                    </Row>
                    <Row>

                        <Col xl={6} lg={6} md={12} sm={12} className='mt-3' >
                            <div className="app__btn_offer_column_reverse2">
                                <div className="app__btn flex justify-content-center w-100  " >
                                    <Link to={'/offers'} className='w-100'>
                                        <Button label={isLang === "en" ? 'View all' : 'مشاهدة الكل '} />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} className='mt-3' >
                            <div className="app__btn_offer_column_reverse">
                                <div className="app__btn flex justify-content-center w-100  " >
                                    <Link to={'/offers'} className='w-100'>
                                        <Button label={isLang === "en" ? 'View all' : 'مشاهدة الكل '} />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default HomeOffers
