import Icons from "../../constants/Icons";

const routes = [
  {
    path: "/account/personal_data",
    nameEn: "Profile",
    nameAr: "  البيانات الشخصية",
    icon: <i className="pi pi-user"></i>,
  }, 
  {
    path: "/account/points",
    nameEn: "Points",
    nameAr: "  النقاط",
    icon: <Icons.Points className="logoSvg" style={{ width: 17 }} />,
  }, 
  {
    nameAr: "حجوزاتى   ",
    nameEn: "  Bookings ",
    icon: <Icons.DeliveryAddress className="logoSvg" style={{ width: 17 }} />, Roles: [1],
    subRoutes: [
      {
        path: "/account/manage",
        // nameAr: "إدارة حجوزاتى  ",
        nameAr: "حجوزات حالية",
        // nameEn: "Manage My booking  ",
        nameEn: "Current Bookings  ",
        icon: <Icons.Aboutus className="logoSvg" style={{ width: 17 }} />,
      },
      {
        path: "/account/bookings",
        nameAr: " حجوزات سابقة  ",
        nameEn: "Last Bookings",
        icon: <Icons.Aboutus className="logoSvg" style={{ width: 17 }} />,
      },
    ],
  },
];

export default routes