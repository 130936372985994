import {
    GoogleMap,
    Marker,
    useLoadScript
} from '@react-google-maps/api';
import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Icons from '../constants/Icons';
import { CarFilterStates } from '../context/FiltersCars';
import { Button } from 'primereact/button';
import { LocalizationContext } from '../context/LangChange';

const libraries = ['places'];


const LocationGoogleMap = ({ height }) => {
    const mapContainerStyle = {
        width: '100%',
        height: height,
        borderRadius: '8px'
    };
    let { userLocationMap, setLoctionMap } = useContext(CarFilterStates);
    let { isLang } = useContext(LocalizationContext);

    const [markers, setMarkers] = useState([]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [map, setMap] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null); // Add state for current location

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAtUOb461InzoQoGEVKKVqqLf2NbwSjqdk',
        libraries,
    });


    const handleMapClick = event => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newMarker = { lat, lng }; 

        // Clear current location state only if there was a pin on the current location
        if (currentLocation) {
            setCurrentLocation(null);
        }

        setMarkers([newMarker]);
        setSelectedMarker(newMarker);
        setLoctionMap(newMarker);
    };

    const handleMarkerClick = marker => {
        setSelectedMarker(marker);
        setLoctionMap(marker);

    };


    const handleCurrentLocationClick = () => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords;
                const newMarker = { lat: latitude, lng: longitude };
                setMarkers([newMarker]);
                setSelectedMarker(newMarker);
                setCurrentLocation(newMarker); // Set current location state
                setLoctionMap(newMarker);
            },
            error => {
                console.error(error);
            }
        );
    };

    const handleMapLoad = (map) => {
        setMap(map);
    };
    const handleMapDragEnd = () => {
        if (map) {
            // Get the center of the map when dragging ends
            const center = map.getCenter();
            const newMarker = { lat: center.lat(), lng: center.lng() };

            // Clear current location state only if there was a pin on the current location
            if (currentLocation) {
                setCurrentLocation(null);
            }

            setMarkers([newMarker]);
            setSelectedMarker(newMarker);
            setLoctionMap(newMarker);
        }
    };
    useEffect(() => {
        handleCurrentLocationClick()

        return () => {
            handleCurrentLocationClick()

        }
    }, [])
    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading Maps';

    return (
        <Container className=''>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={18}
                center={currentLocation || userLocationMap} // Use current location if available
                onClick={handleMapClick}
                onLoad={handleMapLoad}
                onDragEnd={handleMapDragEnd}
            >


                {currentLocation && ( // Add marker for current location
                    <Marker position={currentLocation} />
                )}

                {markers.map((marker, index) => (
                    <Marker
                        key={index}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onClick={() => handleMarkerClick(marker)}
                    />
                ))}

            </GoogleMap>




            {/*         <div className="btn_pay location_btn mt-6  w-100">
                <Button icon={<Icons.Location />} severity="secondary" className='w-12' dir='ltr' label={isLang === "en" ? 'Detect location' : 'تحديد الموقع '} onClick={handleCurrentLocationClick} />
            </div> */}
        </Container>
    );
};

export default LocationGoogleMap
