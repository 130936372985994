import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React, { useContext, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import { Link, useLocation } from 'react-router-dom';
import Component from '../../../../constants/Component';
import Icons from '../../../../constants/Icons';
import { LocalizationContext } from '../../../../context/LangChange';
import './style.scss';

const LatestCars = ({ carsSliderHome }) => {
    let { isLang } = useContext(LocalizationContext);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 4,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1200, min: 767 },
            items: 3,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1
        },
        customTablet: {
            breakpoint: { max: 768, min: 480 },
            items: 2,
            slidesToSlide: 1
        },
        customBreakpoint: {
            breakpoint: { max: 1500, min: 1201 },
            items: 4,
            slidesToSlide: 1
        },
        extraSmallMobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('path', currentPath)
    }, [])
    return (
        <div dir={isLang === "en" ? 'ltr' : 'rtl'} className='app__cars_slider'>
            <div className="mt-5  app__cars_slider_container  ">
                <div className='app__cars pt-30 pb-30'>
                    <Component.Title title={isLang === "en" ? 'Latest Cars' : 'احدث السيارات'} />
                </div>
                <Carousel
                    showDots={true}
                    responsive={responsive}
                    ssr={true}
                    autoPlay
                    autoPlaySpeed={2000}
                    infinite
                    keyBoardControl={true}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    swipeable={true} // Enable swipe gesture for smooth sliding
                    transitionDuration={500} //
                >
                    {
                        carsSliderHome?.map((item, index) => (
                            <div key={index} className="app__cars_slider_card flex justify-content-center p-2">
                                <Card
                                    className="md:w-21rem  "
                                    title={`${isLang === "en" ? item.brand : item.brand_arabic}` + '  ' + `${isLang === "en" ? item.sub_brand : item.sub_brand_arabic}`}
                                    header={<img src={item.image} className='image_card w-100 IMA_CAR' alt="Car" loading='lazy' />}
                                >
                                    <div className="slider_card" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                        <ul>
                                            <li>
                                                <Icons.SliderCarSedan />
                                                <strong className='bold'>{item.year} {' '} {isLang === "en" ? item.type : item.type_arabic}  </strong>
                                            </li>
                                            <li>
                                                <Icons.SliderCarMoney />
                                                {item.offer !== 0 && <span className='app__discount'>{item.price}  </span>}

                                                {
                                                    item.offer === 0 ?
                                                        <span>{item.price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                        :
                                                        <span>{item.offer_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                }
                                                <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>

                                            </li>
                                            <li>
                                                <Icons.SliderCarPeople />
                                                <span>{item.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                                            </li>
                                            {item.aircondition !== 0 && <li>
                                                <Icons.SliderCarSoil />
                                                <span>    {isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                                            </li>}

                                            {item.aircondition === 0 && <li>
                                                <Icons.SliderCarSoil />
                                                <span>    {isLang === "en" ? 'Non-air conditioned' : 'غير مكيفة'} </span>
                                            </li>}


                                        </ul>
                                        <div className="order   flex justify-content-end " >
                                            <Link to={`/${isLang}/cars/${item.id}?car-name=${item.brand_arabic}_${item.sub_brand_arabic}`}>
                                                <Button label={isLang === "en" ? 'Book now ' : '  احجزها الآن '} size='small' severity="warning" />
                                            </Link>

                                        </div>
                                    </div>
                                </Card>
                                {
                                    item.offer !== 0 && <div className="price absolute" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                        <span>{isLang === "en" ? 'off' : 'خصم'}</span>
                                        {item.percentage}%
                                    </div>
                                }
                            </div>
                        ))
                    }

                </Carousel>
            </div>
            <div className="app__btn flex justify-content-center  mt-5 " >
                <Link to={'/cars'}>
                    <Button label={isLang === "en" ? 'View all' : 'مشاهدة الكل '} className='w-100' />
                </Link>
            </div>

        </div>
    )
}

export default LatestCars
