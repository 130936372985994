import React, { useContext } from 'react'
import './Banner.scss'
import { LocalizationContext } from '../../context/LangChange';
const Banner = ({ img, dec, title }) => {
    let { isLang } = useContext(LocalizationContext);

    return (
        <div className="app__connect" dir={isLang === "en" ? 'ltr' : 'rtl'}>
            <img src={img} alt="images" loading='lazy' />
            <div className="content">
                <h3>{title}</h3>
                <p>{dec}</p>
            </div>
        </div>
    )
}

export default Banner
