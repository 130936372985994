import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { format, isValid, parse } from 'date-fns';
import { arSA, enUS } from 'date-fns/locale';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Component from '../../../../constants/Component';
import { LocalizationContext } from '../../../../context/LangChange';
import './style.scss';
import useLocalizedNavigate from '../../../../hook/useLocalizedNavigate';


export const BlogSection = () => {
    let { isLang } = useContext(LocalizationContext);
    const [blogPosts, setBlogPosts] = useState([]);
    const localizedNavigate = useLocalizedNavigate();

    const fetchData = async () => {
        let data = await axios.get(`${process.env.REACT_APP_API_URL}/blogs?lang=${isLang}`)
        setBlogPosts(data?.data);
    }
    useEffect(() => {
        fetchData()
    }, [isLang]);

    const parseDateString = (dateString) => {
        return parse(dateString, 'dd/MM/yyyy', new Date());
    };

    return (
        <div className={`${isLang == 'ar' ? 'rtl' : 'ltr'}`}>
            {
                blogPosts.length > 0 ?
                    <div className="blog_bg  ">
                        <div className="blog_section">
                        <div dir={isLang === "en" ? 'ltr' : 'rtl'} className='app__cars_slider more_cars_2 flex justify-content-between align-items-center mb-4'>
                            <Component.Title title={isLang == "en" ? 'Blogs' : 'المدونات'} />
                            <Link className={`${isLang === "en" ? 'more_cars__en' : 'more_cars__ar'}`} onClick={() => localizedNavigate('/blogs')}  >
                                {isLang == "ar" ? 'رؤية المزيد' : 'See more'}
                            </Link>
                        </div>
                            <Row className='mb-8' dir={isLang == 'ar' ? 'rtl' : 'ltr'}>
                                {blogPosts?.slice(0, 3)?.map((item, index) => (
                                    <Col key={index} xl={4} lg={4} md={12} sm={24} xs={24} className='mt-3'>
                                        <Card className="blog_card h-100 shadow-sm" dir={isLang == 'ar' ? 'rtl' : 'ltr'}>
                                            <Link to={`/blogs/${item.id}/${item.slug}`} className={`  header_blog`}>
                                                <Card.Img
                                                    variant="top"
                                                    src={item.image}
                                                    alt={item.title}
                                                    // className={`blog_image  `}
                                                />
                                            </Link>
                                            <Link to={`/blogs/${item.id}/${item.slug}`} className='blog_meta_a' >
                                                <Card.Body>
                                                    <div className="blog_meta  d-flex gap-3 align-items-center">
                                                        <i className='pi pi-calendar'></i>
                                                        {isValid(parseDateString(item.date)) ? (
                                                            <span className='blog_date'>
                                                                {isLang === 'ar'
                                                                    ? format(parseDateString(item.date), 'dd MMMM yyyy', { locale: arSA })
                                                                    : format(parseDateString(item.date), 'MMMM dd, yyyy', { locale: enUS })
                                                                }
                                                            </span>
                                                        ) : (
                                                            <span>Invalid date</span>
                                                        )}
                                                    </div>
                                                    <div className="blogs_data">
                                                        <h3 className='mt-2'> {item.title.split(" ").slice(0, 7).join(" ")}</h3>
                                                        <p>{item.short.split(" ").slice(0, 15).join(" ")}</p>
                                                    </div>
                                                </Card.Body>
                                            </Link>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div> : ''
            }

        </div>
    );
};
