import { useFormik } from 'formik';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Button } from 'primereact/button';
import { useMountEffect } from 'primereact/hooks';
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { Messages } from 'primereact/messages';
import { Steps } from 'primereact/steps';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LocalizationContext } from '../../../context/LangChange';
import img from './../../../constants/Img';
import './style.scss';
import useValidation from '../Services/useValidation';
import axios from 'axios';
import { Password } from 'primereact/password';
import { RadioButton } from 'primereact/radiobutton';
import PhoneInput from 'react-phone-input-2';

const ForgetPassword = () => {
    let { validateRestpasswordNewForm } = useValidation();

    const msgs = useRef(null);
    let { code } = useParams()
    let { validateRestpasswordForm } = useValidation()
    let { isLang } = useContext(LocalizationContext);
    const [emailVerifiy, setVerifiy] = useState('');
    const [checked, setChecked] = useState(false);
    const [enteredPassword, setEnteredPassword] = useState('');
    const [handelOpenPassword, setHandelOpenPassword] = useState('password');
    const [handelOpenPassword1, setHandelOpenPassword1] = useState('password');
    const [loadmobile, setLoadmobile] = useState(false);
    const [disabled, setIsDisabled] = useState(true);
    let navigate = useNavigate();
    const [otp, setOtp] = React.useState('')
    const [activeIndex, setActiveIndex] = useState(0);
    const toast = useRef(null);
    const [ingredient, setIngredient] = useState('email');

    const items = [
        {
            label: isLang === "en" ? 'Send code' : 'إرســـال رمز otp',
            command: (event) => {


            }
        },
        {
            label: isLang === "en" ? 'OTP' : 'OTP',
            command: (event) => {
            }
        },
        {
            label: isLang === "en" ? 'Reset password' : 'إعادة تعيين كلمة المرور',
            command: (event) => {
            }
        },
    ];

    const formik = useFormik({
        initialValues: {
            mobile: '',
        },
        validationSchema: validateRestpasswordForm,
        onSubmit: async (values) => {

            setLoadmobile(true)
            setVerifiy(values.mobile)

            const url = `${process.env.REACT_APP_API_URL}/restpassword?mobile=${values.mobile}`;
            let { data } = await axios.post(url)


            if (data?.status === true) {
                msgs.current.clear();
                msgs.current.show({ sticky: true, severity: 'success', summary: ` `, detail: data?.msg === "OTP_EMAIL_SENT" && isLang === "en" ? 'OTP has been sent to your email' : 'تم إرسال otp  لبريدك إلكتروني ' });
                setTimeout(() => {
                    setLoadmobile(false)
                    setActiveIndex(1)
                }, 1500);

            } else {
                msgs.current.clear();
                msgs.current.show({ sticky: true, severity: 'error', summary: ` `, detail: data?.msg === "CLIENT_NOT_FOUND" && isLang === "en" ? 'This account is not registered' : 'هذا الحساب غير مسجل' });

                setTimeout(() => {
                    setLoadmobile(false)
                }, 500);
            }

        }
    });

    const formikCheckOtp = useFormik({
        initialValues: {
            code: '',
        },
        // validationSchema: validateRestpasswordForm,
        onSubmit: async (values) => {

            setLoadmobile(true)

            const url = `${process.env.REACT_APP_API_URL}/otpcheck?mobile=${formik.values.mobile}&otp=${values.code}`;
            let { data } = await axios.post(url)

            if (data?.status === true) {
                msgs.current.clear();
                msgs.current.show({ sticky: true, severity: 'success', summary: ` `, detail: data?.msg === "OTP_EMAIL_SENT" && isLang === "en" ? 'OTP has been sent to your email' : 'تم إرسال otp  لبريدك إلكتروني ' });
                setTimeout(() => {
                    setLoadmobile(false)
                    setActiveIndex(2)
                }, 1500);

            } else {
                msgs.current.clear();
                msgs.current.show({ sticky: true, severity: 'error', summary: ` `, detail: data?.msg === "CLIENT_NOT_FOUND" && isLang === "en" ? 'This account is not registered' : 'هذا الحساب غير مسجل' });

                setTimeout(() => {
                    setLoadmobile(false)
                }, 500);
            }

        }
    });

    const formikResetpassword = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: validateRestpasswordNewForm,
        onSubmit: async (values) => {

            setLoadmobile(true)
            setVerifiy(values.code)

            const url = `${process.env.REACT_APP_API_URL}/setpassword?mobile=${formik.values.mobile}&password=${values.password}`;
            let { data } = await axios.post(url)

            if (data?.status === true) {
                msgs.current.clear();
                msgs.current.show({ sticky: true, severity: 'success', summary: ` `, detail: data?.msg === "OTP_EMAIL_SENT" && isLang === "en" ? 'OTP has been sent to your email' : 'تم إرسال otp  لبريدك إلكتروني ' });
                setTimeout(() => {
                    setLoadmobile(false)
                    setIsDisabled(false)
                    navigate('/auth/sign-in')
                }, 1500);

            } else {
                msgs.current.clear();
                msgs.current.show({ sticky: true, severity: 'error', summary: ` `, detail: data?.msg === "CLIENT_NOT_FOUND" && isLang === "en" ? 'This account is not registered' : 'هذا الحساب غير مسجل' });

                setTimeout(() => {
                    setLoadmobile(false)
                }, 500);
            }

        }
    });
    const handleChange = (newValue) => {
        setOtp(newValue)
        formikCheckOtp.setFieldValue('code', newValue)
    }


    useEffect(() => {

        // داخل دالة useEffect أو componentDidMount عند تحميل صفحة Login
        const rememberMe = localStorage.getItem('rememberMe');
        if (rememberMe === 'true') {
            // إذا تم تحديد زر "تذكرني" في الجلسة السابقة، قم بتعبئة حقول تسجيل الدخول
            const storedMobile = localStorage.getItem('rememberMeEmail');
            const storedPassword = localStorage.getItem('rememberMePassword');

            // قم بتعبئة حقول تسجيل الدخول بالمعلومات المخزنة
            formik.setFieldValue('mobile', storedMobile);
            formik.setFieldValue('password', storedPassword);
            setChecked(true); // قم بتحديد زر "تذكرني" على الواجهة إذا كان محفوظًا
        }


    }, [])
    useMountEffect(() => {

        if (code === 'DONE') {
            if (msgs.current) {
                msgs.current.clear();
                msgs.current.show([
                    { sticky: true, severity: 'warn', summary: ' ', detail: isLang === "en" ? 'Acount has been activete' : 'تم تفعيل حسابك بنجاح', closable: false },
                ]);
            }
        }
    });


    return (
        <>

            <div className='app__login'  >

                <Row className='h-100' dir={isLang === "en" ? 'ltr' : 'rtl'}>
                    <Col xl={6} lg={6} md={6} sm={12} className="bg_login_md_view h-100 app__login-contect  flex-column"  >
                        <div className="w-100">
                            <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} className='' />
                        </div>
                        {
                            activeIndex === 0 &&
                            <form onSubmit={formik.handleSubmit} className='w-100'>
                                <Container className='flex justify-content-center align-items-center flex-column'>
                                    {/* <h1> {isLang === "en" ? 'Forgot password' : 'نسيت كلمة المرور'}</h1> */}

                                    <div className="Inputs w-100 flex gap-3 flex-column  "   >
                                        <div className="  flex justify-content- ">
                                            <div className="flex flex-wrap gap-3">
                                                <div className="flex align-items-center gap-2">
                                                    <RadioButton inputId="ingredient1" name="pizza" value="phone" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'phone'} />
                                                    <label htmlFor="ingredient1" className="ml-2">{isLang === "en" ? 'Mobile Number' : '   رقم الجوال '}</label>
                                                </div>
                                                <div className="flex align-items-center gap-2">
                                                    <RadioButton inputId="ingredient2" name="pizza" value="email" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'email'} />
                                                    <label htmlFor="ingredient2" className="ml-2">{isLang === "en" ? '  Email' : 'بريد الإلكتروني'}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" flex flex-column gap-2 mt-2 ">
                                            {/* <label htmlFor="username">{isLang === "en" ? 'Mobile Number / Email' : '   رقم الجوال / بريد الإلكتروني'}  </label> */}
                                            {
                                                ingredient === 'email' &&
                                                <InputText
                                                    id="mobile"
                                                    name="mobile"
                                                    type="email"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    placeholder={isLang === "en" ? 'Enter your Mobile Number / email' : 'أدخل رقم الجوال /البريد الإلكتروني'}
                                                    className={`  custom-input`} // Add the custom-input class here
                                                    dir='ltr'
                                                    value={formik.values.mobile}
                                                />
                                            }
                                            {
                                                ingredient === 'phone' &&
                                                <div dir='ltr' className=" flex flex-column login_by_phone gap-2 ">

                                                    <PhoneInput
                                                        country='sa'
                                                        enableSearch={true}
                                                        id="mobile"
                                                        name="mobile"
                                                        preferredCountries={['sa']}
                                                        // onChange={(value) => formik.setFieldValue('mobile', value)}
                                                        onChange={(value, countryData) => {
                                                            // استخراج مفتاح الدولة من بيانات الدولة
                                                            let dialCode = countryData.dialCode;
                                                            // إزالة مفتاح الدولة من القيمة المدخلة
                                                            let newValue = value.replace(dialCode, '');
                                                            // إذا كانت القيمة الجديدة تبدأ بالصفر، ازل هذا الصفر
                                                            if (newValue.startsWith('0')) {
                                                              newValue = newValue.substring(1);
                                                            }
                                                            // إعادة إضافة مفتاح الدولة إلى القيمة الجديدة
                                                            newValue = dialCode + newValue;  
                                                            formik.setFieldValue('mobile', newValue);
                                                          }}
                                                        onBlur={formik.handleBlur}
                                                        dir="ltr"
                                                        value={formik.values.mobile}
                                                        containerClass="w-full"
                                                        className="w-full "
                                                        inputClass={'w-100'}
                                                        // localization={ar}   
                                                        inputExtraProps={{
                                                            autoFocus: true,
                                                        }}
                                                    />
                                                </div>

                                            }
                                            {formik.errors.mobile && formik.touched.mobile ?
                                                <Message severity="error" text={formik.errors.mobile} /> : null
                                            }
                                        </div>

                                        <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                                            <Messages ref={msgs} className='w-100' />
                                        </div>
                                        <div className="flex flex-column    mt-2  ">
                                            <div className='w-100'>
                                                <Button loading={loadmobile} disabled={loadmobile} label={isLang === "en" ? 'Send OTP' : 'ارسال الرمز'} className='w-100' severity='warning' />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="app__now_account">
                                        <span >  {isLang === "en" ? `Do you have an account? ` : '  لديك حساب ؟  '} <Link to={'/auth/sign-in'}>   {isLang === "en" ? 'Sign in' : ' تسجيل دخول  '}</Link></span>
                                    </div>

                                </Container>
                            </form>
                        }


                        {
                            activeIndex === 1 &&
                            <form onSubmit={formikCheckOtp.handleSubmit} className='w-100'>
                                <Container className='flex justify-content-center align-items-center flex-column'>
                                    {/* <h1> {isLang === "en" ? 'Forgot password' : 'نسيت كلمة المرور'}</h1> */}

                                    <div className="Inputs w-100 flex gap-3 flex-column  "   >

                                        <div className=" flex flex-column gap-2 BG-INFO   ">{/* 
                                            <label htmlFor="username">{isLang === "en" ? 'OTP' : '   رقم  OTP'}  </label> */}
                                            <div className="otp_input" dir='ltr'>
                                                <MuiOtpInput name="code" value={otp} onChange={handleChange} length={4} />
                                            </div>

                                            {formikCheckOtp.errors.mobile && formikCheckOtp.touched.mobile ?
                                                <Message severity="error" text={formikCheckOtp.errors.mobile} /> : null
                                            }
                                        </div>

                                        <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                                            <Messages ref={msgs} className='w-100' />
                                        </div>
                                        <div className="flex flex-column    mt-2  ">
                                            <div className='w-100'>
                                                <Button loading={loadmobile} disabled={loadmobile} label={isLang === "en" ? 'Continue' : 'استمرار'} className='w-100' severity='warning' />
                                            </div>
                                        </div>


                                    </div>

                                    <div className="app__now_account">
                                        <span >  {isLang === "en" ? `Do you have an account? ` : '  لديك حساب ؟  '} <Link to={'/auth/sign-in'}>   {isLang === "en" ? 'Sign in' : ' تسجيل دخول  '}</Link></span>
                                    </div>

                                </Container>
                            </form>
                        }

                        {
                            activeIndex === 2 &&
                            <form onSubmit={formikResetpassword.handleSubmit} className='w-100'>
                                <Container className='flex justify-content-center align-items-center flex-column'>
                                    {/* <h1> {isLang === "en" ? 'Forgot password' : 'نسيت كلمة المرور'}</h1> */}

                                    <div className="Inputs w-100 flex gap-3 flex-column  "   >

                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username" >{isLang === "en" ? '  Password' : '   كلمة المرور   '}   </label>
                                            {
                                                handelOpenPassword === "password" ?
                                                    <div className="password_open">
                                                        <Password
                                                            id="password"
                                                            name="password"
                                                            type={handelOpenPassword}
                                                            onBlur={formikResetpassword.handleBlur}
                                                            value={enteredPassword}
                                                            onChange={(e) => {
                                                                setEnteredPassword(e.target.value);
                                                                formikResetpassword.handleChange(e);
                                                            }}
                                                            className="p-inputtext-sm"
                                                            dir='ltr'
                                                            footer={formikResetpassword.errors.password && formikResetpassword.touched.password ?
                                                                <div dir={isLang !== "en" ? 'ltr' : 'rtl'} >
                                                                    <Message severity="error" text={formikResetpassword.errors.password} />
                                                                </div>
                                                                : <Message severity="error" text='' />
                                                            }
                                                        />

                                                        {
                                                            handelOpenPassword === "password" ?
                                                                <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                                                                <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>
                                                        }
                                                    </div> :
                                                    <div className="password_open">
                                                        <InputText
                                                            id="password"
                                                            name="password"
                                                            type={handelOpenPassword}
                                                            onBlur={formikResetpassword.handleBlur}
                                                            value={enteredPassword}
                                                            onChange={(e) => {
                                                                setEnteredPassword(e.target.value);
                                                                formikResetpassword.handleChange(e);
                                                            }}
                                                            className="p-inputtext-sm w-full"
                                                            dir='ltr'
                                                        />

                                                        {
                                                            handelOpenPassword === "password" ?
                                                                <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                                                                <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>
                                                        }
                                                    </div>

                                            }

                                            {formikResetpassword.errors.password && formikResetpassword.touched.password ?
                                                <Message severity="error" text={formikResetpassword.errors.password} /> : null
                                            }
                                        </div>
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="confirmPassword" className="f  mb-2"> {isLang === "en" ? 'Confirm Password   ' : 'تأكيد كلمة المرور    '}  </label>
                                            <div className="password_open">
                                                <InputText
                                                    id="confirmPassword"
                                                    name="confirmPassword"
                                                    type={handelOpenPassword1}
                                                    onBlur={formikResetpassword.handleBlur}
                                                    onChange={formikResetpassword.handleChange}
                                                    className="w-full p-inputtext-sm"
                                                    dir='ltr'
                                                />
                                                {
                                                    handelOpenPassword1 === "password" ?
                                                        <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword1('text')}  ></i> :
                                                        <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword1('password')}  ></i>

                                                }
                                            </div>
                                            {formikResetpassword.errors.confirmPassword && formikResetpassword.touched.confirmPassword ?
                                                <Message severity="error" text={formikResetpassword.errors.confirmPassword} /> : null
                                            }
                                        </div>
                                        <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                                            <Messages ref={msgs} className='w-100' />
                                        </div>
                                        <div className="flex flex-column    mt-2  ">
                                            <div className='w-100'>
                                                <Button loading={loadmobile} label={isLang === "en" ? 'Change password' : 'تغير باسورد'} className='w-100' severity='warning' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="app__now_account">
                                        <span>{isLang === "en" ? `Do you have an account? ` : '  لديك حساب ؟  '} <Link to={'/auth/sign-in'}>   {isLang === "en" ? 'Sign in' : ' تسجيل دخول  '}</Link></span>
                                    </div>

                                </Container>
                            </form>
                        }
                    </Col>
                    <Col xl={6} lg={6} md={6} className='h-100 bg_login_md' sm={12}>
                        <div className="app__login-image h-100">
                            <img src={img.LoginBg} className='w-100 h-100 ' alt="images" loading='lazy' />
                            <Link as={Link} to={'/auth/sign-in'}>
                                <div className="btn_back cursor-pointer shadow-lg" >
                                    <i className={`pi ${isLang === "en" ? "pi-arrow-right " : "pi-arrow-left"} `}></i>
                                </div>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ForgetPassword
