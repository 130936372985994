import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loaderImg from '../../../assets/Loaders.json';
import Component from '../../../constants/Component';
import Icons from '../../../constants/Icons';
import img from '../../../constants/Img';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import useValidation from '../../auth/Services/useValidation';
import { updateProfileData } from '../../services/ProfileAuth';
import Nationalities from '../../auth/Register/Nationalities';
const EditAccount = () => {
    const toast = useRef(null);
    let { isLang } = useContext(LocalizationContext);
    let { fetchCities, fetchPersonalData, fetchPersonal } = useContext(SharedData)
    const gender = [
        { name: 'male', value: 0 },
        { name: 'female', value: 1 },
    ];
    const nationality = Nationalities();
    let { validateUpdateProfile } = useValidation()
    let navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            nationality: '',
            gender: fetchPersonalData.gender !== null ? fetchPersonalData.gender : '', // Set gender to the value from fetchPersonalData.gender
            cityId: '',
            address: '',
            type: '',
            image: ''
        },
        // isValid: false, 
        validationSchema: validateUpdateProfile,
        onSubmit: (values) => updateProfileData(values).then((data) => {
            
            const { severity, summary, detail, updateDate } = data;
            try {
                if (updateDate === true) {
                    const rememberMe = localStorage.getItem('rememberMe');
                    if (rememberMe === 'true') {
                        localStorage.removeItem('rememberMeEmail');
                        localStorage.setItem('rememberMeEmail', values?.mobile);
                    }
                }
                if (updateDate === true) {
                    fetchPersonal()
                    navigate('/account/personal_data')
                }
                toast.current.show({ severity, summary, detail, life: 3000 });
            } catch (error) {

            }
        })
    });
    useEffect(() => {
        formik.setValues({
            firstName: fetchPersonalData?.first_name || '',
            lastName: fetchPersonalData.last_name || '',
            email: fetchPersonalData.email || '',
            mobile: fetchPersonalData.mobile || '',
            type: fetchPersonalData.type !== null ? fetchPersonalData.type : '',
            nationality: fetchPersonalData.nationality || '',
            gender: fetchPersonalData.gender !== null ? fetchPersonalData.gender : '',
            address: fetchPersonalData.address || '',
            image: fetchPersonalData.image || '',
            cityId: fetchPersonalData.cityId !== null ? fetchPersonalData.cityId : '',
        });
    }, [fetchPersonalData, formik.setValues]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleImageSelect = async (event) => {
        try {
            setIsLoading(true); // تعيين isLoading إلى true 
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            const { data } = await axios.post(`${process.env.REACT_APP_URL_IMAGE}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setSelectedImage(data);
            formik.values.image = data
            // You can handle the response here as needed.
            setInterval(() => {
                setIsLoading(false);
            }, 1000);
        } catch (error) {
            setIsLoading(false);
            console.error('Error uploading the image:', error);
        }
    };
    useEffect(() => {
        window.scrollBy(0, -window.pageYOffset);
    }, [])

    return (
        <div className="app__PersonalData mb-8">
            <Toast ref={toast} position={'bottom-center'} />
            <form onSubmit={formik.handleSubmit} className='w-100'>
                <div className="header ">
                    <div className="img  ">
                        <div className="personal">
                            {
                                isLoading ?
                                    <>
                                        <div className="load_img">
                                            <Player
                                                className='w-100'
                                                // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                                                src={loaderImg}
                                                autoplay
                                                loop
                                            />
                                        </div>
                                    </> :
                                    <>
                                        {selectedImage ?
                                            <img src={`${process.env.REACT_APP_URL_IMAGE}/${selectedImage}`} className='w-100' alt="images" /> :
                                            <>
                                                {
                                                    formik.values.image ?
                                                        <img src={`${formik.values.image}`} className='w-100' alt="images" loading='lazy' /> :
                                                        <img src={img.personal} className='w-100' alt="images" loading='lazy' />
                                                }
                                            </>
                                        }
                                    </>
                            }


                            <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                                <input
                                    type="file"
                                    id="file-input"
                                    accept="image/*"
                                    onChange={handleImageSelect}
                                    style={{ display: 'none' }}
                                />
                                <div className="ChangeImage absolute">
                                    <label htmlFor="file-input" className="btn__porfile flex justify-content-center align-items-center" style={{ pointerEvents: 'all' }}>
                                        <Icons.Camera />
                                        {/* <i className='pi pi-image text-light color-wight'></i> */}
                                    </label>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="Edite">
                        <Link to={'/account/personal_data'}>
                            <Button icon='pi pi-cog' label={isLang==="en"?'Personal page':'  صفحه الشخصيه '} />
                        </Link>

                    </div>
                </div>
                <div className="info_edit mt-50">

                    <div className="grid">
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <label htmlFor="firstName" className="font-bold block mb-2">    {isLang === "en" ? ' First Name' : '  الاسم الأول  '} </label>
                            <InputText
                                id="firstName"
                                name="firstName"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="w-full p-inputtext-sm"
                                dir={isLang === "en" ? 'ltr' : 'rtl'}
                                value={formik.values?.firstName}
                            />
                            {formik.errors?.firstName && formik.touched?.firstName ?
                                <Message severity="error" text={formik.errors?.firstName} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col" >
                            <label htmlFor="lastName" className="font-bold block mb-2">{isLang === "en" ? ' Last Name' : ' اسم العائلة   '}  </label>
                            <InputText
                                id="ؤ"
                                name="lastName"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="w-full p-inputtext-sm"
                                dir={isLang === "en" ? 'ltr' : 'rtl'}
                                value={formik.values.lastName}

                            />
                            {formik.errors.lastName && formik.touched.lastName ?
                                <Message severity="error" text={formik.errors.lastName} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <label htmlFor="mobile" className="font-bold block mb-2">{isLang === "en" ? ' Mobile Number' : '  رقم الجوال '}   </label>
                            <InputText
                                id="mobile"
                                name="mobile"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="w-full p-inputtext-sm"
                                dir='ltr'
                                value={formik.values.mobile}
                            />
                            {formik.errors.mobile && formik.touched.mobile ?
                                <Message severity="error" text={formik.errors.mobile} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <label htmlFor="email" className="font-bold block mb-2"> {isLang === "en" ? ' Email' : '  البريد الالكترونى   '}    </label>
                            <InputText
                                id="email"
                                name="email"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="w-full p-inputtext-sm"
                                dir={isLang === "en" ? 'ltr' : 'rtl'}

                                value={formik.values.email}
                            />
                            {formik.errors.email && formik.touched.email ?
                                <Message severity="error" text={formik.errors.email} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col" >
                            <label htmlFor="title_ar" className="font-bold block mb-2"> {isLang === "en" ? ' Nationality' : '   الجنسية'}    </label>
                            <Dropdown
                                filter
                                id="nationality"
                                name="nationality"
                                value={formik.values.nationality}
                                options={nationality}
                                optionLabel="name"
                                onChange={(e) => formik.setFieldValue("nationality", e.value)}
                                onBlur={formik.handleBlur}
                                optionValue="name"
                                placeholder={isLang === "en" ? 'Select your nationality' : ' اختر جنسيتك   '}
                                className="w-full p-inputtext-sm"
                                disabled
                                
                            />
                            {formik.errors.nationality && formik.touched.nationality ?
                                <Message severity="error" text={formik.errors.nationality} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <label htmlFor="gender" className="font-bold block mb-2">
                                {isLang === "en" ? 'Gender  ' : '  النوع '}
                            </label>
                            <Dropdown
                                id="gender"
                                name="gender"
                                value={formik.values.gender}
                                options={gender}
                                optionLabel="name"
                                onChange={(e) => formik.setFieldValue("gender", e.value)}
                                onBlur={formik.handleBlur}
                                placeholder={isLang === "en" ? 'Select a gender' : 'اختر النوع'}
                                className="w-full p-inputtext-sm"

                            />
                            {formik.errors.gender && formik.touched.gender ?
                                <Message severity="error" text={formik.errors.gender} /> : null
                            }
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col" >
                            <label htmlFor="cityId" className="font-bold block mb-2">{isLang === "en" ? 'City ' : '  المدينة '} </label>
                            <Dropdown
                                filter
                                id="cityId"
                                name="cityId"
                                value={formik.values.cityId}
                                options={fetchCities.map(city => ({
                                    name: isLang === 'en' ? city.name : city.arabic_name,
                                    value: city.id,
                                }))}
                                optionLabel="name"
                                onChange={(e) => formik.setFieldValue("cityId", e.value)}
                                onBlur={formik.handleBlur}
                                placeholder={isLang === "en" ? 'Select a cityId' : 'اختر النوع'}
                                className="w-full p-inputtext-sm"
                            />
                            {formik.errors.cityId && formik.touched.cityId ?
                                <Message severity="error" text={formik.errors.cityId} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <label htmlFor="address" className="font-bold block mb-2">{isLang === "en" ? ' Address' : ' العنوان  '}    </label>
                            <InputText
                                id="address"
                                name="address"
                                type="text"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="w-full p-inputtext-sm"
                                dir={isLang === "en" ? 'ltr' : 'rtl'}

                                value={formik.values.address}

                            />
                            {formik.errors.address && formik.touched.address ?
                                <Message severity="error" text={formik.errors.address} /> : null
                            }
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col ">
                            <div className="flex flex-column gap-2 mt-2 submit">
                                <Button severity='warning' label={isLang === "en" ? 'Save Changes' : 'حفظ التغيرات '} size='small' className=' w-full' />
                            </div>
                        </div>
                    </div>


                </div>
            </form>
            <Component.ChangePasword
                isLang={isLang}
                toast={toast}
            />
        </div>
    )
}

export default EditAccount
