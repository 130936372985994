import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Component from '../../../constants/Component';
import img from '../../../constants/Img';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import './style.scss';
import { Helmet } from 'react-helmet-async';

const AboutUS = () => {
    let { isLang } = useContext(LocalizationContext);
    let { aoutUsData, seo,
        fetchSEO } = useContext(SharedData);
    useEffect(() => {
        window.scrollBy(0, -window.pageYOffset);
        fetchSEO('ABOUT')
    }, [])
    return (
        <>
            <Helmet >
                <meta name="title" content={seo?.title} />
                <meta name="description" content={seo?.desc} />
                <meta name="keywords" content={seo?.keywords} />
             </Helmet>
            <div className='app__aboutus'>
                <div className="app__connect" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                    <img src={img.BgAbout} alt="images" loading='lazy' />
                    <div className={`content ${isLang === "en" ? 'contentEn' : 'contentAr'}`}>
                        <Container fluid>
                            <Component.Title title={isLang === "en" ? aoutUsData?.title : aoutUsData?.arabic_title} />
                            <div className="desc">
                                <Row>
                                    <Col xl={7} lg={7} md={12} sm={12}>
                                        <p>{isLang === "en" ? aoutUsData?.text : aoutUsData?.arabic_text}</p>
                                        {/* <p>انطلاقا من المبادئ الأساسية التي بنيت عليها شركة الغزال لتقدم خدماتها المتميزة بمجال <span>تأجير السيارات  </span> وعليه فأنه يسرنا ان نضع امامكم هذا الملف التعريفي الذي يلقى الضوء على مجموعة شركاتنا حيث استطاعت الشركة وخلال فترة قياسيه من تحقيق نتائج أعمال تجاوزت الخطط المستهدفة وذلك بفضل الله ثم بثقة عملائنا وقدرتنا على تحقيق أعلى مستويات رضا العملاء من خلال ما وفرته الشركة من <span>   حلول تسويقية</span> <span>    للتأجير</span> و <span>    كادر مؤهل</span> من موظفي التأجير ليساعدكم لكي تحصل على أفضل برامج ل<>تأجير السيارات وعليه</>. </p>
                                    <p>ومن اهداف المجموعة توسيع الشراكة مع الشركات الوطنية والعالمية لنوفر لهم منتجات تأجيريه تلبي احتياجاتهم.</p>
                                    <p>وتعمل الشركة على تفعيل الإدارة الفعالة من خلال أعلى درجات التواصل بين موظفي الشركة بهدف الارتقاء بمستوى الخدمات لتحقيق اعلى درجات رضا العملاء. </p>
                                    <p>ولا يسعنا الا ان نشكر كل من ساهم في انجاح هذا الصرح وخاصه فريق العمل الذي يلتزم اعلى درجات الحرفية والاخلاص والالتزام وكذلك عملاؤنا الذين كانت لثقتهم بنا دور كبير في تصميمنا على مواصلة التميز نحو عام جديد من الإنجازات التي تعود بالخير على بلدنا وعملاؤنا ومساهمينا وموظفينا</p> */}
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUS;
