import { Galleria } from 'primereact/galleria';
import React, { useContext } from 'react';
import { LocalizationContext } from '../../../context/LangChange';
import './Slider.scss';
 
const Slider = ({banners}) => { 
    let { isLang } = useContext(LocalizationContext);

    const itemTemplate = (item) => {
        return (
            <div className='image__container'>
                <img src={item.image} alt={item.alt} className='image__slider' loading='lazy'/>
                <div className="overlay"></div>
            </div>
        );
    };


    return (
        <div style={{ width: '100%'  }}>
            <Galleria value={banners} showThumbnails={false}
                className="image-slider-indicators"
                showIndicatorsOnItem={true}
                circular autoPlay transitionInterval={2000} 
                showIndicators item={itemTemplate} />
        </div>
    );
}

export default Slider;
