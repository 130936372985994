import React, { useContext } from 'react'
import { LocalizationContext } from '../../../context/LangChange';

const Nationalities = () => {

    let { isLang } = useContext(LocalizationContext);
    const nationality = [
        { name: isLang === "en" ? 'Saudi' : 'سعودي', value: 0 },
        { name: isLang === "en" ? 'Egyptian' : 'مصري', value: 1 },
        { name: isLang === "en" ? 'Emirian' : 'إماراتي', value: 2 },
        { name: isLang === "en" ? 'Qatari' : 'قطري', value: 3 },
        { name: isLang === "en" ? 'Kuwaiti' : 'كويتي', value: 4 },
        { name: isLang === "en" ? 'Bahraini' : 'بحريني', value: 5 },
        { name: isLang === "en" ? 'Omani' : 'عماني', value: 6 },
        { name: isLang === "en" ? 'Yemenite' : 'يمني', value: 7 },
        { name: isLang === "en" ? 'Iraqi' : 'عراقي', value: 8 },
        { name: isLang === "en" ? 'Syrian' : 'سوري', value: 9 },
        { name: isLang === "en" ? 'Lebanese' : 'لبناني', value: 10 },
        { name: isLang === "en" ? 'Tunisian' : 'تونسي', value: 11 },
        { name: isLang === "en" ? 'Algerian' : 'جزائري', value: 12 },
        { name: isLang === "en" ? 'Jordanian' : 'أردني', value: 13 },
        { name: isLang === "en" ? 'Palestinian' : 'فلسطيني', value: 14 },
        { name: isLang === "en" ? 'Moroccan' : 'مغربي', value: 15 },
        { name: isLang === "en" ? 'Sudanese' : 'سوداني', value: 16 },
        { name: isLang === "en" ? 'Libyan' : 'ليبي', value: 17 },
        { name: isLang === "en" ? 'Somali' : 'صومالي', value: 18 },
        { name: isLang === "en" ? 'Turkish' : 'تركي', value: 19 },
        { name: isLang === "en" ? 'Afghan' : 'أفغاني', value: 20 },
        { name: isLang === "en" ? 'Albanian' : 'ألباني', value: 23 },
        { name: isLang === "en" ? 'Algerian' : 'جزائري', value: 24 },
        { name: isLang === "en" ? 'American' : 'أمريكي', value: 25 },
        { name: isLang === "en" ? 'Andorran' : 'أندوري', value: 26 },
        { name: isLang === "en" ? 'Angolan' : 'أنغولي', value: 27 },
        { name: isLang === "en" ? 'Antiguans' : 'انتيغوا', value: 28 },
        { name: isLang === "en" ? 'Argentinean' : 'أرجنتيني', value: 29 },
        { name: isLang === "en" ? 'Armenian' : 'أرميني', value: 30 },
        { name: isLang === "en" ? 'Australian' : 'أسترالي', value: 31 },
        { name: isLang === "en" ? 'Austrian' : 'نمساوي', value: 32 },
        { name: isLang === "en" ? 'Azerbaijani' : 'أذربيجاني', value: 33 },
        { name: isLang === "en" ? 'Bahamian' : 'باهامى', value: 34 },
        { name: isLang === "en" ? 'Bahraini' : 'بحريني', value: 35 },
        { name: isLang === "en" ? 'Bangladeshi' : 'بنجلاديشي', value: 36 },
        { name: isLang === "en" ? 'Barbadian' : 'باربادوسي', value: 37 },
        { name: isLang === "en" ? 'Barbudans' : 'بربودا', value: 38 },
        { name: isLang === "en" ? 'Batswana' : 'بوتسواني', value: 39 },
        { name: isLang === "en" ? 'Belarusian' : 'بيلاروسي', value: 40 },
        { name: isLang === "en" ? 'Belgian' : 'بلجيكي', value: 41 },
        { name: isLang === "en" ? 'Belizean' : 'بليزي', value: 42 },
        { name: isLang === "en" ? 'Beninese' : 'بنيني', value: 43 },
        { name: isLang === "en" ? 'Bhutanese' : 'بوتاني', value: 44 },
        { name: isLang === "en" ? 'Bolivian' : 'بوليفي', value: 45 },
        { name: isLang === "en" ? 'Bosnian' : 'بوسني', value: 46 },
        { name: isLang === "en" ? 'Brazilian' : 'برازيلي', value: 47 },
        { name: isLang === "en" ? 'British' : 'بريطاني', value: 48 },
        { name: isLang === "en" ? 'Bruneian' : 'بروناى', value: 49 },
        { name: isLang === "en" ? 'Bulgarian' : 'بلغاري', value: 50 },
        { name: isLang === "en" ? 'Burkinabe' : 'بوركيني', value: 51 },
        { name: isLang === "en" ? 'Burmese' : 'بورمي', value: 52 },
        { name: isLang === "en" ? 'Burundian' : 'بوروندي', value: 53 },
        { name: isLang === "en" ? 'Cambodian' : 'كمبودي', value: 54 },
        { name: isLang === "en" ? 'Cameroonian' : 'كاميروني', value: 55 },
        { name: isLang === "en" ? 'Canadian' : 'كندي', value: 56 },
        { name: isLang === "en" ? "Cape Verdean" : "االرأس الأخضر", value: 57 },
        { name: isLang === "en" ? "Central African" : "وسط أفريقيا", value: 58 },
        { name: isLang === "en" ? "Chadian" : "تشادي", value: 59 },
        { name: isLang === "en" ? "Chilean" : "شيلي", value: 60 },
        { name: isLang === "en" ? "Chinese" : "صينى", value: 61 },
        { name: isLang === "en" ? "Colombian" : "كولومبي", value: 62 },
        { name: isLang === "en" ? "Comoran" : "جزر القمر", value: 63 },
        { name: isLang === "en" ? "Congolese" : "كونغولي", value: 64 },
        { name: isLang === "en" ? "Costa Rican" : "كوستاريكي", value: 65 },
        { name: isLang === "en" ? "Croatian" : "كرواتية", value: 66 },
        { name: isLang === "en" ? "Cuban" : "كوبي", value: 67 },
        { name: isLang === "en" ? "Cypriot" : "قبرصي", value: 68 },
        { name: isLang === "en" ? "Czech" : "تشيكي", value: 69 },
        { name: isLang === "en" ? "Danish" : "دانماركي", value: 70 },
        { name: isLang === "en" ? "Djibouti" : "جيبوتي", value: 71 },
        { name: isLang === "en" ? "Dominican" : "دومينيكاني", value: 72 },
        { name: isLang === "en" ? "Dutch" : "هولندي", value: 73 },
        { name: isLang === "en" ? "East Timorese" : "تيموري شرقي", value: 74 },
        { name: isLang === "en" ? "Ecuadorean" : "اكوادوري", value: 75 },
        { name: isLang === "en" ? "Equatorial Guinean" : "غيني  استوائي", value: 76 },
        { name: isLang === "en" ? "Eritrean" : "إريتري", value: 77 },
        { name: isLang === "en" ? "Estonian" : "إستوني", value: 78 },
        { name: isLang === "en" ? "Ethiopian" : "حبشي", value: 79 },
        { name: isLang === "en" ? "Fijian" : "فيجي", value: 80 },
        { name: isLang === "en" ? "Filipino" : "فلبيني", value: 81 },
        { name: isLang === "en" ? "Finnish" : "فنلندي", value: 82 },
        { name: isLang === "en" ? "French" : "فرنسي", value: 83 },
        { name: isLang === "en" ? "Gabonese" : "جابوني", value: 84 },
        { name: isLang === "en" ? "Gambian" : "غامبيي", value: 85 },
        { name: isLang === "en" ? "Georgian" : "جورجي", value: 86 },
        { name: isLang === "en" ? "German" : "ألماني", value: 87 },
        { name: isLang === "en" ? "Ghanaian" : "غاني", value: 88 },
        { name: isLang === "en" ? 'Kenyan' : 'كيني', value: 89 },
        { name: isLang === "en" ? 'Greek' : 'إغريقي', value: 90 },
        { name: isLang === "en" ? 'Grenadian' : 'جرينادي', value: 91 },
        { name: isLang === "en" ? 'Guatemalan' : 'غواتيمالي', value: 92 },
        { name: isLang === "en" ? 'Guinea_Bissauan' : "غيني بيساوي", value: 93 },
        { name: isLang === "en" ? 'Guinean' : 'غيني', value: 94 },
        { name: isLang === "en" ? 'Guyanese' : 'جوياني', value: 95 },
        { name: isLang === "en" ? 'Haitian' : 'هايتي', value: 96 },
        { name: isLang === "en" ? 'Herzegovinian' : 'هرسكي', value: 97 },
        { name: isLang === "en" ? 'Honduran' : 'هندوراسي', value: 98 },
        { name: isLang === "en" ? 'Hungarian' : 'هنغاري', value: 99 },
        { name: isLang === "en" ? 'Icelander' : 'إيسلندي', value: 100 },
        { name: isLang === "en" ? 'Indian' : 'هندي', value: 101 },
        { name: isLang === "en" ? 'Indonesian' : 'إندونيسي', value: 102 },
        { name: isLang === "en" ? 'Iranian' : 'إيراني', value: 103 },
        { name: isLang === "en" ? 'Irish' : 'إيرلندي', value: 104 },
        { name: isLang === "en" ? 'Italian' : 'إيطالي', value: 105 },
        { name: isLang === "en" ? 'Ivorian' : 'إفواري', value: 106 },
        { name: isLang === "en" ? 'Jamaican' : 'جامايكي', value: 107 },
        { name: isLang === "en" ? 'Japanese' : 'ياباني', value: 108 },
        { name: isLang === "en" ? 'Jordanian' : 'أردني', value: 109 },
        { name: isLang === "en" ? 'Kazakhstani' : 'كازاخستاني', value: 110 },
        { name: isLang === "en" ? 'Kittian and Nevisian' : 'كيتياني ونيفيسياني', value: 111 },
        { name: isLang === "en" ? 'Kyrgyz' : 'قيرغيزستان', value: 112 },
        { name: isLang === "en" ? 'Laotian' : 'لاوسي', value: 113 },
        { name: isLang === "en" ? 'Latvian' : 'لاتفي', value: 114 },
        { name: isLang === "en" ? 'Liberian' : 'ليبيري', value: 115 },
        { name: isLang === "en" ? 'Libyan' : 'ليبي', value: 116 },
        { name: isLang === "en" ? 'Liechtensteiner' : 'ليختنشتايني', value: 117 },
        { name: isLang === "en" ? 'Lithuanian' : 'لتواني', value: 118 },
        { name: isLang === "en" ? 'Luxembourger' : 'لكسمبرغي', value: 119 },
        { name: isLang === "en" ? 'Macedonian' : 'مقدوني', value: 120 },
        { name: isLang === "en" ? 'Malagasy' : 'مدغشقري', value: 121 },
        { name: isLang === "en" ? 'Malawian' : 'مالاوى', value: 122 },
        { name: isLang === "en" ? 'Malaysian' : 'ماليزي', value: 123 },
        { name: isLang === "en" ? 'Maldivan' : 'مالديفي', value: 124 },
        { name: isLang === "en" ? 'Malian' : 'مالي', value: 125 },
        { name: isLang === "en" ? 'Maltese' : 'مالطي', value: 126 },
        { name: isLang === "en" ? 'Marshallese' : 'مارشالي', value: 127 },
        { name: isLang === "en" ? 'Mauritanian' : 'موريتاني', value: 128 },
        { name: isLang === "en" ? 'Mauritian' : 'موريشيوسي', value: 129 },
        { name: isLang === "en" ? 'Mexican' : 'مكسيكي', value: 130 },
        { name: isLang === "en" ? 'Micronesian' : 'ميكرونيزي', value: 131 },
        { name: isLang === "en" ? 'Moldovan' : 'مولدوفي', value: 132 },
        { name: isLang === "en" ? 'Monacan' : 'موناكو', value: 133 },
        { name: isLang === "en" ? 'Mongolian' : 'منغولي', value: 134 },
        { name: isLang === "en" ? 'Moroccan' : 'مغربي', value: 135 },
        { name: isLang === "en" ? 'Mosotho' : 'ليسوتو', value: 136 },
        { name: isLang === "en" ? 'Motswana' : 'لتسواني', value: 137 },
        { name: isLang === "en" ? 'Mozambican' : 'موزمبيقي', value: 138 },
        { name: isLang === "en" ? 'Namibian' : 'ناميبي', value: 139 },
        { name: isLang === "en" ? 'Nauruan' : 'ناورو', value: 140 },
        { name: isLang === "en" ? 'Nepalese' : 'نيبالي', value: 141 },
        { name: isLang === "en" ? 'New Zealander' : 'نيوزيلندي', value: 142 },
        { name: isLang === "en" ? 'Ni-Vanuatu' : 'ني فانواتي', value: 143 },
        { name: isLang === "en" ? 'Nicaraguan' : 'نيكاراغوا', value: 144 },
        { name: isLang === "en" ? 'Nigerien' : 'نيجري', value: 145 },
        { name: isLang === "en" ? 'North Korean' : 'كوري شمالي', value: 146 },
        { name: isLang === "en" ? 'Northern Irish' : 'إيرلندي شمالي', value: 147 },
        { name: isLang === "en" ? 'Norwegian' : 'نرويجي', value: 148 },
        { name: isLang === "en" ? 'Pakistani' : 'باكستاني', value: 149 },
        { name: isLang === "en" ? 'Palauan' : 'بالاوي', value: 150 },
        { name: isLang === "en" ? 'Palestinian' : 'فلسطيني', value: 151 },
        { name: isLang === "en" ? 'Panamanian' : 'بنمي', value: 152 },
        { name: isLang === "en" ? "Papua New Guinean" : "بابوا غينيا الجديدة", value: 153 },
        { name: isLang === "en" ? 'Paraguayan' : 'باراغواياني', value: 154 },
        { name: isLang === "en" ? 'Peruvian' : 'بيروفي', value: 155 },
        { name: isLang === "en" ? 'Polish' : 'بولندي', value: 156 },
        { name: isLang === "en" ? 'Portuguese' : 'برتغالي', value: 157 },
        { name: isLang === "en" ? 'Romanian' : 'روماني', value: 158 },
        { name: isLang === "en" ? 'Russian' : 'روسي', value: 159 },
        { name: isLang === "en" ? 'Rwandan' : 'رواندي', value: 160 },
        { name: isLang === "en" ? 'Saint Lucian' : 'لوسياني', value: 151 },
        { name: isLang === "en" ? 'Salvadoran' : 'سلفادوري', value: 152 },
        { name: isLang === "en" ? 'Samoan' : 'ساموايان', value: 153 },
        { name: isLang === "en" ? 'Scottish' : 'سكتلندي', value: 154 },
        { name: isLang === "en" ? 'Senegalese' : 'سنغالي', value: 155 },
        { name: isLang === "en" ? 'Serbian' : 'صربي', value: 156 },
        { name: isLang === "en" ? 'Seychellois' : 'سيشلي', value: 157 },
        { name: isLang === "en" ? 'Singaporean' : 'سنغافوري', value: 158 },
        { name: isLang === "en" ? 'Slovakian' : 'سلوفاكي', value: 159 },
        { name: isLang === "en" ? 'Slovenian' : 'سلوفيني', value: 160 },
        { name: isLang === "en" ? 'Somali' : 'صومالي', value: 161 },
        { name: isLang === "en" ? 'Spanish' : 'إسباني', value: 162 },
        { name: isLang === "en" ? 'Sudanese' : 'سوداني', value: 163 },
        { name: isLang === "en" ? 'Surinamer' : 'سورينامي', value: 164 },
        { name: isLang === "en" ? 'Swazi' : 'سوازي', value: 165 },
        { name: isLang === "en" ? 'Swedish' : 'سويدي', value: 166 },
        { name: isLang === "en" ? 'Swiss' : 'سويسري', value: 167 },
        { name: isLang === "en" ? 'San Marinese' : 'سان مارينيز', value: 168 },
        { name: isLang === "en" ? 'Sao Tomean' : 'ساو توميان', value: 169 },
        { name: isLang === "en" ? 'Sierra Leonean' : 'سيرا ليوني', value: 170 },
        { name: isLang === "en" ? 'Solomon Islander' : 'جزر سليمان', value: 171 },
        { name: isLang === "en" ? 'South African' : 'جنوب افريقيي', value: 172 },
        { name: isLang === "en" ? 'South Korean' : 'كوري جنوبي', value: 173 },
        { name: isLang === "en" ? 'Sri Lankan' : 'سري لانكي', value: 174 },
        { name: isLang === "en" ? 'Taiwanese' : 'تايواني', value: 175 },
        { name: isLang === "en" ? 'Tajik' : 'طاجيكي', value: 176 },
        { name: isLang === "en" ? 'Tanzanian' : 'تنزاني', value: 177 },
        { name: isLang === "en" ? 'Thai' : 'التايلاندي', value: 178 },
        { name: isLang === "en" ? 'Togolese' : 'توغواني', value: 179 },
        { name: isLang === "en" ? 'Tongan' : 'تونجاني', value: 180 },
        { name: isLang === "en" ? 'Trinidadian or Tobagonian' : 'ترينيدادي أو توباغوني', value: 181 },
        { name: isLang === "en" ? 'Tuvaluan' : 'توفالي', value: 182 },
        { name: isLang === "en" ? 'Ugandan' : 'أوغندي', value: 183 },
        { name: isLang === "en" ? 'Ukrainian' : 'أوكراني', value: 184 },
        { name: isLang === "en" ? 'Uruguayan' : 'أوروجواي', value: 185 },
        { name: isLang === "en" ? 'Uzbekistani' : 'أوزبكستاني', value: 186 },
        { name: isLang === "en" ? 'Venezuelan' : 'فنزويلي', value: 187 },
        { name: isLang === "en" ? 'Vietnamese' : 'فيتنامي', value: 188 },
        { name: isLang === "en" ? 'Welsh' : 'ويلزي', value: 189 },
        { name: isLang === "en" ? 'Zambian' : 'زامبي', value: 190 },
        { name: isLang === "en" ? 'Zimbabwean' : 'زيمبابوي', value: 191 },

    ];
    return nationality
}

export default Nationalities


