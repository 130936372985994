import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import img from '../../../../constants/Img';
import { LocalizationContext } from '../../../../context/LangChange';
import './style.scss';
import { SharedData } from '../../../../context/CallApi';
const DownloadAppNow = () => {
    let { isLang } = useContext(LocalizationContext);
    let { contactsData } = useContext(SharedData);

    return (
        <div className='app__download pt-50 pb-50'>
            <Container className='mt-5'>
                <Row dir={isLang === "en" ? 'rtl' : 'ltr'}>
                    <Col xl={6} lg={6} md={12} sm={12} className='app__mabile'>
                        <img src={img.MobileApp} alt="images" loading='lazy' />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='app__mabile_sm'>
                        <img src={img.MobileApp} alt="images" />
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} className='app__mabile-content' dir={isLang === "en" ? 'ltr' : 'rtl'}>

                        <h2>  {isLang === "en" ? 'Download the application now' : 'حمل التطبيق الآن'}   </h2>
                        <p>{isLang === "en" ? 'Al Ghazal mobile application, start your unique trip with our special cars rental service.' : 'تطبيق الغزال للهاتف المحمول، ابدأ رحلتك الفريدة مع خدمة تأجير السيارات المميزة لدينا.'}</p>
                        <div className="btn__group mt-4">
                            <a href={contactsData?.android} target="_blank" rel="noopener noreferrer">
                                <img src={img.Googleplay} alt="images" loading='lazy' />
                            </a>
                            <a href={contactsData?.ios} target="_blank" rel="noopener noreferrer">
                                <img src={img.appstore3} alt="images" loading='lazy' />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DownloadAppNow
