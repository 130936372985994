import { useNavigate, useLocation } from 'react-router-dom';
import { LocalizationContext } from '../context/LangChange';
import { useContext } from 'react';

function useLocalizedNavigate() {
    const navigate = useNavigate();
    const location = useLocation();
    let { isLang } = useContext(LocalizationContext);

    function localizedNavigate(to, options) {
        // إضافة بادئة اللغة للمسار إذا لم تكن موجودة
        const path = to.startsWith(`/${isLang}`) ? `/${isLang}${to}` : `/${isLang}${to}`;
        navigate(path, options);
    }

    return localizedNavigate;
}

export default useLocalizedNavigate;
