import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../constants/Icons';
import { LocalizationContext } from '../../context/LangChange';
import './style.scss';
import { runFireworks } from './utils';
const Thanks = () => {
  let { isLang } = useContext(LocalizationContext);
  // let { invoice } = useContext(CarFilterStates);

  let { type } = useParams()
  useEffect(() => {
    runFireworks();
  }, []);

  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);;
  }, []);

  let { id } = useParams()
  const [invoice, setDetailsData] = useState([]);

  const fetchCarsDetails = async () => {
    setDetailsData([])
    const url = `${process.env.REACT_APP_API_URL}/resdetail?resId=${id}`;
    let { data } = await axios.get(url)
    setDetailsData(data);
  }

  const fetchLimDetails = async () => {
    setDetailsData([])
    const url = `${process.env.REACT_APP_API_URL}/limdetail?resId=${id}`;
    let { data } = await axios.get(url)
    setDetailsData(data);
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const findCarTypeParam = urlSearchParams.get('lim');

  useEffect(() => {
    if (type !== 'sign-up') {
      findCarTypeParam == 0 ? fetchCarsDetails() : fetchLimDetails()
    }
    window.scrollBy(0, -window.pageYOffset);
  }, [id, findCarTypeParam])
  return (
    <div className="success-wrapper mb-8">
      {
        type !== 'sign-up' &&
        <>
          {
            findCarTypeParam == 0 ?
              <div className={` ${isLang === "en" ? 'success order_success' : 'success  '} `}>
                <p className="icon">
                  <Icons.Bags />
                </p>
                <h2>{isLang === "en" ? 'Congratulations and we wish you an enjoyable trip  ' : ' تهانينا ونتمني لك رحلة ممتعة'}</h2>
                {/* <p className="email-msg">Check your email inbox for the receipt.</p> */}
                {
                  invoice &&
                  <div className="invoice w-75" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                    {invoice.name &&
                      <div className="invoice-item flex gap-3">
                        <strong>{isLang === "en" ? 'Car Name:' : 'اسم السيارة : '}</strong>
                        <span className=''>{invoice.name}</span>
                      </div>
                    }
                    {invoice.res_number &&
                      <div className="invoice-item flex gap-3">
                        <strong>{isLang === "en" ? 'Order number :' : '  رقم الطلب : '}</strong>
                        <span>{invoice.res_number}</span>
                      </div>
                    }


                    {
                      invoice.lim === 0 ?
                        <div className="invoice-item flex gap-3">
                          <strong>{isLang === "en" ? 'Car Price :' : ' سعر السيارة : '}</strong>
                          <span>{parseFloat(invoice.price)?.toFixed(2)} {' '} {isLang === 'en' ? 'SAR' : 'ريال'}</span>
                        </div> : <div className="invoice-item flex gap-3">
                          <strong>{isLang === "en" ? 'Car Price :' : ' سعر السيارة : '}</strong>
                          <span> {parseFloat(invoice.lim_price)?.toFixed(2)} {' '} {isLang === 'en' ? 'SAR' : 'ريال'}</span>
                        </div>
                    }

                    <div className="invoice-item flex gap-3">
                      <strong>{isLang === "en" ? 'Total Price :' : 'إجمالي المبلغ : '}</strong>
                      <span>{parseFloat(invoice.total)?.toFixed(2)} {' '} {isLang === 'en' ? 'SAR' : 'ريال'}</span>
                    </div>


                    {invoice.start_date &&
                      <div className="invoice-item flex gap-3">
                        <strong>{isLang === "en" ? 'Start Date :' : '  بداية الحجز : '}</strong>
                        <span>{invoice.start_date}</span>
                      </div>
                    }
                    {invoice.end_date &&
                      <div className="invoice-item flex gap-3">
                        <strong>{isLang === "en" ? 'End Date :' : '  نهاية الحجز : '}</strong>
                        <span>{invoice.end_date}</span>
                      </div>
                    }
                  </div>
                }
                <p className="description" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                  {isLang === "en" ? 'If you have any questions, please email' : '  اذا كان لديك أى أسئلة من فضلك راسلنى على البريد الإلكترونى'}
                  <a className="email" href="mailto:rent@alghazal.sa">
                    rent@alghazal.sa
                  </a>
                  <br />

                </p>
                <Link to={`/${isLang}/account/manage`}>
                  <button type="button" width="300px" className="success_btn px-5">
                    {isLang == "en" ? 'Manage My booking' : 'إدارة حجوزاتى '}
                  </button>
                </Link>
              </div> :
              <div className={` ${isLang === "en" ? 'success order_success' : 'success  '} `}>
                <p className="icon">
                  <Icons.Bags />
                </p>
                <h2>{isLang === "en" ? 'Congratulations and we wish you an enjoyable trip  ' : ' تهانينا ونتمني لك رحلة ممتعة'}</h2>
                {/* <p className="email-msg">Check your email inbox for the receipt.</p> */}
                {
                  invoice &&
                  <div className="invoice w-75" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                    {invoice.name &&
                      <div className="invoice-item flex gap-3">
                        <strong>{isLang === "en" ? 'Car Name:' : 'اسم السيارة : '}</strong>
                        <span className=''>{invoice.name}</span>
                      </div>
                    }
                    {invoice.res_number &&
                      <div className="invoice-item flex gap-3">
                        <strong>{isLang === "en" ? 'Order number :' : '  رقم الطلب : '}</strong>
                        <span>{invoice.res_number}</span>
                      </div>
                    }


                    {
                      invoice.lim === 0 ?
                        <div className="invoice-item flex gap-3">
                          <strong>{isLang === "en" ? 'Car Price :' : ' سعر السيارة : '}</strong>
                          <span>{parseFloat(invoice.price)?.toFixed(2)} {' '} {isLang === 'en' ? 'SAR' : 'ريال'}</span>
                        </div> : <div className="invoice-item flex gap-3">
                          <strong>{isLang === "en" ? 'Car Price :' : ' سعر السيارة : '}</strong>
                          <span> {parseFloat(invoice.lim_price)?.toFixed(2)} {' '} {isLang === 'en' ? 'SAR' : 'ريال'}</span>
                        </div>
                    }

                    <div className="invoice-item flex gap-3">
                      <strong>{isLang === "en" ? 'Total Price :' : 'إجمالي المبلغ : '}</strong>
                      <span>{parseFloat(invoice.total)?.toFixed(2)} {' '} {isLang === 'en' ? 'SAR' : 'ريال'}</span>
                    </div>


                    {invoice.start_date &&
                      <div className="invoice-item flex gap-3">
                        <strong>{isLang === "en" ? 'Start Date :' : '  بداية الحجز : '}</strong>
                        <span>{invoice.start_date}</span>
                      </div>
                    }
                    {invoice.end_date &&
                      <div className="invoice-item flex gap-3">
                        <strong>{isLang === "en" ? 'End Date :' : '  نهاية الحجز : '}</strong>
                        <span>{invoice.end_date}</span>
                      </div>
                    }
                  </div>
                }
                <p className="description" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                  {isLang === "en" ? 'If you have any questions, please email' : '  اذا كان لديك أى أسئلة من فضلك راسلنى على البريد الإلكترونى'}
                  <a className="email" href="mailto:rent@alghazal.sa">
                    rent@alghazal.sa
                  </a>
                  <br />

                </p>
                <Link to={`/${isLang}/account/manage`}>
                  <button type="button" width="300px" className="success_btn px-5">
                    {isLang == "en" ? 'Manage My booking' : 'إدارة حجوزاتى '}
                  </button>
                </Link>
              </div>

          }
        </>

      }

      {
        type === 'sign-up' &&
        <div className='d-flex sh justify-content-center align-items-center   success_point'>
          <Icons.thanksPages />
          <Link to="/cars">
            <button type="button" width="300px" className="success_btn px-5">
              {isLang === "en" ? 'Cars' : 'السيارت المعروضة'}
            </button>
          </Link>
        </div>
        /*   <div className="success"  >
            <p className="icon">
  
            </p>
            <h2>{isLang === "en" ? 'Thank you for  registering!' : '  !شكرا لك على التسجيل  '}</h2>
            <p className="email-msg">{isLang === "en" ? 'Check your email inbox  to activate the account.' : 'تحقق من صندوق البريد الإلكتروني الخاص بك لتفعيل الحساب.'}</p>
            <p className="description" dir={isLang === "en" ? 'ltr' : 'rtl'}>
              {isLang === "en" ? 'If you have any questions, please email' : '  اذا كان لديك أى أسئلة من فضلك راسلنى على البريد الإلكترونى'}
              {' '}
              <a className="email" href="mailto:rent@alghazal.sa">
                rent@alghazal.sa
              </a>
  
            </p>
            <Link to="/auth/sign-in">
              <button type="button" width="300px" className="success_btn px-5">
                {isLang === "en" ? ' sign In now' : 'قم بتسجيل دخولك الآن'}
              </button>
            </Link>
          </div> */
      }


    </div>
  )
}

export default Thanks