import React, { createContext, useState } from 'react';

export const CarFilterStates = createContext([])

function FiltersCars({ children }) {

    const [storeBrancheTo, setStoreBrancheTo] = useState(null);
    const [storeBrancheFrom, setStoreBrancheForm] = useState('');
    const [storeBranche, setStoreBranches] = useState(null);
    const [storeBrand, setStoreBrand] = useState(null);
    const [storeStartDate, setStoreStartDate] = useState(null);
    const [clacDateLim, setClacDateLim] = useState(null);
    const [storeEndDate, setStoreEndDate] = useState(null);
    const [storeTime, setStoreTime] = useState(null);
    const [checked, setChecked] = useState(false);
    const [typeIdCar, setTypeIdCar] = useState(null);
    const [activeBtn, setActiveBtn] = useState('btn1');
    const [invoice, setInvoice] = useState('btn1');
    const [booking, setBooking] = useState({});
    const [userLocationMap, setLoctionMap] = useState(null);

    return (
        <CarFilterStates.Provider
            value={{
                setStoreBrancheTo,
                setStoreBrancheForm,
                setStoreBranches,
                setStoreBrand,
                setStoreStartDate,
                setStoreEndDate,
                storeBrancheTo,
                storeBrancheFrom,
                storeBrand,
                storeBranche,
                storeStartDate,
                storeEndDate,
                checked,
                setChecked,
                typeIdCar,
                setTypeIdCar,
                activeBtn,
                setActiveBtn,
                invoice,
                setInvoice,
                booking,
                setBooking,
                clacDateLim,
                setClacDateLim,
                userLocationMap,
                setLoctionMap,
                storeTime,
                setStoreTime
            }}
        >
            {children}
        </CarFilterStates.Provider>
    )
}

export default FiltersCars