import axios from 'axios';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Banner from '../../../components/Banner/Banner';
import Component from '../../../constants/Component';
import img from '../../../constants/Img';
import { LocalizationContext } from '../../../context/LangChange';
import Icons from './../../../constants/Icons';
import { Helmet } from 'react-helmet-async';

const DetailsOffers = () => {
    let { isLang } = useContext(LocalizationContext);
    let { id } = useParams()
    const [offerDetails, setOfferDetails] = useState([]);
    const [offerEndData, setOfferEndData] = useState([]);

    const fetchOfferDetails = async () => {
        const url = `${process.env.REACT_APP_API_URL}/offerdetails?offerId=${id}`;
        let { data } = await axios.get(url)
        setOfferDetails(data);
        var parts = data.end_date.split('/');
        var formattedDate = new Date(parts[2], parts[1] - 1, parts[0]);
        var isoFormattedDate = formattedDate.toISOString();
        setOfferEndData(isoFormattedDate);

    }
    useEffect(() => {
        fetchOfferDetails()
        window.scrollBy(0, -window.pageYOffset);

    }, [id])



    // let { id } = useParams();
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const endDate = new Date(`${offerEndData}`).getTime();
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const timeRemaining = endDate - now;

            if (timeRemaining <= 0) {
                clearInterval(interval);
            } else {
                const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

                setDays(days);
                setHours(hours);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [offerEndData]);
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const currentPath = location.pathname;

        if (currentPath === `/od/${id}`) {
            fetchOfferCheck()
        }
    }, [])
    const fetchOfferCheck = async () => {
        const url = `${process.env.REACT_APP_API_URL}/wlog?oid=${id}&type=1`;
        let { data } = await axios.post(url)
        if (data?.status === true) {
            navigate(`/offers/details/${id}`)
        }
    }


    return (
        <>
            <Helmet >
                <meta name="title" content="شركة تأجير السيارات في المملكة العربية السعودية" />
                <meta name="description" content="تقدم شركة الغزال في المملكة العربية السعودية خدمات تأجير سيارات مخصصة للأفراد والشركات بأسعار تنافسية للغاية." />
                <meta name="keywords" content="الغزال لتأجير السيارات ، شركة تأجير السيارات في المملكة العربية السعودية ، شركات تأجير السيارات ، شركة تأجير السيارات في المملكة العربية السعودية ، تأجير السيارات في المملكة العربية السعودية ,  تطبيق لتاجير السيارات في المملكة العربية السعودية ، خدمات تأجير السيارات في المملكة العربية السعودية" />
            </Helmet>
            <div className='app__offers' >
                <Banner
                    img={img.BgOffersPage}
                    title={isLang === "en" ? 'Explore Al Ghazal offers ' : '   استكشف عروض الغزال'}
                    dec={isLang === "en" ? 'We guarantee an easy and smooth experience through a simple booking process and reasonable prices that suit your budget. Book today and enjoy an unforgettable trip! ' : '       نضمن لك تجربة سهلة وسلسة من خلال عملية حجز بسيطة وأسعار معقولة تناسب ميزانيتك. احجز اليوم واستمتع برحلة لا تُنسى!                '}
                />
                <div className="app__offer_details" /* style={bgStyle} */ dir={isLang === "en" ? 'ltr' : 'rtl'}>
                    <Container dir={'ltr'} >
                        <div className="flex justify-content-center align-content-center">
                            <div className="countdown__offers  shadow-4 flex flex-column ">
                                <div className="title">
                                    <span> {isLang === "en" ? 'Offer will expire in' : 'العرض سينتهى خلال'}    </span>
                                </div>
                                <div className="countdown ">
                                    <div className="date">
                                        <h4>{days}</h4>
                                        <p> {isLang === "en" ? ' Days' : '  أيام '}</p>
                                    </div>
                                    <div className="hours">
                                        <h4>{hours}</h4>
                                        <p> {isLang === "en" ? ' Hours' : '  ساعات '}</p>
                                    </div>
                                    <div className="minutes">
                                        <h4>{minutes}</h4>
                                        <p> {isLang === "en" ? ' Minutes' : '  دقائق '}</p>
                                    </div>
                                    <div className="seconds">
                                        <h4>{seconds}</h4>
                                        <p> {isLang === "en" ? ' Seconds' : '  ثواني '}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <div className='pt-45 pb-45 app__offer_details-content m-auto app__offers_details_width  '>
                        <Container>
                            <Component.Title title={isLang === "en" ? 'Offer Details' : 'تفاصيل العرض'} />
                            <p>{isLang === "en" ? offerDetails?.details : offerDetails?.details_arabic}</p>
                            <div className="mt-5">
                                <Component.Title title={isLang === "en" ? 'Terms Of Use' : '  شروط الاستخدام '} />
                                <p>{isLang === "en" ? offerDetails?.terms : offerDetails?.terms_arabic}</p>
                            </div>
                        </Container>

                        <div className="offers_ShownCars mt-50" dir={isLang === "en" ? 'ltr' : 'rtl'} >
                            <Container>

                                <Row style={{ width: '90%' }} className='m-auto d-flex justify-content-center '>

                                    {
                                        offerDetails?.cars?.map((item, index) => (
                                            <Col key={index} className='relative flex justify-content-center align-items-center mt-5' xl={4} lg={4} md={6} sm={12}>
                                                <Card
                                                    title={`${isLang === "en" ? item.brand : item.brand_arabic}` + '  ' + `${isLang === "en" ? item.sub_brand : item.sub_brand_arabic}`}
                                                    header={<img src={item.image} className='w-100 IMA_CAR' alt="images" loading='lazy' />}  >
                                                    <div className="slider_card" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                                        <ul>
                                                            <li>
                                                                <Icons.SliderCarSedan />
                                                                <strong className='bold'>{item.year} {' '} {isLang === "en" ? item.type : item.type_arabic}  </strong>
                                                            </li>
                                                            <li>
                                                                <Icons.SliderCarMoney />
                                                                {item.offer !== 0 && <span className='app__discount'>{item.price} {isLang === "en" ? 'SR' : 'ريال'} </span>}

                                                                {
                                                                    item.offer === 0 ?
                                                                        <span>{item.price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                                        :
                                                                        <span>{item.offer_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                                                }
                                                                <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>

                                                            </li>
                                                            <li>
                                                                <Icons.SliderCarPeople />
                                                                <span>{item.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                                                            </li>
                                                            {item.aircondition !== 0 && <li>
                                                                <Icons.SliderCarSoil />
                                                                <span>    {isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                                                            </li>}

                                                            {item.aircondition === 0 && <li>
                                                                <Icons.SliderCarSoil />
                                                                <span>    {isLang === "en" ? 'Non-air conditioned' : 'غير مكيفة'} </span>
                                                            </li>}


                                                        </ul>
                                                        <div className="order   flex justify-content-end " >
                                                            <Link to={`/${isLang}/cars/${item.id}?car-name=${item.brand_arabic}_${item.sub_brand_arabic}`}>
                                                                <Button label={isLang === "en" ? 'Book now ' : '  احجزها الآن '} size='small' severity="warning" />
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </Card>
                                                {
                                                    item.offer !== 0 && <div className="price absolute" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                                                        <span>{isLang === "en" ? 'off' : 'خصم'}</span>
                                                        {item.percentage}%
                                                    </div>
                                                }
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsOffers