import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { SharedData } from '../../context/CallApi';
import { LocalizationContext } from '../../context/LangChange';
import img from './../../constants/Img';
import './navbar.scss';
import routes from './routes';
import useLocalizedNavigate from '../../hook/useLocalizedNavigate';

const NavBar = ({ navbarColor }) => {
    let { isLang, setIsLang, LogOut } = useContext(LocalizationContext);
    let { fetchPersonalData } = useContext(SharedData);
    const location = useLocation();
    const localizedNavigate = useLocalizedNavigate();

    const [visibleRight, setVisibleRight] = useState(false);
    // const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    let navigate = useNavigate()
    // const currentPath = location.pathname;
    const onAvatarClick = (event) => {
        menuRef.current.show(event);
        setShowMenu(!showMenu);
    };

    // const [activeLink, setActiveLink] = useState(0);

    const handleLinkClick = (index) => {
        // setActiveLink(index);
        // setMenuOpen(false)
    };

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleLocalizationChange = async (action) => {
        if (action === 'en') {
            setIsLang('en')
            window.location.reload();

        } else if (action === 'ar') {
            setIsLang('ar')
            window.location.reload();
        } 
    }
 
    const menuItems = [

        {
            label: <Link to="/account/personal_data"> {isLang === 'en' ? 'Profile' : 'الملف الشخصي'}</Link>,
            icon: 'pi pi-fw pi-user',
            command: () => {
                // LogOut(); 
            },
        },

        {
            label: <Link to="/"> {isLang === 'en' ? 'Logout' : 'تسجيل خروج  '}</Link>,
            icon: 'pi pi-fw pi-sign-out',
            command: () => {
                LogOut();
            },

        },
        {
            label: isLang === 'en' ? 'Change Lang' : 'تغير اللغة',
            icon: 'pi pi-fw pi-globe ',
            items: [
                {
                    label: 'عربيه',
                    icon: isLang === 'ar' ? 'pi pi-fw  pi-check' : ' ',
                    command: () => handleLocalizationChange('ar'),

                },
                {
                    label: 'English',
                    icon: isLang === 'en' ? 'pi pi-fw  pi-check' : ' ',
                    command: () => handleLocalizationChange('en'),

                },
            ]
        },
        { separator: true },
        {
            template: (item, options) => {
                return (
                    <div className="details_users">
                        <button onClick={(e) => options.onClick(e)} className={'w-full p-link flex align-items-center px-3 pb-3 pt-3'}>
                            <Avatar image={`${fetchPersonalData?.image}`} className="mr-2" shape="circle" />
                            <div className="flex flex-column align">
                                <span className="font-bold">{fetchPersonalData?.first_name}</span>
                                <span className="text-sm">{fetchPersonalData?.email}</span>
                            </div>
                        </button>
                    </div>
                )
            }

        },
    ];

    useEffect(() => {
        // Retrieve the current pathname
        const pathname = window.location.pathname;
        if (pathname == '/') {
            navigate('/en');

        } else {
            navigate('/ar');

        }
        if (isLang == "en") {
            const newPath = pathname.replace('/ar', '/en');
            // Navigate to the new path
            navigate(newPath);

        } else {
            const newPath = pathname.replace('/en', '/ar');
            // Navigate to the new path
            navigate(newPath);
        }
    }, [])
    return (
        <>
            <div className="main-nav">
                <Navbar
                    dir={`${isLang === "en" ? 'ltr' : 'rtl'}`}
                    fixed={scrollPosition > 400 ? 'top' : 'top'}
                    collapseOnSelect
                    expand="sm"
                    variant={'dark'}
                    className={`px-3  ${navbarColor}  py-3 ${scrollPosition > 400 ? 'scroll navbar-navOnthorPAge shadow-sm ' : ''}`}
                >
                    <Container fluid >
                        <div className="flex justify-content-between    align-items-center navbar_view_lg">
                            <Navbar.Brand className={`${isLang === "en" ? 'Logo__RightClick Logo__RightClickEn' : 'Logo__RightClick'} `} as={Link} to={'/'}>
                                <img src={isLang === "en" ? img.LogoEn : img.Logo} alt="images" loading='lazy' />
                            </Navbar.Brand>
                            <div className="sidebar_nav">
                                < >
                                    <Nav
                                        className="me-auto my-2 my-lg-0  w-100 h-100"
                                        navbarScroll
                                    >
                                        <Button size='small' rounded outlined severity="secondary" aria-label="Bookmark" icon="pi pi-bars" onClick={() => setVisibleRight(true)} />
                                    </Nav>

                                </>
                            </div>
                        </div>
                        <div className="navbar_none w-100 ">
                            <div className="flex  justify-content-between   align-items-center"  >
                                <div className="navbar__hidden">
                                    <Navbar.Collapse id="navbarScroll"  >
                                        <Nav
                                            className="w-100 m-auto my-2 my-lg-0 flex gap-4"
                                            style={{ maxHeight: '100px' }}
                                            navbarScroll
                                        >
                                            <Nav.Link onClick={() => localizedNavigate('/')} className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}>{isLang === "en" ? 'Home' : 'الرئيسية'}</Nav.Link>
                                            <Nav.Link onClick={() => localizedNavigate('/cars')} className={`nav-link ${location.pathname === '/cars' ? 'active' : ''}`}>{isLang === "en" ? 'Fleet' : 'أسطـول الغزال'}</Nav.Link>
                                            <Nav.Link onClick={() => localizedNavigate('/offers')} className={`nav-link ${location.pathname === '/offers' ? 'active' : ''}`}>{isLang === "en" ? 'Offers' : 'العروض'}</Nav.Link>
                                            <Nav.Link onClick={() => localizedNavigate('/branches')} className={`nav-link ${location.pathname === '/branches' ? 'active' : ''}`}>{isLang === "en" ? 'Branches' : 'الفروع'}</Nav.Link>
                                            <Nav.Link onClick={() => localizedNavigate('/about')} className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`}>{isLang === "en" ? 'About Us' : 'من نحن'}</Nav.Link>
                                            <Nav.Link onClick={() => localizedNavigate('/blogs')} className={`nav-link ${location.pathname === '/blogs' ? 'active' : ''}`}>{isLang === "en" ? 'Blogs' : 'المدونات'}</Nav.Link>
                                            <Nav.Link onClick={() => localizedNavigate('/contactus')} className={`nav-link ${location.pathname === '/contactus' ? 'active' : ''}`}>{isLang === "en" ? 'Contact Us' : 'تواصل معنا'}</Nav.Link>
                                            {/* تعليق الرابط الوظيفي إذا لم يكن مستخدمًا */}
                                        </Nav>
                                    </Navbar.Collapse>
                                </div>
                                <div className="navbar__hidden">
                                    <Navbar.Collapse id="navbarScroll" >
                                        <Nav
                                            className=" my-2 my-lg-0 flex justify-content-center align-items-center gap-3"
                                            style={{ maxHeight: '100px' }}
                                            navbarScroll
                                        >
                                            {
                                                localStorage.getItem('CLIENT_FOUND') === "CLIENT_FOUND" ?
                                                    <Avatar onClick={onAvatarClick} image={`${fetchPersonalData?.image}`} className="mr-2" shape="circle" />
                                                    :
                                                    <>
                                                        <Nav.Link onClick={() => localizedNavigate('/auth/sign-in')}> {isLang === "en" ? 'Login ' : 'تسجيل دخول'} </Nav.Link>

                                                        <div className="change_lang_navbar1">
                                                            {/* <Link to="/">En</Link> */}
                                                            {
                                                                isLang === "en" ?
                                                                    <div
                                                                        className='app__menu-talk cursor-pointer'
                                                                        whileHover={{ scale: 1.1 }}
                                                                        whileTap={{ scale: 0.8 }}
                                                                        onClick={(eventKey) => handleLocalizationChange('ar')}  >
                                                                        <span className='text-light'>العربية </span>
                                                                        <img src={img.ArLang} alt="images" loading='lazy' />
                                                                    </div> :
                                                                    <div
                                                                        className='app__menu-talk cursor-pointer flex gap-2'
                                                                        onClick={(eventKey) => handleLocalizationChange('en')}
                                                                    >
                                                                        <span className='text-light'>En </span>


                                                                        <img src={img.EnLang} alt="images" loading='lazy' />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </>
                                            }

                                        </Nav>

                                    </Navbar.Collapse>
                                    <TieredMenu
                                        ref={menuRef}
                                        model={menuItems}
                                        popup
                                        onHide={() => setShowMenu(false)}
                                        id="popup_menu"
                                        style={{ display: showMenu ? 'block' : 'none' }}
                                        className='mt-2'
                                    />
                                </div>
                            </div>
                        </div>


                    </Container>
                </Navbar>

                <Sidebar className='bg_sidebar view_sidebar_home-page' dir={isLang === "en" ? 'ltr' : 'rtl'} visible={visibleRight} position={`${isLang === "en" ? 'right' : 'left'}`} onHide={() => setVisibleRight(false)}>

                    <div className="navbar_sm_page  ">

                        <div className="   d-flex flex-column   " >
                            {
                                localStorage.getItem("client_id") &&
                                <>
                                    <div className="details_users_sidebar mt-4">
                                        <button className={'w-full p-link flex align-items-center gap-3 px-3 pb-3 pt-3'}>
                                            <img src={`${fetchPersonalData?.image}`} alt="image logo" loading='lazy' />
                                            <div className="flex flex-column align details_users_sidebar_content">
                                                <span className="font-bold">{fetchPersonalData?.first_name}</span>
                                                <span className="text-sm">{fetchPersonalData?.email}</span>
                                            </div>
                                        </button>
                                    </div>
                                    <hr />
                                </>
                            }

                            <section className={'routes_navabr_sidbar  '} >
                                {
                                    localStorage.getItem("client_id") &&
                                    <div>
                                        <Link
                                            to={`/${isLang}/account/personal_data`}
                                            className={`link link__sidebar ${location.pathname === `/${isLang}/account/personal_data` ? 'active' : ''} ${isLang === "en" ? 'justify-content-start' : 'justify-content-start'}`}
                                        >
                                            <div className={`icon`}>
                                                <i className='pi pi-fw pi-user text-light'></i>
                                            </div>
                                            <div className="link_text">{isLang === "en" ? 'Profile' : 'الصفحه الشخصية'}</div>
                                        </Link>
                                    </div>
                                }

                                {routes?.map((route, i) => {
                                    // إنشاء المسار مع بادئة اللغة
                                    const pathWithLang = `/${isLang}${route.path}`;

                                    return (
                                        <div key={i}>
                                            <Link
                                                to={pathWithLang}
                                                className={`link link__sidebar ${location.pathname === pathWithLang ? 'active' : ''} ${isLang === "en" ? 'justify-content-start' : 'justify-content-start'}`}
                                                onClick={() => {
                                                    handleLinkClick(i);
                                                    setVisibleRight(false);
                                                }}
                                            >
                                                <div className={`icon ${location.pathname === pathWithLang ? 'active' : ''}`} id={route.name}>
                                                    {route.icon}
                                                </div>
                                                <div className="link_text">{isLang === "en" ? route.nameEn : route.nameAr}</div>
                                            </Link>
                                        </div>
                                    );
                                })}


                                {
                                    !localStorage.getItem("client_id") ?
                                        <div>
                                            <Link

                                                onClick={() => {
                                                    setVisibleRight(false)
                                                    localizedNavigate('/auth/sign-in')
                                                }}
                                                className={`link link__sidebar   ${isLang === "en" ? 'justify-content-start' : 'justify-content-start'}`}
                                            >
                                                <div className={`icon  `}  >
                                                    <i className='pi pi-fw pi-sign-in text-light'></i>
                                                </div>
                                                <div className="link_text">{isLang === "en" ? 'Login ' : 'تسجيل دخول'}</div>
                                            </Link>
                                        </div>
                                        :
                                        <div>
                                            <Link
                                                onClick={() => {
                                                    LogOut()
                                                    setVisibleRight(false)
                                                }}
                                                className={`link link__sidebar   ${isLang === "en" ? 'justify-content-start' : 'justify-content-start'}`}
                                            >
                                                <div className={`icon`}  >
                                                    <i className='pi pi-fw pi-sign-out text-light'></i>
                                                </div>
                                                <div className="link_text">{isLang === "en" ? 'Logout ' : 'تسجيل خروج '}</div>
                                            </Link>
                                        </div>
                                }
                            </section>
                        </div>
                        <hr />
                        <div className="change_lang-sidebar " style={{ padding: '0 18px' }} dir={isLang === "en" ? 'ltr' : 'rtl'}>
                            <div className="header">
                                <h3>{isLang === "en" ? 'Language change ' : '  تغيـــر اللغــة '}</h3>
                            </div>
                            {
                                isLang === "en" ?
                                    <div
                                        className='app__menu-talk cursor-pointer'
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.8 }}
                                        onClick={(eventKey) => {
                                            handleLocalizationChange('ar')
                                            setVisibleRight(false)
                                        }}
                                    >
                                        عربيه
                                        <img src={img.ArLang} alt="images" loading='lazy' />
                                    </div> :
                                    <div
                                        className='app__menu-talk cursor-pointer'
                                        onClick={(eventKey) => {
                                            handleLocalizationChange('en')
                                            setVisibleRight(false)
                                        }}
                                    >
                                        English
                                        <img src={img.EnLang} alt="images" loading='lazy' />
                                    </div>
                            }
                        </div>
                    </div>

                </Sidebar>


            </div>
        </>
    )
}

export default NavBar
