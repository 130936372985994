import { Player } from '@lottiefiles/react-lottie-player';
import React, { useContext, useState, useEffect } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import img from '../../../constants/Img';
import { LocalizationContext } from '../../../context/LangChange';
import { Data } from '../Data';
import Component from '../../../constants/Component';

const Terms = () => {
  let { isLang } = useContext(LocalizationContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
    Data.fetchTermsAll().then((data) => setData(data));
  }, []);
  return (
    <div className="app__wallet mt-70 py-7">

      <div className='terms_popup mt pb-7 px-2'>
        <Container>
          <header className=' '>
            <Component.Title title={isLang === "en" ? 'Terms of use' : 'شروط الأستخدام'} />
          </header>
          <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
            <Col xl={12} lg={12} md={12} sm={12} >
              {
                data?.slice(0, 3).map((item, index) => (
                  <section className='content mt-4' key={index}>
                    <h4>{isLang === "en" ? item.title : item.titleAr} <small className='fw-light'>{isLang === "en" ? item.desc : item.descAr} </small> </h4>
                    <div className={`${isLang === "en" ? 'list_number' : 'list_number list_number_Ar'}`}>
                      <ul className='mt-5'>
                        {
                          item?.list?.map((item, index) => (
                            <li className='mt-2' key={index}> {isLang === "en" ? item.title : item.titleAr}
                              <div className={`${isLang === "en" ? 'list_number' : 'list_number list_number_Ar'}`}>
                                {
                                  item?.ListUl ?
                                    <ol>
                                      {
                                        item?.ListUl?.map((item, index) => (
                                          <li className='mt-1 ' key={index}>
                                            <strong>{isLang === "en" ? item.title : item.titleAr}</strong> <small>{isLang === "en" ? item.desc : item.descAr}</small>
                                            <div className={`${isLang === "en" ? 'list_number' : 'list_number list_number_Ar'}`}>
                                              <ol>
                                                {
                                                  item?.listOl?.map((item, index) => (
                                                    <li className='mt-1' key={index}> <>{isLang === "en" ? item.title : item.titleAr}</> <small>{isLang === "en" ? item.desc : item.descAr}</small>
                                                      <div className={`${isLang === "en" ? 'list_number' : 'list_number list_number_Ar'}`}>
                                                        <ol>
                                                          {
                                                            item?.listOl3th?.map((item, index) => (
                                                              <li className='mt-1' key={index}> <>{isLang === "en" ? item.title : item.titleAr}</> <small>{isLang === "en" ? item.desc : item.descAr}</small> </li>
                                                            ))
                                                          }
                                                        </ol>
                                                      </div>
                                                    </li>
                                                  ))
                                                }
                                              </ol>
                                            </div>
                                          </li>
                                        ))
                                      }
                                    </ol> : ''
                                }
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                      <hr />
                    </div>

                  </section>

                ))
              }

            </Col>

          </Row>
        </Container>
      </div>

    </div>
  )
}

export default Terms
