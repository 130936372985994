
// import Vigtas from './../layout/Vigtas'; 
import DownloadAppNow from '../Pages/website/Home/DownloadAppNow/DownloadAppNow';
import Home from '../Pages/website/Home/Home';
import HomeContactUS from '../Pages/website/Home/HomeContactUS/HomeContactUS';
import LatestCars from '../Pages/website/Home/LatestCars/LatestCars';
import HomeOffers from '../Pages/website/Home/Offers/HomeOffers';
import SearchCar from '../Pages/website/Home/SearchCar/SearchCar';
import Services from '../Pages/website/Home/services/Services';
import Footer from './../components/Footer/Footer';
import NavBar from './../components/NavBar/NavBar';
import Title from './../components/Title/Title';
import Alghazal from './../layout/Alghazal';

import CarPayDetails from '../Pages/Admin/Bookings/CarPayDetails';
import Points from '../Pages/Admin/Points/Points';
import Policy from '../Pages/Setting/Policy/Policy';
import PrivacyPolicyPopup from '../Pages/Setting/Policy/PrivacyPolicyPopup';
import TermsPopup from '../Pages/Setting/Terms/TermsPopup';
import { BlogSection } from '../Pages/website/Home/HomeBlogs/BlogSection';
import Bookings from './../Pages/Admin/Bookings/Bookings';
import ManageReservations from './../Pages/Admin/Bookings/ManageReservations';
import DeliveryAddress from './../Pages/Admin/Delivery/DeliveryAddress';
import ChangePasword from './../Pages/Admin/PersonalData/ChangePasword';
import EditAccount from './../Pages/Admin/PersonalData/EditAccount';
import PersonalData from './../Pages/Admin/PersonalData/PersonalData';
import Wallet from './../Pages/Admin/Points/Wallet';
import Terms from './../Pages/Setting/Terms/Terms';
import TermsCancellation from './../Pages/Setting/TermsCancellation';
import ActivationAccount from './../Pages/auth/Login/ActivationAccount';
import ForgetPassword from './../Pages/auth/Login/ForgetPassword';
import Login from './../Pages/auth/Login/Login';
import Register from './../Pages/auth/Register/Register';
import AboutUS from './../Pages/website/AboutUS/AboutUS';
import Branches from './../Pages/website/Branches/Branches';
import Connect from './../Pages/website/ConnectUs/Connect';
import Apply from './../Pages/website/Job/Apply';
import Career from './../Pages/website/Job/Career';
import LimousineCars from './../Pages/website/Limousine/LimousineCars';
import DetailsOffers from './../Pages/website/Offers/DetailsOffers';
import Offers from './../Pages/website/Offers/Offers';
import BankView from './../Pages/website/Payment/BankView';
import PaymentDetails from './../Pages/website/Payment/PaymentDetails';
import PaymentModal from './../Pages/website/Payment/PaymentModal';
import LimousineDetails from './../Pages/website/Payment/ReservationLimousine/LimousineDetails';
import ResLimousine from './../Pages/website/Payment/ReservationLimousine/ResLimousine';
import ReservationsBefore from './../Pages/website/Payment/ReservationsBefore';
import FiltersCars from './../Pages/website/ShownCars/FiltersCars';
import ShownCars from './../Pages/website/ShownCars/ShownCars';
import Loader from './../components/Loaders/Loader';
import ModalLang from './../components/NavBar/ModalLang';
import NotFound from './../components/NotFound/NotFound';
import SidebarMenu from './../components/Sidebar/SidebarMenu';
import SidebarSM from './../components/Sidebar/SidebarSM';
import Sidebar from './../components/Sidebar/Sildebar';
import Thanks from './../components/Thanks/Thanks';
import Account from './../layout/Account';
import Auth from './../layout/Auth';
import BlogsDetails from '../Pages/website/blogs/BlogsDetails';
import Blogs from '../Pages/website/blogs/Blogs';

const Component = { 
    // Layout
    Alghazal,  
    Auth,
    Points,
    ActivationAccount,
    Loader,
    ModalLang,
    CarPayDetails,
    Wallet,
    Account,
    EditAccount,
    FiltersCars,
    NavBar,
    Sidebar,
    SidebarSM,
    SidebarMenu,
    Footer, 
    Home,
    Title,
    SearchCar,
    LatestCars,
    DownloadAppNow,
    ChangePasword,
    HomeOffers,
    HomeContactUS,
    Services,
    AboutUS,
    Login,
    Register,
    Career,
    ShownCars,
    Offers,
    Connect,
    Branches,
    DetailsOffers,
    NotFound,
    PaymentModal,
    PaymentDetails, 
    DeliveryAddress,
    PersonalData,
    ManageReservations,
    Bookings,
    Apply,
    Policy,
    Thanks,
    Terms,
    PrivacyPolicyPopup,
    TermsPopup,
    ForgetPassword,
    ReservationsBefore,
    LimousineCars,
    TermsCancellation,
    LimousineDetails,
    BlogSection,
    ResLimousine,
    BlogsDetails,
    Blogs,
    BankView
}

export default Component


