import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

export const FiltersApis = createContext([])

function CarFiltersApis({ children }) {

    const [branchesData, setBranches] = useState([]);
    const [engines, setEngines] = useState([]);
    const [CarTypes, setCarTypes] = useState([]);
    const [CarModel, setCarModel] = useState([]);
    const [CarBrands, setCarBrands] = useState([]);
    const [carData, setCarData] = useState([]);
    const [page, setPage] = useState('');
    const [LoadingCars, setLoadingCars] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(1);


    const [CarSubBrands, setCarSubBrands] = useState([]);
    const [carBrandId, setCarBrandId] = useState(0);
    const fetchCarSubBrands = async () => {
        const url = `${process.env.REACT_APP_API_URL}/subs?brandId=${carBrandId}`;
        let { data } = await axios.get(url)
        setCarSubBrands(data);
    }

    const fetchCarsAll = async () => {
        const url = `${process.env.REACT_APP_API_URL}/allcars`;
        let { data } = await axios.get(url)
        setCarBrands(data?.brands);
        setCarModel(data?.years);
        setCarTypes(data?.types);
        setEngines(data?.engines);
        setBranches(data?.branches);
        setCarData(data?.cars);
        setPage(data?.pages);
    }

    const [offersData, setOffersData] = useState([]);
    const fetchOffers = async () => {
        const url = `${process.env.REACT_APP_API_URL}/offers`;
        let { data } = await axios.get(url)
        setOffersData(data);
    }


    const [LimozinPlacesData, setLimozinPlacesData] = useState([]);

    const fetchLimozinPlaces = async () => {
        const url = `${process.env.REACT_APP_API_URL}/limozin`;
        let { data } = await axios.get(url)
        setLimozinPlacesData(data);
    }

    useEffect(() => {
        fetchCarsAll()
        fetchOffers()
        fetchLimozinPlaces()
    }, [])


    useEffect(() => {
        fetchCarSubBrands()
    }, [carBrandId])

    return (
        <FiltersApis.Provider
            value={{
                LoadingCars,
                setLoadingCars,
                branchesData,
                engines,
                CarTypes,
                CarModel,
                CarBrands,
                CarSubBrands,
                setCarBrandId,
                carData,
                setCarData,
                fetchCarsAll,
                offersData,
                LimozinPlacesData,
                page, setPage, rows, setRows, first, setFirst

            }}
        >
            {children}
        </FiltersApis.Provider>
    )
}

export default CarFiltersApis