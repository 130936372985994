import axios from 'axios';
import { format } from 'date-fns/esm';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CarFilterStates } from '../../../context/FiltersCars';
import { useContext } from 'react';
import { useMountEffect } from 'primereact/hooks';
import { SharedData } from '../../../context/CallApi';
import { Message } from 'primereact/message';
import PhoneInput from 'react-phone-input-2';
import Nationalities from '../../auth/Register/Nationalities';
import toast from 'react-hot-toast';



const ReservationsBefore = ({ id, isLang }) => {
    let { setBooking } = useContext(CarFilterStates);
    let { fetchPersonalData,fetchPersonal, bookingValidStep1, setBookingValidStep1 } = useContext(SharedData);
    const nationality = Nationalities();

    const msgs = useRef(null);
    const idtype = [
        { name: isLang === "en" ? "Citizen" : "مواطــن ", value: 0 },
        { name: isLang === "en" ? "Resident" : "مقيــم", value: 1 },
        { name: isLang === "en" ? "Visitor" : "زائــر", value: 2 },
    ];
    const gender = [
        { name: 'Male', value: 0 },
        { name: 'Female', value: 1 },
    ];
    const validationSchema = Yup.object().shape({
        // birthdate: Yup.date().required(isLang === "en" ? "Birth date is required" : "تاريخ الميلاد مطلوب"),
        birthdate: Yup.date()
            .required(isLang === "en" ? "Birth date is required" : "تاريخ الميلاد مطلوب")
            .test(
                'age',
                isLang === "en" ? "Must be 21 years or older" : "يجب أن يكون عمرك 21 عامًا أو أكثر",
                value => {
                    const today = new Date();
                    const birthdate = new Date(value);
                    const age = today.getFullYear() - birthdate.getFullYear();
                    const m = today.getMonth() - birthdate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
                        return age > 21;
                    }
                    return age >= 21;
                }
            ),
        // licenseexp: Yup.date().required(isLang === "en" ? "License expiration date is required" : "تاريخ انتهاء الرخصة مطلوب"),
        idtype: Yup.string().required(isLang === "en" ? "Identity type is required" : "نوع الهوية مطلوب"),

        idnum: Yup.string()
            .when("idtype", (idtype, schema) => {
                if (idtype == '2') {
                    // إذا كان النوع زائر، لا نحتاج إلى التحقق من رقم الهوية
                    return schema.notRequired();
                } else {
                    // تطبيق التحقق من صحة رقم الهوية للمواطنين والمقيمين
                    return schema.test(
                        'is-saudi-national-id',
                        isLang === "en" ? 'National ID invalid and must be 10 digits' : 'رقم الهوية غير صالح و يجب ان يتكون من 10 أرقام',
                        value => { 
                            if (idtype == '1') { // المقيمين
                                return /^[2][0-9]{9}$/.test(value);
                            }
                            if (idtype != 1) {
                                return /^[1][0-9]{9}$/.test(value);
                            }
                        }
                    ).required(isLang === "en" ? 'National ID is required' : 'رقم الهوية مطلوب');
                }
            }),
        // idf1: Yup.string().required(isLang === "en" ? "Front side of National ID is required" : "الجهة الأمامية للهوية الوطنية مطلوبة"),
        // idf2: Yup.string().required(isLang === "en"  ? "Back side of National ID is required"   : "الجهة الخلفية للهوية الوطنية مطلوبة"),
        // lic1: Yup.string().required(isLang === "en" ? "Front side of Driving License is required" : "الجهة الأمامية لرخصة القيادة مطلوبة"),
        // lic2: Yup.string().required(isLang === "en" ? "Back side of Driving License is required" : "الجهة الخلفية لرخصة القيادة مطلوبة"),
    });
    const [isUploading, setIsUploading] = useState(false);

    const formikReservationsApi = useFormik({
        initialValues: {
            birthdate: '',
            licenseexp: '',
            idtype: '',
            idnum: '',// رقم الهوية 
            version_num: '', //رقم النسخة
            gender: fetchPersonalData.gender !== null ? fetchPersonalData.gender : '', // Set gender to the value from fetchPersonalData.gender
            mobile: '',
            license_num: '',
            nationality: '',
            email: '',
            clientId: localStorage.getItem('client_id'),
            nationalId: ''
        },
        validationSchema, // Pass the dynamically created schema
        onSubmit: (values) => { 

            handleResFirstTime(values)
            /*setBooking(values);
              idCheckApi() */
        }
    });
    const handleResFirstTime = async (d) => {
        const getcClientId = localStorage.getItem('client_id');
        try {
            const nationalId = d?.nationalId ? `&idNum=${d?.nationalId}` : '&idnum='; 
            const idtype = d?.idtype ? `&idtype=${d?.idtype}` : '&idtype='; 
            const birthdate = d?.birthdate ? `&birthdate=${d?.birthdate}` : '&birthdate='; 
            const idnum = d?.idnum ? `&idnum=${d?.idnum}` : '&idnum='; 
            const gender = d?.gender ? `&gender=${d?.gender}` : '&gender='; 
            const license_num = d?.license_num ? `&license_num=${d?.license_num}` : '&license_num='; 
            const nationality = d?.nationality ? `&nationality=${d?.nationality}` : '&nationality='; 
            const email = d?.email ? `&email=${d?.email}` : '&email='; 
            const mobile = d?.mobile ? `&mobile=${d?.mobile}` : '&mobile='; 
            const version_num = d?.version_num ? `&version_num=${d?.version_num}` : '&version_num='; 
            localStorage.setItem('type_national_id',d?.idtype)

            const url = `${process.env.REACT_APP_API_URL}/completeservlet?clientId=${getcClientId}${nationalId}&license_exp=''&idtype=${d?.idtype}${idnum}${birthdate}${gender}${license_num}${nationality}${email}${mobile}${version_num}`;
            let { data } = await axios.post(url)
            if (data?.status === true) {
                toast.success(isLang === "en" ? 'The rest of the data has been completed successfully' : 'تم إكمال باقي البيانات بنجاح')
                setBookingValidStep1(false)
                // fetchPersonal()
            }else{
                toast.success(isLang === "en" ? 'An error occurred while recording data' : 'حدث خطا اثناء تسجيل البيانات')

            }


        } catch (error) {
            console.error('حدث خطأ أثناء النسخ: ', error);
        }
    };
    const handleFileSelect = async (fieldName, event) => {
        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            const { data } = await axios.post(`${process.env.REACT_APP_URL_IMAGE}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            formikReservationsApi.setFieldValue(fieldName, data);
        } catch (error) {
            console.error('Error uploading the image:', error);
            formikReservationsApi.setErrors({ [fieldName]: 'Error uploading the image' });
        } finally {
            setIsUploading(false);
        }
    };

    useMountEffect(() => {
        if (msgs.current) {
            msgs.current.clear();
            msgs.current.show({ id: '1', sticky: true, severity: 'warn', summary: '', detail: isLang === "en" ? 'Please complete the information only once and then complete the booking steps' : 'رجاء استكمال البيانات لمرة واحدة فقط ثم استكمل خطوات الحجز', closable: false });
        }
    });
    const idCheckApi = async () => {
        // toast.current.clear();
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/idcheck?nationalId=${formikReservationsApi.values.idnum}`);
            if (data.msg === 'NATIONAL_ID_EXIST') {
                formikReservationsApi.setFieldError('idnum', isLang === "en" ? 'National identity already exists' : '   الهوية الوطنية موجودة مسبقا');
            } else if (data.msg === 'NOT_FOUND') {
                // setBookingValidStep1(false)
                // formikReservationsApi.setFieldError('idnum', 'NOT_FOUND');
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };
    useEffect(() => {
        formikReservationsApi.setValues({/* 
            firstName: fetchPersonalData?.first_name || '',
            lastName: fetchPersonalData.last_name || '', */
            email: fetchPersonalData.email || '',
            mobile: fetchPersonalData.mobile || '',
            // type: fetchPersonalData.type !== null ? fetchPersonalData.type : '',
            nationality: fetchPersonalData.nationality || '',
            gender: fetchPersonalData.gender !== null ? fetchPersonalData.gender : '',
            clientId: localStorage.getItem('client_id'),

            // address: fetchPersonalData.address || '',
            // image: fetchPersonalData.image || '',
            // cityId: fetchPersonalData.cityId !== null ? fetchPersonalData.cityId : '',
        });
    }, [fetchPersonalData, formikReservationsApi.setValues]);
    return (
        <>
            <form onSubmit={formikReservationsApi.handleSubmit}>
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} className='mt-3'>
                        <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                            <Messages ref={msgs} className='w-100' />
                        </div>
                    </Col>

                    <Col xl={6} lg={6} md={6} sm={12} className={`${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}  mt-2 calendar_check `} >
                        <label htmlFor="birthdate" className="font-bold block mb-2">{isLang === "en" ? 'Birth-date' : '  تاريخ الميلاد '}  </label>
                        <Calendar
                            id="birthdate"
                            name="birthdate"
                            value={formikReservationsApi.values.birthdate ? new Date(formikReservationsApi.values.birthdate) : null}
                            placeholder={isLang === "en" ? 'Birth-date' : '  تاريخ الميلاد '}
                            onChange={(e) => {
                                const selectedDate = e.value;
                                const formattedDate = selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '';
                                formikReservationsApi.setFieldValue("birthdate", formattedDate);
                            }}
                            showIcon
                            className='w-full '
                            dir={isLang === "en" ? 'ltr' : 'rtl'}
                            icon="pi pi-calendar-plus"
                            touchUI
                            required

                        />
                        {formikReservationsApi.touched.birthdate && formikReservationsApi.errors.birthdate && (
                            <small className="p-error">{formikReservationsApi.errors.birthdate}</small>
                        )}
                    </Col>



                    <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col  ">
                        <label htmlFor="gender" className="font-bold block mb-2">
                            {isLang === "en" ? 'Gender  ' : '  النوع '}
                        </label>
                        <Dropdown
                            id="gender"
                            name="gender"
                            value={formikReservationsApi.values.gender}
                            options={gender}
                            optionLabel="name"
                            onChange={(e) => formikReservationsApi.setFieldValue("gender", e.value)}
                            onBlur={formikReservationsApi.handleBlur}
                            placeholder={isLang === "en" ? 'Select a gender' : 'اختر النوع'}
                            className="w-full p-inputtext-sm"
                            required

                        />
                        {formikReservationsApi.errors.gender && formikReservationsApi.touched.gender ?
                            <Message severity="error" text={formikReservationsApi.errors.gender} /> : null
                        }
                    </div>

                    <Col xl={6} lg={6} md={6} sm={12} className='mt-4'   >
                        <label htmlFor="licenseexp" className="font-bold block mb-2">{isLang === "en" ? 'Identity type' : 'نوع الهوية '}   </label>
                        <Dropdown
                            id="idtype"
                            name="idtype"
                            value={formikReservationsApi.values.idtype}
                            optionLabel="name"
                            onChange={(e) => formikReservationsApi.setFieldValue("idtype", e.value)}
                            onBlur={formikReservationsApi.handleBlur}
                            options={idtype}
                            placeholder={isLang === "en" ? 'Card type' : 'نوع الهوية'}
                            className="w-full p-inputtext-sm "
                            required
                        />
                        {formikReservationsApi.touched.idtype && formikReservationsApi.errors.idtype && (
                            <small className="p-error">{formikReservationsApi.errors.idtype}</small>
                        )}
                    </Col>
                    {
                        (formikReservationsApi.values.idtype == 0 || formikReservationsApi.values.idtype == 1) &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                                <label htmlFor="idnum" className="font-bold block mb-2">{isLang === "en" ? 'ID Number' : ' رقم الهوية  '} </label>
                                <InputText
                                    id="idnum"
                                    name="idnum"
                                    type="text"
                                    required
                                    onBlur={(e) => {
                                        formikReservationsApi.handleBlur(e);
                                    }}
                                    onChange={(e) => {
                                        formikReservationsApi.handleChange(e);
                                        if (!formikReservationsApi.errors.idnum && formikReservationsApi.touched.idnum) {
                                            idCheckApi();
                                        }
                                    }}
                                    // onChange={formikReservationsApi.handleChange}
                                    className="w-full p-inputtext-sm"
                                    dir='ltr'
                                />
                                {formikReservationsApi.errors.idnum && formikReservationsApi.touched.idnum ?
                                    <small className="p-error">{formikReservationsApi.errors.idnum}</small> : null
                                }
                            </div>

                            <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                                <label htmlFor="version_num" className="font-bold block mb-2">{isLang === "en" ? 'Version Number' : 'رقم النسخة'} </label>
                                <InputText
                                    id="version_num"
                                    name="version_num"
                                    type="text"
                                    onBlur={(e) => {
                                        formikReservationsApi.handleBlur(e);
                                    }}
                                    onChange={formikReservationsApi.handleChange}
                                    className="w-full p-inputtext-sm"
                                    dir='ltr'
                                    required
                                />
                                {formikReservationsApi.errors.version_num && formikReservationsApi.touched.version_num ?
                                    <small className="p-error">{formikReservationsApi.errors.version_num}</small> : null
                                }
                            </div>

                            <div dir='ltr' className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                                <label htmlFor="mobile" dir={isLang == "en" ? 'ltr' : 'rtl'} className="font-bold block mb-2">{isLang === "en" ? ' Mobile Number' : '  رقم الجوال '}  </label>

                                <PhoneInput
                                    country='sa'
                                    enableSearch={true}
                                    id="mobile"
                                    name="mobile"
                                    preferredCountries={['sa']}
                                    // onChange={(value) => formikReservationsApi.setFieldValue('mobile', value)}
                                    onChange={(value, countryData) => {
                                        // استخراج مفتاح الدولة من بيانات الدولة
                                        let dialCode = countryData.dialCode;
                                        // إزالة مفتاح الدولة من القيمة المدخلة
                                        let newValue = value.replace(dialCode, '');
                                        // إذا كانت القيمة الجديدة تبدأ بالصفر، ازل هذا الصفر
                                        if (newValue.startsWith('0')) {
                                          newValue = newValue.substring(1);
                                        }
                                        // إعادة إضافة مفتاح الدولة إلى القيمة الجديدة
                                        newValue = dialCode + newValue;  
                                        formikReservationsApi.setFieldValue('mobile', newValue);
                                      }}
                                    onBlur={formikReservationsApi.handleBlur}
                                    dir="ltr"
                                    value={formikReservationsApi.values.mobile}
                                    containerClass="w-full"
                                    className="w-full "
                                    inputClass={'w-100'}
                                    // localization={ar}   
                                    inputExtraProps={{
                                        autoFocus: true,
                                    }}
                                />
                                {formikReservationsApi.errors.mobile && formikReservationsApi.touched.mobile ?
                                    <small className="p-error">{formikReservationsApi.errors.mobile}</small> : null
                                }
                            </div>

                        </>
                    }
                    {
                        (formikReservationsApi.values.idtype == 2 || formikReservationsApi.values.idtype == 1) &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col mt-3" >
                                <label htmlFor="title_ar" className="font-bold block mb-2"> {isLang === "en" ? ' Nationality' : '   الجنسية'}    </label>
                                <Dropdown
                                    filter
                                    required
                                    id="nationality"
                                    name="nationality"
                                    value={formikReservationsApi.values.nationality}
                                    options={nationality}
                                    optionLabel="name"
                                    onChange={(e) => formikReservationsApi.setFieldValue("nationality", e.value)}
                                    onBlur={formikReservationsApi.handleBlur}
                                    optionValue="name"
                                    placeholder={isLang === "en" ? 'Select your nationality' : ' اختر جنسيتك   '}
                                    className="w-full p-inputtext-sm"


                                />
                                {formikReservationsApi.errors.nationality && formikReservationsApi.touched.nationality ?
                                    <Message severity="error" text={formikReservationsApi.errors.nationality} /> : null
                                }
                            </div>
                        </>
                    }
                    {
                        formikReservationsApi.values.idtype == 2 &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col  mt-3">
                                <label htmlFor="email" className="font-bold block mb-2"> {isLang === "en" ? ' Email' : '  البريد الالكترونى   '}    </label>
                                <InputText
                                    id="email"
                                    name="email"
                                    type="text"
                                    onBlur={formikReservationsApi.handleBlur}
                                    onChange={formikReservationsApi.handleChange}
                                    className="w-full p-inputtext-sm"
                                    dir={isLang === "en" ? 'ltr' : 'rtl'}
                                    required
                                    value={formikReservationsApi.values.email}
                                />
                                {formikReservationsApi.errors.email && formikReservationsApi.touched.email ?
                                    <Message severity="error" text={formikReservationsApi.errors.email} /> : null
                                }
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col  mt-3">
                                <label htmlFor="license_num" className="font-bold block mb-2"> {isLang === "en" ? ' License number' : 'رقم الرخصة'}    </label>
                                <InputText
                                    id="license_num"
                                    name="license_num"
                                    type="text"
                                    onBlur={formikReservationsApi.handleBlur}
                                    onChange={formikReservationsApi.handleChange}
                                    className="w-full p-inputtext-sm"
                                    dir='ltr'
                                    required
                                    value={formikReservationsApi.values.license_num}
                                />
                                {formikReservationsApi.errors.license_num && formikReservationsApi.touched.license_num ?
                                    <Message severity="error" text={formikReservationsApi.errors.license_num} /> : null
                                }
                            </div>

                            <div className="lg:col-6 md:col-12 sm:col-12 xs:col-12 input__Col  mt-3">
                                <label htmlFor="nationalId" className="font-bold block mb-2"> {isLang === "en" ? 'Passport number' : 'رقم جواز السفر'}    </label>
                                <InputText
                                    id="nationalId"
                                    name="nationalId"
                                    type="text"
                                    onBlur={formikReservationsApi.handleBlur}
                                    onChange={formikReservationsApi.handleChange}
                                    className="w-full p-inputtext-sm"
                                    dir='ltr'
                                    required
                                    value={formikReservationsApi.values.nationalId}
                                />
                                {formikReservationsApi.errors.nationalId && formikReservationsApi.touched.nationalId ?
                                    <Message severity="error" text={formikReservationsApi.errors.nationalId} /> : null
                                }
                            </div>
                        </>
                    }


                    <Col xl={12} lg={12} md={12} sm={12} className='payment_footer_btn payment_footer_btn2  gap-5 flex flex-row align-items-end'>
                        <Row className='w-100 payment_footer_btn_gap'>
                            <Col xl={5} lg={5} md={6} sm={12}  >
                                <Link to={`${localStorage.getItem('path')}`}>
                                    <div className="car_back   w-100" >
                                        <Button type='submit' className='w-12' icon={`pi ${isLang === "en" ? 'pi-caret-left' : 'pi-caret-right'}`} dir={isLang === "en" ? 'ltr' : 'rtl'}
                                            label={isLang === "en" ? 'Back to cars ' : '    الرجوع للسيارات'} severity="secondary" />
                                    </div>
                                </Link>
                            </Col>
                            <Col xl={7} lg={7} md={6} sm={12} >
                                <div className="btn_pay w-100">
                                    <Button type='submit' className='w-12' icon={`pi ${isLang === "en" ? 'pi-caret-right' : 'pi-caret-left'}`} dir='ltr' label={isLang === "en" ? 'Continuing booking' : 'استمرار الحجز'} severity="secondary" />
                                </div>
                            </Col>

                        </Row>
                    </Col>


                </Row>

            </form>
        </>
    );
};

export default ReservationsBefore;
