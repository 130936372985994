import React from 'react'
import { Outlet } from 'react-router-dom';

const Auth = () => {
    return (
        <>
            <Outlet ></Outlet>
        </>
    )
}

export default Auth
