import { Player } from '@lottiefiles/react-lottie-player';
import React, { useContext } from 'react';
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';

const HandePage = ({ src ,msg,msgAr}) => {
  let { isLang } = useContext(LocalizationContext);

    return (
        <>
            <Component.Title title={isLang === "en" ?msg : msgAr} />
            <div className="app__error">
                <div className="Player">
                    <Player
                        className='w-100'
                        // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
                        src={src}
                        autoplay
                        loop
                    />
                </div>
            </div>
        </>
    )
}

export default HandePage