import axios from 'axios';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import img from '../../../constants/Img';
import { LocalizationContext } from '../../../context/LangChange';
import { Checkbox } from 'primereact/checkbox';
import { CarFilterStates } from '../../../context/FiltersCars';
import { SharedData } from '../../../context/CallApi';
import { format } from 'date-fns';
import cheerio from 'cheerio';


const PaymentModal = ({ detailsCars, overKM, deliveryType, promoCodeValue, totalPrice, CalcDays, selectedStartDate, selectedEndDate, promoType, promoValue, promoCheck, priceTax, DeliveryExpenses, carName, carImage, show, setModalShow, onHide, getReservationsData, price }) => {
    let { setInvoice, booking, userLocationMap } = useContext(CarFilterStates);
    let { fetchPersonal } = useContext(SharedData);
    let { fetchPersonalData } = useContext(SharedData);

    let { isLang } = useContext(LocalizationContext);
    // حساب قيمة مصاريف التوصيل
    const [deliveryCharges, setDeliveryCharges] = useState(null);
    const [modalDone, setModalDone] = useState(false);
    const [calcTax, setcalcTax] = useState(null);
    const [calcTotalPricePerDay, setcalcTotalPricePerDay] = useState(null);
    const [discountsPromoWithPrice, setDiscountsPromoWithPrice] = useState(false);
    const [checked, setChecked] = useState(false);
    const [loadingPay, setLoadingPay] = useState(false);


    const handleTotalPrice = () => {


        if (promoType === 0) {
            let pricePromo = totalPrice * (promoValue / 100);
            setDiscountsPromoWithPrice(pricePromo)
            if (deliveryType === 0) {
                let total = totalPrice - pricePromo
                let totalWithTax = total * priceTax
                setcalcTax(totalWithTax)
                if (getReservationsData?.cityTo != getReservationsData?.cityFrom) {
                    setcalcTotalPricePerDay(((totalPrice) - pricePromo) + totalWithTax + 150)
                } else {
                    setcalcTotalPricePerDay(((totalPrice) - pricePromo) + totalWithTax)
                }

            } else {
                let total = totalPrice - pricePromo
                let totalWithTax = total * priceTax
                setcalcTax(totalWithTax)
                setcalcTotalPricePerDay(((totalPrice + DeliveryExpenses?.value) - pricePromo) + totalWithTax)

            }


        } else if (promoType === 1) {
            setDiscountsPromoWithPrice(promoValue)
            if (deliveryType === 0) {
                let total = totalPrice - promoValue
                let totalWithTax = total * priceTax
                setcalcTax(totalWithTax)
                setcalcTotalPricePerDay((totalPrice - promoValue) + totalWithTax)
                if (getReservationsData?.cityTo != getReservationsData?.cityFrom) {
                    setcalcTotalPricePerDay((totalPrice - promoValue) + totalWithTax + 150)
                } else {
                    setcalcTotalPricePerDay((totalPrice - promoValue) + totalWithTax)
                }

            } else {
                let total = totalPrice - promoValue
                let totalWithTax = total * priceTax
                setcalcTax(totalWithTax)
                setcalcTotalPricePerDay(((totalPrice + DeliveryExpenses?.value) - promoValue) + totalWithTax)
                setDeliveryCharges(DeliveryExpenses?.value)

            }


        } else {
            if (deliveryType === 0) {
                let totalWithTax = totalPrice * priceTax
                setcalcTax(totalWithTax)

                if (getReservationsData?.cityTo != getReservationsData?.cityFrom) {
                    setcalcTotalPricePerDay(totalPrice + totalWithTax + 150)

                } else {
                    setcalcTotalPricePerDay(totalPrice + totalWithTax)


                }

            } else {
                let totalWithTax = totalPrice * priceTax
                setcalcTax(totalWithTax)
                setcalcTotalPricePerDay((totalPrice + DeliveryExpenses?.value) + totalWithTax)
            }


        }

        // حساب قيمة مصاريف التوصيل 
        if (deliveryType == 0) {
            if (getReservationsData?.cityTo != getReservationsData?.cityFrom) {
                setDeliveryCharges(parseFloat(calcTax).toFixed(2) <= 0 ? 0 : 150)
            }
        } else {
            if (overKM) {
                setDeliveryCharges(DeliveryExpenses?.value !== 0 ? parseFloat(DeliveryExpenses?.value).toFixed(2) : 0)
            }
        }
    };


    useEffect(() => {
        handleTotalPrice()

        return () => {
            handleTotalPrice()
        }

    }, [getReservationsData?.cityFrom, getReservationsData?.cityTo, promoValue, priceTax, deliveryType, userLocationMap, selectedStartDate, selectedEndDate])


    useEffect(() => {
        handleTotalPrice()
        return () => {
            handleTotalPrice()
        }
    }, [userLocationMap, DeliveryExpenses?.value])
    // branchId
    // deliveringId
    let navigate = useNavigate()
    const handelReservations = async () => {

        const deliveringIdParam = getReservationsData?.deliveringId ? `&deliveringId=${getReservationsData?.deliveringId}` : '';
        const promoCodeParam = promoCodeValue ? `&promo=${promoCodeValue}` : '';
        const birthdate = booking?.birthdate ? `&birthdate=${format(new Date(booking.birthdate), 'dd/MM/yyyy')}` : '&birthdate=';
        const licenseexp = booking?.licenseexp ? `&licenseexp=${format(new Date(booking.licenseexp), 'dd/MM/yyyy')}` : '&licenseexp=';
        const idf1 = booking?.idf1 ? `&idf1=${booking?.idf1}` : '&idf1=';
        const idnum = booking?.idnum ? `&idnum=${booking?.idnum}` : '&idnum=';
        const idf2 = booking?.idf2 ? `&idf2=${booking?.idf2}` : '&idf2=';
        const lic1 = booking?.lic1 ? `&lic1=${booking?.lic1}` : '&lic1=';
        const lic2 = booking?.lic2 ? `&lic2=${booking?.lic2}` : '&lic2=';
        const idtype = booking?.idtype ? `&idtype=${booking?.idtype}` : '&idtype=';

        const lat = userLocationMap?.lat ? `&lat=${parseFloat(userLocationMap?.lat)}` : '&lat=';
        const lang = userLocationMap?.lng ? `&lang=${parseFloat(userLocationMap?.lng)}` : '&lang=';
        const startTime = getReservationsData?.startTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
        const endTime = getReservationsData?.endTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })

        const formattedStartTime = startTime.replace(/([0-9]{2}:[0-9]{2}).*/, '$1');
        const formattedEndTime = endTime.replace(/([0-9]{2}:[0-9]{2}).*/, '$1');


        if (getReservationsData?.payType === 1) {
            setLoadingPay(true)
            const url = `${process.env.REACT_APP_API_URL}/tempreservations?carId=${getReservationsData?.carId}${birthdate}&startTime=${formattedStartTime}&endTime=${formattedEndTime}&delv=${deliveryCharges}&authorized=${getReservationsData?.authorized}&driver=${getReservationsData?.driver}${licenseexp}${idf1}${idf2}${lic1}${lic2}${idtype}${idnum}${lang}${lat}&clientId=${getReservationsData?.clientId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&cityId=${getReservationsData?.cityId}&address=${getReservationsData?.address}&branchId=${getReservationsData?.branchId}${deliveringIdParam}&type=${getReservationsData?.type}&payType=${getReservationsData?.payType}${promoCodeParam}&price=${price * CalcDays}&tax=${calcTax}&total=${calcTotalPricePerDay <= 0 ? 0 : calcTotalPricePerDay}&days=${CalcDays}&lim_type=0&os=web`;
            let { data } = await axios.post(url)

            if (data?.status) {
                localStorage.removeItem('res_number')
                setInvoice(data);
                localStorage.setItem('res_number', data?.res_number)

                fetchPersonal()
                setTimeout(() => {
                    methodPayment(
                        {
                            IDReservation: data?.res_number,
                            Name: fetchPersonalData?.first_name + '  ' + fetchPersonalData?.last_name,
                            Phone: fetchPersonalData?.mobile ? fetchPersonalData?.mobile : null,
                            Email: fetchPersonalData?.email ? fetchPersonalData?.email : null,
                            Amount: data?.total
                        }
                    )
                }, 1500);

            }


        } else {
            setLoadingPay(true)

            const url = `${process.env.REACT_APP_API_URL}/reservations?carId=${getReservationsData?.carId}${birthdate}&startTime=${startTime}&endTime=${endTime}&delv=${deliveryCharges}&authorized=${getReservationsData?.authorized}&driver=${getReservationsData?.driver}${licenseexp}${idf1}${idf2}${lic1}${lic2}${idtype}${idnum}${lang}${lat}&clientId=${getReservationsData?.clientId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&cityId=${getReservationsData?.cityId}&address=${getReservationsData?.address}&branchId=${getReservationsData?.branchId}${deliveringIdParam}&type=${getReservationsData?.type}&payType=${getReservationsData?.payType}${promoCodeParam}&price=${price * CalcDays}&tax=${calcTax}&total=${calcTotalPricePerDay <= 0 ? 0 : calcTotalPricePerDay}&days=${CalcDays}&lim_type=0&os=web`;
            let { data } = await axios.post(url)
            if (data?.status) {
                localStorage.removeItem('res_number')
                setInvoice(data);
                setModalShow(false)
                setModalDone(true)
                const timeOut = setTimeout(() => {
                    navigate(`/${isLang}/thanks-page/order/${data?.res_number}?lim=0`)
                    fetchPersonal()
                    setLoadingPay(true)

                }, 2000);
                setLoadingPay(false)

                return () => clearTimeout(timeOut);

            }
        }


    }

    const methodPayment = async (invoice) => {
        try {
            const response = await axios.post('https://backend.betterway-egypt.com/api/admin/test', invoice, {});
            const $ = cheerio.load(response.data);
            const redirectLink = $('meta[http-equiv="refresh"]').attr('content').match(/url='(.*?)'/)[1];
            // Set the current page's URL to the new URL
            setTimeout(function () {
                window.location.href = redirectLink;
                setLoadingPay(false)
            }, 1000);

        } catch (error) {
            setLoadingPay(false)

        }
    };




    return (
        <div className='app__wallet app__payment_summry'>
            <Modal
                show={show}
                onHide={onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Row dir={isLang === "en" ? 'ltr' : 'rtl'}>
                        <Col xl={6} lg={6} md={12} sm={12}  >
                            <div className="app__payment_getway flex h-100 flex-column justify-content-center">
                                <div className="header w-100">
                                    <h3>{isLang === "en" ? 'Order summary' : 'ملخص الطلب'}</h3>
                                    <hr />
                                </div>
                                <div className="app__payment_summary1">
                                    <div className="summary-right">
                                        <img src={carImage} className='w-100' alt="images" loading='lazy' />
                                    </div>
                                    <div className="summary-left">
                                        <div className="content">
                                            <div className="content1">
                                                <h3>{carName}</h3>
                                                <h2>{parseFloat(price).toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                            </div>
                                            <div className="content2">
                                                <h3>{isLang === "en" ? 'Rental term' : 'مدة الإيجار'}</h3>
                                                <h2>  {CalcDays} {isLang === "en" ? 'Days' : 'يوم'}  </h2>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="app__payment_summary_date  flex flex-column">
                                    <div className="summary-left">
                                        <div className="content2 flex flex-row justify-content-between w-100">
                                            <h3>{isLang === "en" ? 'Start date  ' : 'تاريخ بداية الايجار'}</h3>
                                            <h2>  {selectedStartDate}   </h2>
                                        </div>
                                        <div className="content2 flex flex-row justify-content-between w-100">
                                            <h3>{isLang === "en" ? 'end date  ' : ' تاريخ نهاية الايجار'}</h3>
                                            <h2>  {selectedEndDate}   </h2>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="app__payment_summary2">
                                    <div className="  content1 ">
                                        <div className="content1_title">
                                            <span>{isLang === "en" ? 'Total Amount' : 'إجمالى المبلغ'}</span>
                                        </div>
                                        <div className="content1_price">
                                            <h2>{parseFloat(totalPrice)?.toFixed(2) <= 0 ? 0 : parseFloat(totalPrice)?.toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                        </div>
                                    </div>
                                    {
                                        promoCheck &&
                                        <div className="content1">
                                            <div className="content1_title">
                                                <span>{isLang === "en" ? 'Promo code discount ' : 'قيمة خصم البرومو كود'}</span>
                                            </div>
                                            <div className="content1_price">
                                                <h2>{parseFloat(discountsPromoWithPrice)?.toFixed(2) <= 0 ? 0 : parseFloat(discountsPromoWithPrice)?.toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                            </div>
                                        </div>
                                    }

                                </div>
                                <hr />
                                <div className="app__payment_summary3">
                                    <div className="  content2  flex flex-row ">
                                        <div className="content2_title w-100 ">
                                            <span>{isLang === "en" ? 'Tax amount' : 'قيمة الضريبة'}</span>
                                        </div>
                                        <div className="content2_discount flex justify-content-between flex-row w-100">
                                            <h3>{getReservationsData?.tax * 100}% </h3>
                                            <h2>{parseFloat(calcTax).toFixed(2) <= 0 ? 0 : parseFloat(calcTax).toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                        </div>
                                    </div>
                                    {
                                        deliveryType == 0 && <>
                                            {
                                                (getReservationsData?.cityTo != getReservationsData?.cityFrom) &&
                                                <div className="  content2  flex flex-row ">
                                                    <div className="content2_title w-100 ">
                                                        <span className='m-0 p-0'>{isLang === "en" ? 'Delivery charges ' : 'مصاريف التوصيل'}</span>
                                                    </div>
                                                    <div className="content2_discount flex justify-content-end flex-row w-100 ">
                                                        <h2>{parseFloat(calcTax).toFixed(2) <= 0 ? 0 : 150} {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }

                                    {
                                        deliveryType !== 0 &&
                                        <>
                                            <div className="content2 flex flex-row w-100 m-0 p-0 justify-content-between">
                                                {
                                                    overKM &&
                                                    <>
                                                        <div className="content2_title  w-100 m-0 p-0 ">
                                                            <span className='m-0 p-0'>{isLang === "en" ? 'Delivery charges ' : 'مصاريف التوصيل'}</span>
                                                        </div>
                                                        <div className="content2_discount  m-0 p-0 flex justify-content-between flex-row w-100">
                                                            {
                                                                DeliveryExpenses?.value !== 0 ?
                                                                    <h3 className='m-0 p-0'>{parseFloat(DeliveryExpenses?.km).toFixed(2)} {' '} {isLang === "en" ? 'Km' : 'كم'} </h3> :
                                                                    <h3 className='m-0 p-0'>  </h3>
                                                            }
                                                            {
                                                                DeliveryExpenses?.value !== 0 ?
                                                                    <h2 className='m-0 p-0'>{parseFloat(DeliveryExpenses?.value).toFixed(2)} {isLang === "en" ? 'SR' : 'ريال'} </h2> :
                                                                    <div className="app__payment_summary_date  flex flex-column">
                                                                        <div className="summary-left">
                                                                            <div className="content2 flex flex-row justify-content- w-100">
                                                                                <span className='m-0 p-0 text-red-400'>{isLang === "en" ? 'Free delivery' : 'التوصيل مجانا '}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }

                                                        </div>
                                                    </>
                                                }
                                            </div>

                                        </>
                                    }

                                    {
                                        overKM &&
                                        <div className="content3  mt-4 flex flex-row justify-content-between align-items-center">
                                            <div className="content3_title">
                                                <span>{isLang === "en" ? 'Total Amount' : 'إجمالى المبلغ'}</span>
                                            </div>
                                            <div className="content3_price">
                                                <h2>   {parseFloat(calcTotalPricePerDay).toFixed(2) <= 0 ? 0 : parseFloat(calcTotalPricePerDay).toFixed(2)}  {isLang === "en" ? 'SR' : 'ريال'} </h2>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="btn_pay mt-6  w-100">
                                    <div className="flex  gap-2 align-items-center  mb-3">
                                        {
                                            overKM && <>
                                                <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={e => setChecked(e.checked)} checked={checked} className='m-0 p-0' />
                                                <label htmlFor="ingredient1" className="  text_box p-0 m-0 ">  {isLang === "en" ? 'Deposit amount will be paid at the branch before receiving the car' : 'سيتم دفع مبلغ تأميني في الفرع قبل استلام السيارة'}  </label>
                                            </>
                                        }
                                    </div>
                                    {
                                        getReservationsData?.payType === 1 ?
                                            <Button onClick={(e) => { handelReservations(e) }} loading={loadingPay} disabled={!checked} className='w-12' dir='ltr'
                                                label={overKM ? isLang === "en" ? 'Pay now' : '   إدفــع الان' : isLang === "en" ? 'I have exceeded the maximum number of kilometers allowed for delivery.' : '. لقد تجاوزت الحد الاقصي لعدد الكيلومترات المسموح بيها للتوصيل '}
                                                severity="warning" /> :
                                            <Button
                                                loading={loadingPay}
                                                onClick={(e) => { handelReservations(e) }}
                                                disabled={!checked} className='w-12' dir='ltr'
                                                label={overKM ? isLang === "en" ? 'Confirmation' : 'تـــاكيد الطلب' : isLang === "en" ? 'I have exceeded the maximum number of kilometers allowed for delivery.' : '. لقد تجاوزت الحد الاقصي لعدد الكيلومترات المسموح بيها للتوصيل '}
                                                severity="warning" />
                                    }

                                </div>
                                <span className='mt-5 info_after_payment text-center'>
                                    {
                                        isLang === "en" ? 'Your personal data will be used to process your order and support your experience throughout this website and for other purposes described in our Privacy Policy.' : "سيتم استخدام بياناتك الشخصية لمعالجة طلبك ودعم تجربتك في جميع أنحاء هذا الموقع ولأغراض أخرى موصوفة في سياسة الخصوصية الخاصة بنا."
                                    }
                                </span>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}  >
                            <div className="app__login-image h-100">
                                <img src={img.Bg_Overlay} className='w-100 h-100 ' alt="images" loading='lazy' />
                                <div onClick={() => setModalShow(false)}>
                                    <div className="btn_back cursor-pointer shadow-lg" >
                                        <i className={`pi ${isLang === "en" ? "pi-arrow-right " : "pi-arrow-left"} `}></i>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal
                show={modalDone}
                onHide={() => setModalDone(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body style={{ height: '100vh' }} className='flex justify-content-center align-item-center '>
                    <div style={{ width: '400px' }} className='h-100  flex justify-content-center align-item-center flex-column '>
                        <img src={img.Image_Reservations} alt="image logo" loading='lazy' />
                        <h1 className='text-center text_done_Reservations'>    {isLang === "en" ? 'The order has been confirmed' : 'تم تاكيد الطلب'}</h1>
                    </div>
                </Modal.Body>

            </Modal>


        </div>
    )
}

export default PaymentModal

