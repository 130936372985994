import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect } from 'react';
import { Link, NavLink } from "react-router-dom";
import Icons from "../../constants/Icons";
import img from "../../constants/Img";
import { LocalizationContext } from "../../context/LangChange";
import './Sidebar.scss';
import SidebarMenu from "./SidebarMenu";
import routes from './route.js';


const SidebarSM = () => {
  let { isLang, isOpen, setIsOpen } = useContext(LocalizationContext);


  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: { duration: 0.4, when: "afterChildren" },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.3,
        when: "beforeChildren",
      },
    },
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      <div className=" main-container_nav  " dir={isLang === 'ar' ? 'rtl' : 'ltr'}>
        <motion.div
          dir={isLang === 'ar' ? 'rtl' : 'ltr'}
          animate={{
            width: isOpen ? "160px" : "0px",
            background: '#000',
            transition: {
              duration: 0.7,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar  `}
        >
          <div className="side   " style={{ [isLang === 'ar' ? 'right' : 'left']: 0 }}>
            <div className="top_section  ">
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="logo "
                    key={1}
                  >
                    <Link to={'/'} onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
                      <img loading="lazy" src={img.Logo5} alt='logo' width={129} height={78}  />
                    </Link>
                  </motion.div>
                )}
         
              </AnimatePresence>
            </div>
            <section className={isLang === 'ar' ? 'routes routesAr' : 'routes'}   >
              {
                routes?.map((root, i) => {
                  if (root.subRoutes) {
                    return (
                      <SidebarMenu
                        key={i}
                        setIsOpen={setIsOpen}
                        route={root}
                        showAnimation={showAnimation}
                        isOpen={isOpen}
                        open={isOpen}
                        isLang={isLang}
                      />
                    );
                  }
                  return (
                    <motion.div
                      key={i}
                      animate={{
                        transition: {
                          duration: 2,
                          damping: 10
                        }
                      }}
                    >
                      <NavLink to={root.path} onClick={() => setIsOpen(!isOpen)} key={i} className="link " >
                        <div className="icon" id={root.name} data-tooltip-content={isLang === 'ar' ? root.nameAr : root.nameEn}>
                          {root.icon}
                        </div>

                        <AnimatePresence>
                          {
                            isOpen &&
                            <>
                              <motion.div
                                variants={showAnimation
                                }
                                initial={"hidden"}
                                animate={"show"}
                                exit={"hidden"}
                                className="link_text"
                              >
                                {isLang === 'ar' ? root.nameAr : root.nameEn}
                              </motion.div>
                            </>
                          }
                        </AnimatePresence>
                      </NavLink>
                    </motion.div>
                  )
                })
              }
                    <motion.div
                className="logout"
                animate={{
                  transition: {
                    duration: 2,
                    damping: 10
                  }
                }}
              >
                <NavLink className="link " >
                  <div className="icon"   >
                    <Icons.login style={{ width: 17 }} />
                  </div>
                  <AnimatePresence>
                    {
                      isOpen &&
                      <>
                        <motion.div
                          variants={showAnimation}
                          initial={"hidden"}
                          animate={"show"}
                          exit={"hidden"}
                          className="link_text logout"
                        >
                          <Link to={'/'}>
                            {isLang === 'ar' ? 'تسجيل الخروج' : 'LogOut'}
                          </Link>
                        </motion.div>
                      </>
                    }
                  </AnimatePresence>
                </NavLink>
                <div className="change_lang-sidebar " dir='rtl '>
                  <div className="header">
                  </div>
                  {
                    isLang === "en" ?
                      <div
                        className='app__menu-talk cursor-pointer'
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.8 }}
                        /* onClick={(eventKey) => handleLocalizationChange('ar')} */>
                        عربيه
                        <img src={img.ArLang} alt="images" loading='lazy' />
                      </div> :
                      <div
                        className='app__menu-talk cursor-pointer'
                       /*  onClick={(eventKey) => handleLocalizationChange('en')} */

                      >
                        English
                        <img src={img.EnLang} alt="images"loading='lazy'  />
                      </div>
                  }
                </div>
              </motion.div>
              <hr />

            </section>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default SidebarSM;