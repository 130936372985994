// formikConfig.js
import axios from 'axios';
import { useFormik } from 'formik';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { LocalizationContext } from '../../context/LangChange';
import useValidation from '../auth/Services/useValidation';

const useConnectApi = () => {
  const { validateSendMessage } = useValidation();
  let { isLang } = useContext(LocalizationContext);

  const initialValues = {
    name: '',
    mobile: '',
    email: '',
    message: ''
  }; 
  const onSubmit = async (values ) => {
    const urlRegister = `${process.env.REACT_APP_API_URL}/contactmessage?name=${values.name}&mobile=${values.mobile}&message=${values.message}&email=${values.email}`
    let { data } = await axios.post(urlRegister)
    if (data.status === true) { 
       values.mobile=""
       values.name=""
       values.email=""
       values.message=""
      toast.success(isLang === "en" ? 'Your message has been sent to us, We will contact you soon. ' : 'لقد تم إرسال رسالتك إلينا، سوف نتصل بك قريباً.')
    } else {
     
      toast.error(isLang === "en" ? 'An error occurred while sending' : '  حدث خطا اثناء الارسال ')
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validateSendMessage,
    onSubmit,
  });

  return { formik };
};

export default useConnectApi;
