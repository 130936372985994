import { Button } from 'primereact/button';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icons from '../../../constants/Icons';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import img from './../../../constants/Img';
import './style.scss';
import { Image } from 'primereact/image';

const PersonalData = () => {
  let { isLang } = useContext(LocalizationContext);
  let { fetchPersonalData } = useContext(SharedData)
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
  }, [])

  return (
    <>
      <div className="app__PersonalData">

        <div className="header">
          <div className="img relative">

            <div className={`personal  
            ${fetchPersonalData?.type === 0 && 'type_normal'} 
            ${fetchPersonalData?.type === 1 && ' type_bronze'} 
            ${fetchPersonalData?.type === 2 && ' type_silver'} 
            ${fetchPersonalData?.type === 3 && 'gold_type'} 
            `}>
              {
                fetchPersonalData.image ?
                  <img src={`${fetchPersonalData.image}`} className='w-100' alt="images" loading='lazy' /> :
                  <img src={img.personal} className='w-100' alt="images" loading='lazy' />
              }
            </div>
            {
              fetchPersonalData?.type === 0 &&
              <div className="gold absolute">
                <img src={img.type_normal} alt="images" loading='lazy' />
              </div>
            }
            {
              fetchPersonalData?.type === 1 &&
              <div className="gold absolute">
                <img src={img.type_bronze} alt="images" loading='lazy' />
              </div>
            }
            {
              fetchPersonalData?.type === 2 &&
              <div className="gold absolute">
                <img src={img.type_silver} alt="images" loading='lazy' />
              </div>
            }
            {
              fetchPersonalData?.type === 3 &&
              <div className="gold absolute">
                <img src={img.type_gold} alt="images" loading='lazy' />
              </div>
            }

          </div>
          <div className="Edite">
            <Link
              to={`/account/personal_data/edit`} state={{ data: fetchPersonalData }}         >
              <Button icon='pi pi-cog' label={isLang === "en" ? 'Edit Profile' : 'تعديل الحساب '} />
            </Link>
          </div>
        </div>
        <div className="info mt-35">
          <h3>   {fetchPersonalData?.first_name + ' ' + fetchPersonalData?.last_name}     </h3>
          {
            fetchPersonalData?.type === 1 &&
            <span className='text_client_bronze'>( {isLang === "en" ? 'Bronze client' : 'عميل برونزي'})</span>
          }
          {
            fetchPersonalData?.type === 2 &&
            <span className='text_client_silver'>( {isLang === "en" ? 'Silver client' : 'عميل فضي '})</span>
          }
          {
            fetchPersonalData?.type === 3 &&
            <span className='text_client_gold'>( {isLang === "en" ? 'Gold client' : 'عميل ذهبي '})</span>
          }

        </div>

        {/* <div className="mt-30 info_content">
          <Component.Title title={isLang === "en" ? 'Job title' : 'المسمى الوظيفى'} />
          <p className='w-100 mt-2'>لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ،</p>
        </div>

        <div className="mt-35 info_content">
          <Component.Title title={isLang === "en" ? 'work place' : '  محل العمل    '} />
          <p className='w-100 mt-2'>لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ،</p>
        </div> */}

        <div className="info_contactus mt-35">
          <Row>
            {fetchPersonalData?.mobile &&
              <Col xl={6} lg={6} md={6} sm={12} className='mt-4 '>
                <div className="conatent ">
                  <div className="icons">
                    <Icons.CallBold />
                  </div>
                  <div>
                    <div className="header"> {isLang === "en" ? 'Mobile Number' : '   رقم الجوال '}   </div>
                    <div className="body" dir='ltr'> {fetchPersonalData?.mobile}  </div>
                  </div>
                </div>
              </Col>

            }
            {fetchPersonalData?.nationalId &&
              <Col xl={6} lg={6} md={6} sm={12} className='mt-4 '>
                <div className="conatent ">
                  <div className="icons">
                    <Icons.Blogs />
                  </div>
                  <div>
                    <div className="header">   {isLang === "en" ? 'ID Number  ' : ' رقم الهوية  '}</div>
                    <div className="body" dir='ltr'> {fetchPersonalData?.nationalId}   </div>
                  </div>
                </div>
              </Col>
            }
            <Col xl={6} lg={6} md={6} sm={12} className='mt-4 '>
              <div className="conatent ">
                <div className="icons">
                  <Icons.Email2 />
                </div>
                <div>
                  <div className="header">{isLang === "en" ? 'E-mail' : '   البريد الالكترونى   '}  </div>
                  <div className="body"> {fetchPersonalData?.email} </div>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className='mt-4'>
              <div className="conatent ">
                <div className="icons">
                  <i className='pi pi-users'></i>
                </div>
                <div>
                  <div className="header">   {isLang === "en" ? ' Nationality  ' : ' الجنسيه    '} </div>
                  <div className="body">{fetchPersonalData?.nationality}       </div>
                </div>
              </div>
            </Col>

            {fetchPersonalData?.birthdate &&
              <Col xl={6} lg={6} md={6} sm={12} className='mt-4'>
                <div className="conatent ">
                  <div className="icons">
                    <i className='pi  pi-calendar'></i>
                  </div>
                  <div>
                    <div className="header">   {isLang === "en" ? ' Birthdate  ' : 'تاريخ الميلاد'} </div>
                    <div className="body">{fetchPersonalData?.birthdate}       </div>
                  </div>
                </div>
              </Col>
            }

   {/*          {fetchPersonalData?.licexp &&
              <Col xl={6} lg={6} md={6} sm={12} className='mt-4'>
                <div className="conatent ">
                  <div className="icons">
                    <i className='pi  pi-calendar'></i>
                  </div>
                  <div>
                    <div className="header">{isLang === "en" ? ' License Expiry Date  ' : 'تاريخ انتهاء الرخصة'}</div>
                    <div className="body">{fetchPersonalData?.licexp}</div>
                  </div>
                </div>
              </Col>
            } */}

            {fetchPersonalData?.idtype &&
              <>
                <Col xl={6} lg={6} md={6} sm={12} className='mt-4'>
                  <div className="conatent ">
                    <div className="icons">
                      <i className='pi  pi-flag '></i>
                    </div>
                    <div>
                      <div className="header">{isLang === "en" ? 'Identity type' : 'نوع الهوية '}</div>
                      <div className="body">{fetchPersonalData?.idtype === 0 ? isLang === 'en' ? "National number" : "هوية وطنية" : isLang === 'en' ? "Residence" : "إقامة"}</div>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className='mt-4'></Col>
              </>
            }

            {/* {fetchPersonalData?.idface1 &&
              <Col xl={6} lg={6} md={6} sm={12} className='mt-4'>
                <div className="conatent flex align-items-start flex-column">
                  <div className='conatent'>
                    <div className="icons">
                      <i className='pi  pi-id-card'></i>
                    </div>
                    <div className="header">{isLang === "en" ? '  National ID card (front side):' : '    بطاقة الهوية الوطنية (الجانب الأمامي)'}</div>
                  </div>
                  <div className="body flex justify-content-center">
                    <Image src={fetchPersonalData?.idface1} alt="Image" width="250" preview />
                  </div>
                </div>
              </Col>
            }

            {fetchPersonalData?.idface2 &&
              <Col xl={6} lg={6} md={6} sm={12} className='mt-4'>
                <div className="conatent flex align-items-start flex-column">
                  <div className='conatent'>
                    <div className="icons">
                      <i className='pi  pi-id-card'></i>
                    </div>
                    <div className="header">{isLang === "en" ? '  National ID card (front side):' : '    بطاقة الهوية الوطنية (الجانب الأمامي)'}</div>
                  </div>
                  <div className="body flex justify-content-center">
                    <Image src={fetchPersonalData?.idface2} alt="Image" width="250" preview />
                  </div>
                </div>
              </Col>
            } */}

            {/*            {fetchPersonalData?.lic1 &&
              <Col xl={6} lg={6} md={6} sm={12} className='mt-4'>
                <div className="conatent flex align-items-start flex-column">
                  <div className='conatent'>
                    <div className="icons">
                      <i className='pi pi-id-card'></i>
                    </div>
                    <div className="header">{isLang === "en" ? 'Driver`s licence (front side)' : 'رخصة القيادة (الجانب الامامي) '}</div>
                  </div>
                  <div className="body flex justify-content-center">
                    <Image src={fetchPersonalData?.lic1} alt="Image" width="250" preview />
                  </div>
                </div>
              </Col>
            } */}

            {/*        {fetchPersonalData?.lic2 &&
              <Col xl={6} lg={6} md={6} sm={12} className='mt-4'>

                <div className="conatent flex align-items-start flex-column">
                  <div className='conatent'>
                    <div className="icons">
                      <i className='pi  pi-id-card'></i>
                    </div>
                    <div className="header">{isLang === "en" ? 'Driver`s licence (back side)' : 'رخصة القيادة (الجانب الخلفي) '}</div>

                  </div>
                  <div className="body flex justify-content-center">
                    <Image src={fetchPersonalData?.lic2} alt="Image" width="250" preview />
                  </div>
                </div>
              </Col>
            }  */}
          </Row>
        </div>

      </div>
    </>
  )
}

export default PersonalData
