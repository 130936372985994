import axios from "axios";

//   Validate From handel Login
export const handelLogin = async (values, setLoadmobile) => {
    const url = `${process.env.REACT_APP_API_URL}/login?mobile=${values.mobile}&password=${values.password}`;

    setLoadmobile(true);
    let { data } = await axios.post(url).catch((err) => {
        setLoadmobile(false);
    })
    if (data.msg === "CLIENT_FOUND") {
        localStorage.setItem('client_id', data?.client_id)
        localStorage.setItem('client_email', data?.email)
        localStorage.setItem('client_image', data?.image)
        localStorage.setItem('first_name', data?.first_name)
        localStorage.setItem('CLIENT_FOUND', data?.msg)
        setTimeout(() => {
            setLoadmobile(false);
        }, 3000);
        return { checkFirstLogin: data?.show_msg, severity: 'success', summary: 'Success', detail: 'Your login has been successful', detailAr: 'لقد تم تسجيل دخولك بنجاح', closeModal: 'CLIENT_FOUND', client_id: data?.client_id };

    } else if (data.msg === "CLIENT_NOT_FOUND") {
        setLoadmobile(false);
        return { checkFirstLogin: data?.show_msg, severity: 'error', summary: 'Error', detail: 'phone or password incorrect', detailAr: 'الهاتف أو كلمة المرور غير صحيحة' };
    } else if (data.msg === "CLIENT_NOT_ACTIVE") {
        setLoadmobile(false);
        return { checkFirstLogin: data?.show_msg, severity: 'warn', summary: '', detail: 'Your account needs to be activated', detailAr: 'حسابك  بحاجة إلى تنشيط' };
    }  
};
export const handelRegister = async (values, setLoadmobile, isLang) => {
    // const urlRegister = `${process.env.REACT_APP_API_URL}/register?mobile=${values.mobile}&email=${values.email}&password=${values.password}&nationalId=${values.nationalId}&firstName=${values.firstName}&lastName=${values.lastName}&gender=${values.gender}&nationality=${values.nationality}&address=${values.address}&cityId=${values.cityId}&type=0`
    const urlRegister = `${process.env.REACT_APP_API_URL}/register?mobile=${values.mobile}&email=${values.email}&password=${values.password}&firstName=${values.firstName}&lastName=${values.lastName}&gender=${values.gender?values.gender:0}&nationality=${values.nationality}&address=${values.address}&cityId=${values.cityId}&type=0`
    setLoadmobile(true);

    let { data } = await axios.post(urlRegister).catch((err) => {
        setLoadmobile(false);
    })

    if (data.status === true) {
        setTimeout(() => {
            setLoadmobile(false);
        }, 1500);
        return { severity: 'success', summary: 'Success', detail: 'Account successfully created', closeModal: true };

    } else {
        setTimeout(() => {
            setLoadmobile(false);
        }, 1500);
        if (data.msg === 'MOBILE_EXIST') {
            return { severity: 'error', summary: 'error', detail: isLang === "en" ? 'Mobile number used' : 'رقم الجوال موجود مسبقا', closeModal: 'CLIENT_FOUND', closeModal: false };

        } else if (data.msg === 'EMAIL_EXIST') {
            return { severity: 'error', summary: 'error', detail: isLang === "en" ? 'Email number used' : '  بريد الإلكتروني موجود مسبقا', closeModal: 'CLIENT_FOUND', closeModal: false };

        } else {
            return { severity: 'error', summary: 'error', detail: data.msg, closeModal: 'CLIENT_FOUND', closeModal: false };

        }



    }
};

export default {
    handelLogin,
    handelRegister
};
