import React, { useContext } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import src from '../../assets/page notfound.json'
import { Link } from 'react-router-dom';
import { LocalizationContext } from '../../context/LangChange';
const NotFound = () => {
  let { isLang } = useContext(LocalizationContext);

  return (
    <div>
      <div className="app__error flex flex-column vh-100">
        <Player
          className='w-25'
          // src="https://assets3.lottiefiles.com/packages/lf20_ZJR0xnKKys.json"
          src={src}
          autoplay
          loop
        />
        <Link to={`/${isLang}/account/manage`}>
          <button type="button" width="300px" className="success_btn px-5">
            {isLang == "en" ? 'Back To Home Page' : 'العودة إلى الصفحة الرئيسية'}
          </button>
        </Link>
      </div>
    </div>
  )
}

export default NotFound
