import React, { useContext } from 'react';
import { LocalizationContext } from '../../context/LangChange';

const Title = ({ title }) => {
  let { isLang } = useContext(LocalizationContext);

  return (
    <div className='app__title' dir={isLang === "en" ? 'ltr' : 'rtl'}>
      <div style={{ height: '45px' }} >
        <div className='after'></div>
      </div>
      <h1>{title}</h1>
    </div>
  )
}

export default Title
