import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import React, { useContext, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import address from '../../../assets/Bookings.json';
import HandePage from '../../../components/NotFound/HandePage';
import Icons from '../../../constants/Icons';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import './style.scss';

const ManageReservations = () => {
  let { isLang, isOpen, setReservationsStartDate, setReservationsEndDate, setReservationsTotal } = useContext(LocalizationContext);

  let { fetchPersonalData, fetchPersonal } = useContext(SharedData)
  useEffect(() => {
    fetchPersonal()

    return () => {
      fetchPersonal()
    }
  }, [])
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
  }, []);

  return (
    <div className="app__booking">
      <Container>
        {
          fetchPersonalData?.reservations?.length > 0 ?
            <Row>
              {
                fetchPersonalData?.reservations?.map((item, index) => (
                  <Col key={index} xl={4} lg={4} md={6} sm={12} className='mt-3'>
                    <div className={`  ${isOpen ? 'app__booking_xs' : ''}`} >

                      <Card
                        title={`${isLang === "en" ? item.brand : item.brand_arabic} ${isLang === "en" ? item.sub_brand : item.sub_brand_arabic}`}
                        header={<img src={item.image} className='w-100 IMA_CAR' alt="images" loading='lazy' />}
                      >

                        <div className="slider_card" dir={isLang === "en" ? 'ltr' : 'rtl'}>
                          <ul>
                            <li>
                              <Icons.SliderCarSedan />
                              <strong className='bold'>{item.year} {' '} {isLang === "en" ? item.type : item.type_arabic}  </strong>
                            </li>
                            <li>
                              <Icons.CarRent />

                              {
                                item.res_type === 1 ?
                                  <span>  {isLang === "en" ? 'Limousine' : 'ليموزين'}   </span> :
                                  <span>  {isLang === "en" ? 'Individuals' : 'افراد '}   </span>
                              }
                            </li>
                            {
                              item?.res_type === 1 ?
                                <li>
                                  <Icons.SliderCarMoney />
                                  <span>{item.lim_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                  <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                </li> :
                                <li>
                                  <Icons.SliderCarMoney />

                                  {item.offer !== 0 && <span className='app__discount'>{item.price} {isLang === "en" ? 'SR' : 'ريال'} </span>}
                                  {
                                    item.offer === 0 ?
                                      <span>{item.price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                      :
                                      <span>{item.offer_price} {isLang === "en" ? 'SR' : 'ريال'}</span>
                                  }
                                  <span className='oneDay mr-4' >  {isLang === "en" ? 'Per day/tax included' : 'لليوم / شامل الضريبة'}</span>
                                </li>
                            }

                            <li>
                              <Icons.SliderCarPeople />
                              <span>{item.pasengers} {isLang === "en" ? 'Pasengers' : 'راكب'}   </span>
                            </li>
                            {item.aircondition !== 0 && <li>
                              <Icons.SliderCarSoil />
                              <span>    {isLang === "en" ? 'Aircondition' : 'مكيفة'} </span>
                            </li>}

                            {item.aircondition === 0 && <li>
                              <Icons.SliderCarSoil />
                              <span>    {isLang === "en" ? 'Non-air conditioned' : 'غير مكيفة'} </span>
                            </li>}

                          </ul>
                          <div className="order   flex justify-content-end  " >
                            {
                              item?.status == 5 ?
                                <Button label={isLang === "en" ? 'Cancelled' : 'تم الإلغاء'} size='small' severity="warning" /> :

                                <Link to={`/${isLang}/cars/car_details/${item.res_id}?res=current-booking&pay=${item?.pay_type}`}
                                  onClick={() => {
                                    setReservationsStartDate(item.start_date)
                                    setReservationsEndDate(item.end_date)
                                    setReservationsTotal(item.total)
                                    localStorage.setItem('res', 'current-booking')
                                    localStorage.setItem('pay', item?.pay_type)
                                  }}>

                                  <Button label={isLang === "en" ? 'Details' : 'تفاصيل الحجز '} size='small' severity="warning" />
                                </Link>
                            }
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Col>

                ))
              }
            </Row> :
            <HandePage src={address} msg={'You have no previous  booking'} msgAr={'لا يوجد لديك حجوزات   '} />

        }
      </Container>
    </div>
  )
}

export default ManageReservations
