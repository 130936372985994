import AppDownload from "../assets/Images/AppBg.png";
import AppStoreLight from "../assets/Images/appstore (1).png";
import appstore3 from "../assets/Images/appstore.png";
import BgCars from "../assets/Images/BgCars.png";
import BgOffersPage from "../assets/Images/BgOffersPage.png";
import ContactBg from "../assets/Images/contactUs.png";
import GoogleplayLight from "../assets/Images/googleplay (1).png";
import Googleplay from "../assets/Images/googleplay.png";
import Logo from "../assets/Images/Logo.png";
import Logo2 from "../assets/Images/Logo2.png";
import Logo3 from "../assets/Images/Logo3.png";
import Logo4 from "../assets/Images/Logo4.png";
import Logo5 from "../assets/Images/Logo5.png";
import LogoEn from "../assets/Images/logoEn.png";
import MobileApp from "../assets/Images/Mobile app.png";
import BgAbout from "../assets/Images/AboutBg.png";
import ArLang from "../assets/Images/Ar.png";
import BgConnect from "../assets/Images/BgConnect.png";
import BgOffers from "../assets/Images/bgOffers.png";
import RedisterBg from "../assets/Images/bgRedister.png";
import EnLang from "../assets/Images/En.png";
import BgJobs from "../assets/Images/FrameBgJobs.png";
import LoginBg from "../assets/Images/LoginBg.png";
import type_bronze from "../assets/Images/type_bronze.png";
import type_gold from "../assets/Images/type_gold.png";
import type_normal from "../assets/Images/type_normal.png";
import type_silver from "../assets/Images/type_silver.png";
import Bg_Overlay from "../assets/Images/bg_overlay.png";
import DetailsOffers from "../assets/Images/DetailsOffers.png";
import personal from "../assets/Images/Saudi Avatar.jpg";
import Image_Reservations from "../assets/Images/Image_Reservations.png";
import Discount_codes1 from "../assets/Images/discount_codes1.png";
import OtpBg from "../assets/Images/otp.png";



const img = { 
    Image_Reservations, 
    Discount_codes1,
    type_gold,
    type_normal,
    OtpBg,
    type_silver,
    type_bronze,
    Logo4,
    LogoEn,
    Bg_Overlay,
    personal,
    Logo5,
    BgCars,
    BgOffersPage,
    BgJobs,
    Logo2,
    Logo3,
 
    BgAbout,
    BgOffers,
    RedisterBg,
    BgConnect,
    LoginBg,
    Logo,
 
    GoogleplayLight,
    AppDownload,
    MobileApp,
    AppStoreLight,
    appstore3,
    Googleplay,
 
    ContactBg,
    ArLang,
    EnLang,
    DetailsOffers
}

export default img;
