import React, { createContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
export const LocalizationContext = createContext([])

function LangChange({ children }) {

  const [isLang, setIsLang] = useState(localStorage.getItem('langChange'));
  const [userLocationMap, setLoctionMap] = useState(null);
  const [isOpen, setIsOpen] = useState(true); 
  const [reservationsStartDate, setReservationsStartDate] = useState(null);
  const [reservationsEndDate, setReservationsEndDate] = useState(null);
  const [reservationsTotal, setReservationsTotal] = useState(null);

  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    if (!localStorage.getItem('langChange')) {
      setIsLang('ar')
      return localStorage.setItem('langChange', 'ar')
    } else {
      return localStorage.setItem('langChange', isLang)
    }
  }, [isLang])
  async function LogOut() {
    localStorage.removeItem("client_id");
    localStorage.removeItem("CLIENT_FOUND"); 
    return <Navigate to="/" replace={true} />

  }
  return (
    <LocalizationContext.Provider
      value={{
        isLang,
        setIsLang,
        userLocationMap,
        setLoctionMap,
        isOpen,
        setIsOpen,
        toggle, 
        LogOut,
        reservationsStartDate,
        setReservationsStartDate,
        reservationsEndDate,
        setReservationsEndDate,
        reservationsTotal,
        setReservationsTotal

      }}
    >
      {children}
    </LocalizationContext.Provider>
  )
}

export default LangChange