import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { Messages } from 'primereact/messages';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SharedData } from '../../../context/CallApi';
import { LocalizationContext } from '../../../context/LangChange';
import { handelLogin } from '../Services/AuthService';
import useValidation from '../Services/useValidation';
import img from './../../../constants/Img';
import './style.scss';
import { useMountEffect } from 'primereact/hooks';
import { RadioButton } from 'primereact/radiobutton';
import PhoneInput from 'react-phone-input-2';
import { Helmet } from 'react-helmet-async';

const Login = () => {
  const msgs = useRef(null);
  let { code } = useParams()
  let { validateLoginForm } = useValidation()
  let { isLang } = useContext(LocalizationContext);
  let { setClientId, fetchPersonal } = useContext(SharedData);
  const [checked, setChecked] = useState(false);
  const [loadmobile, setLoadmobile] = useState(false);
  const [ingredient, setIngredient] = useState('phone');

  let navigate = useNavigate();
  const [handelOpenPassword, setHandelOpenPassword] = useState('password');



  const formik = useFormik({
    initialValues: {
      mobile: '',
      password: ''
    },
    validationSchema: validateLoginForm,
    onSubmit: (values) => handelLogin(values, setLoadmobile).then((data) => {
      const { detailAr, detail, closeModal, client_id, checkFirstLogin } = data;
      msgs.current.clear();
      try {
        setLoadmobile(true)
        if (checkFirstLogin === true) {
          if (closeModal === "CLIENT_FOUND") {
            fetchPersonal()
            setClientId(client_id)
            showSuccess(isLang === "en" ? detail : detailAr)
            setTimeout(() => {
              navigate('/thanks-page/sign-up')
              setLoadmobile(false)
            }, 500);

            if (checked) {
              // إذا تم تحديد زر "تذكرني"، قم بتخزين معلومة في LocalStorage
              localStorage.setItem('rememberMe', 'true');
              localStorage.setItem('rememberMeEmail', values?.mobile);
              localStorage.setItem('rememberMePassword', values?.password);
            } else {
              localStorage.removeItem('rememberMe');
              localStorage.removeItem('rememberMeEmail');
              localStorage.removeItem('rememberMePassword');
            }
          }
        } else {
          if (closeModal === "CLIENT_FOUND") {
            fetchPersonal()
            setClientId(client_id)
            showSuccess(isLang === "en" ? detail : detailAr)
            setTimeout(() => {
              if (!localStorage.getItem('path')) {
                navigate('/account/personal_data')
              } else {
                navigate(`${localStorage.getItem('path')}`)
              }

              setLoadmobile(false)
            }, 500);


            if (checked) {
              // إذا تم تحديد زر "تذكرني"، قم بتخزين معلومة في LocalStorage
              localStorage.setItem('rememberMe', 'true');
              localStorage.setItem('rememberMeEmail', values?.mobile);
              localStorage.setItem('rememberMePassword', values?.password);
            } else {
              localStorage.removeItem('rememberMe');
              localStorage.removeItem('rememberMeEmail');
              localStorage.removeItem('rememberMePassword');
            }
          } else if (closeModal === "CLIENT_NOT_ACTIVE") {
            showSuccessWarn(isLang === "en" ? detail : detailAr)
            setLoadmobile(false)

          } else {
            showError(isLang === "en" ? detail : detailAr)

            setTimeout(() => {
              setLoadmobile(false)
            }, 1500);

          }

        }
      } catch (error) {

      }
    })
  });
  const showError = (data) => {
    msgs.current.show({ sticky: true, severity: 'error', detail: data });
  };

  const showSuccess = (data) => {
    msgs.current.show({ sticky: true, severity: 'success', detail: data });
  };



  const showSuccessWarn = (data) => {
    msgs.current.show({ sticky: true, severity: 'warn', summary: ` `, detail: data });
  };


  useEffect(() => {

    // داخل دالة useEffect أو componentDidMount عند تحميل صفحة Login
    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe === 'true') {
      // إذا تم تحديد زر "تذكرني" في الجلسة السابقة، قم بتعبئة حقول تسجيل الدخول
      const storedMobile = localStorage.getItem('rememberMeEmail');
      const storedPassword = localStorage.getItem('rememberMePassword');

      // قم بتعبئة حقول تسجيل الدخول بالمعلومات المخزنة
      formik.setFieldValue('mobile', storedMobile);
      formik.setFieldValue('password', storedPassword);
      setChecked(true); // قم بتحديد زر "تذكرني" على الواجهة إذا كان محفوظًا
    }


  }, [])
  useMountEffect(() => {
    if (code === 'DONE') {
      if (msgs.current) {
        msgs.current.clear();
        msgs.current.show([
          { sticky: true, severity: 'warn', summary: ' ', detail: isLang === "en" ? 'Acount has been activete' : 'تم تفعيل حسابك بنجاح', closable: false },
        ]);
      }
    }
  });
  let { seo, fetchSEO } = useContext(SharedData);
  useEffect(() => { 
    fetchSEO('LOGIN')
  }, [])

  return (
    <>
      <Helmet >
        <meta name="title" content={seo?.title} />
        <meta name="description" content={seo?.desc} />
        <meta name="keywords" content={seo?.keywords} />
      </Helmet>
      <div className='app__login'  >
        <Row className='h-100' dir={isLang === "en" ? 'ltr' : 'rtl'}>
          <Col xl={6} lg={6} md={6} sm={12} className="bg_login_md_view h-100 app__login-contect "  >
            <form onSubmit={formik.handleSubmit} className='w-100'>
              <Container className='flex justify-content-center align-items-center flex-column'>
                <h1> {isLang === "en" ? 'Login' : 'تسجيل دخول '}</h1>

                <div className="Inputs w-100 flex gap-3 flex-column  "   >
                  <div className="  flex justify-content-center">
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center gap-2">
                        <RadioButton inputId="ingredient1" name="pizza" value="phone" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'phone'} />
                        <label htmlFor="ingredient1" className="ml-2">{isLang === "en" ? 'Mobile Number' : '   رقم الجوال '}</label>
                      </div>
                      <div className="flex align-items-center gap-2">
                        <RadioButton inputId="ingredient2" name="pizza" value="email" onChange={(e) => setIngredient(e.value)} checked={ingredient === 'email'} />
                        <label htmlFor="ingredient2" className="ml-2">{isLang === "en" ? '  Email' : 'بريد الإلكتروني'}</label>
                      </div>
                    </div>
                  </div>
                  {
                    ingredient === 'email' &&
                    <div className=" flex flex-column gap-2 ">
                      <label htmlFor="username">{isLang === "en" ? '  Email' : 'بريد الإلكتروني'} </label>
                      <InputText
                        id="mobile"
                        name="mobile"
                        type="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder={isLang === "en" ? 'mobile number / Email' : '   رقم الجوال / بريد الإلكتروني'}
                        className={`  custom-input`} // Add the custom-input class here
                        dir='ltr'
                        value={formik.values.mobile}
                      />
                      {formik.errors.mobile && formik.touched.mobile ?
                        <Message severity="error" text={formik.errors.mobile} /> : null
                      }
                    </div>
                  }
                  {
                    ingredient === 'phone' &&
                    <div dir='ltr' className=" flex flex-column login_by_phone gap-2 ">
                      <label htmlFor="mobile" dir={isLang == "en" ? 'ltr' : 'rtl'} className="font-  block m 2">{isLang === "en" ? ' Mobile Number' : '  رقم الجوال '}   </label>

                      <PhoneInput
                        country='sa'
                        enableSearch={true}
                        id="mobile"
                        name="mobile"
                        preferredCountries={['sa']}
                        onChange={(value, countryData) => {
                          // استخراج مفتاح الدولة من بيانات الدولة
                          let dialCode = countryData.dialCode;
                          // إزالة مفتاح الدولة من القيمة المدخلة
                          let newValue = value.replace(dialCode, '');
                          // إذا كانت القيمة الجديدة تبدأ بالصفر، ازل هذا الصفر
                          if (newValue.startsWith('0')) {
                            newValue = newValue.substring(1);
                          }
                          // إعادة إضافة مفتاح الدولة إلى القيمة الجديدة
                          newValue = dialCode + newValue;
                          formik.setFieldValue('mobile', newValue);
                        }}
                        onBlur={formik.handleBlur}
                        dir="ltr"
                        value={formik.values.mobile}
                        containerClass="w-full"
                        className="w-full "
                        inputClass={'w-100'}
                        // localization={ar}   
                        inputExtraProps={{
                          autoFocus: true,
                        }}
                      />
                      {formik.errors.mobile && formik.touched.mobile ?
                        <Message severity="error" dir={isLang === "en" ? 'ltr' : 'rtl'} text={formik.errors.mobile} /> : null
                      }
                    </div>
                  }

                  <div className="flex flex-column gap-2">
                    <label htmlFor="username">{isLang === "en" ? '  Password' : '   كلمة المرور   '}   </label>
                    <div className="password_open">
                      <InputText
                        id="password"
                        name="password"
                        type={handelOpenPassword}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder={isLang === "en" ? 'enter your password' : 'قم بادخال كلمه المرور'}
                        className='w-full '
                        dir='ltr'
                        value={formik.values.password}

                      />
                      {
                        handelOpenPassword === "password" ?
                          <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                          <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>

                      }
                    </div>


                    {formik.errors.password && formik.touched.password ?
                      <Message severity="error" text={formik.errors.password} /> : null
                    }
                  </div>
                  <div className="forget_password">
                    <div className="flex  gap-2 align-items-center mb-2">
                      <Checkbox inputId="ingredient1" name="pizza" value="Cheese" onChange={e => setChecked(e.checked)} checked={checked} />
                      <label htmlFor="ingredient1" className="ml-2 text_box">  {isLang === "en" ? '  Remember me' : 'تذكرنى'}  </label>
                    </div>
                    <Link to={'/auth/forget-password'}>
                      <span >
                        {isLang === "en" ? '  Forgot your password?  ' : 'هل نسيت كلمة السر؟'}
                      </span>
                    </Link>
                  </div>
                  <div className={`${isLang === "en" ? '' : 'Messages_Ar'} w-100 flex justify-content-center`} >
                    <Messages ref={msgs} className='w-100' />
                  </div>
                  <div className="flex flex-column    mt-2  ">
                    <div className='w-100'>
                      <Button loading={loadmobile} disabled={loadmobile} label={isLang === "en" ? 'Login' : 'تسجيل دخول '} className='w-100' severity='warning' />
                    </div>
                  </div>
                </div>

                <div className="app__now_account">
                  <span >  {isLang === "en" ? `Don't have an account? ` : '  ليس لديك حساب؟  '} <Link to={'/auth/sign-up'}>   {isLang === "en" ? 'New account' : ' حساب جديد  '}</Link></span>
                </div>

              </Container>
            </form>
          </Col>
          <Col xl={6} lg={6} md={6} className='h-100 bg_login_md' sm={12}>
            <div className="app__login-image h-100">
              <img src={img.LoginBg} className='w-100 h-100 ' alt="images" loading='lazy' />
              <Link as={Link} to={'/'}>
                <div className="btn_back cursor-pointer shadow-lg" >
                  <i className={`pi ${isLang === "en" ? "pi-arrow-right " : "pi-arrow-left"} `}></i>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </div>



    </>

  )
}

export default Login
