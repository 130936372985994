import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Component from '../../../constants/Component';
import { SharedData } from '../../../context/CallApi';
import Slider from './Slider/Slider';
import { Helmet } from 'react-helmet-async';


const Home = () => {


  let { bannersHome, carsSliderHome, offersHome, branchesHome, carBrandsHome } = useContext(SharedData)
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('path', currentPath)
    window.scrollBy(0, -window.pageYOffset);
  }, [])
  let { seo, fetchSEO } = useContext(SharedData);
  useEffect(() => {
    window.scrollBy(0, -window.pageYOffset);
    fetchSEO('HOME')
  }, [])
  return (

    <div>
      <Helmet >
        <meta name="title" content={seo?.title} />
        <meta name="description" content={seo?.desc} />
        <meta name="keywords" content={seo?.keywords} />
      </Helmet> 
      <Slider dataHome={bannersHome} />
      <Component.SearchCar branchesHome={branchesHome} carBrandsHome={carBrandsHome} />
      <Component.LatestCars carsSliderHome={carsSliderHome} />
      <Component.DownloadAppNow />
      {/* {
        offersHome?.length >= 1 &&
        <Component.HomeOffers dataHome={offersHome} />
      } */}
      <Component.HomeContactUS />
      <Component.BlogSection />
      <Component.Services />
      {/* <Component.Loader /> */}

    </div>
  )
}

export default Home
